/* eslint-disable */
import cn from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import TextEditor from 'src/components/TextEditor';

import { gameDictionary } from '../../common/dictionary';
import { IRulesProps, IRulesState } from '../../interfaces/IRules';
import { getRulesRequest, createRulesRequest } from '../../store/rules/actions';
import { GlobalGameTypeEnum } from 'src/common/provider-common-type-util/dictionary/system-provider';

class Rules extends React.Component<IRulesProps, IRulesState> {
    constructor(props) {
        super(props);
        this.state = {
            currentGameType: gameDictionary.GAME_TYPE_BEST_AT_TABLE,
            currentGameName: gameDictionary.GAME_TYPE_BEST_AT_TABLE_NAME,
        };
    }

    componentDidMount(): void {
        this.props.getRulesRequest({});
    }

    switchRules = (e: any): void => {
        this.setState({
            currentGameType: e.currentTarget.dataset.tab,
            currentGameName: e.target.id,
        });
    };

    getGame = () => {
        const enumKeys = Object.keys(GlobalGameTypeEnum)
            .filter((key) => Number.isNaN(Number(GlobalGameTypeEnum[key])))
            .map((key) => ({
                label: GlobalGameTypeEnum[key],
                value: key,
            }));
        return enumKeys;
    };

    render(): JSX.Element {
        const { currentGameType, currentGameName } = this.state;
        const { rules, translate } = this.props;

        return (
            <>
                <div className="rule-list logos-page page-container" style={{ marginTop: '5px' }}>
                    <div>
                        <div className="logos__blocks rules__head" style={{ overflow: 'auto' }}>
                            {this.getGame().map((item) => (
                                <div
                                    key={item.value}
                                    data-tab={item.value}
                                    id={item.label}
                                    onClick={this.switchRules}
                                    style={{ minWidth: '150px' }}
                                    className={cn(
                                        'logos__block',
                                        {
                                            'logos__block--selected': Number(currentGameType) === Number(item.value),
                                        },
                                        'rule-list__column-name',
                                    )}
                                >
                                    {item.label}
                                </div>
                            ))}
                        </div>

                        <TextEditor
                            currentGameType={Number(currentGameType)}
                            currentGameName={currentGameName}
                            rules={rules}
                            createRulesRequest={this.props.createRulesRequest}
                            translate={this.props.translate}
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    rules: state.rules.rules,
    rulesLoaded: state.rules.rulesLoaded,
});

const mapDispatchToProps = {
    getRulesRequest,
    createRulesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(Rules));
