import React from 'react';

import { IUserData, IHandlerStatus } from '../../interfaces/IProfile';
import ProfitForm from '../Forms/ProfitForm/Form';
import ModalWindow from '../ModalWindow';

import ModalHOC from './ModalHOC';

interface IProfitModal {
    userData: IUserData;
    dataModal: string;
    handlerStatus: IHandlerStatus;
    profitRequest(object: { id: number; profitPercent: number }): void;
    switchModal(e: React.MouseEvent<HTMLElement>): void;
    translate(key: string): string;
}

const ProfitModal: React.FC<IProfitModal> = (props) => {
    const onSubmit = (values: { amount: number }): void => {
        props.profitRequest({
            id: props.userData.userId,
            profitPercent: values.amount,
        });
    };

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner">
                <div className="modal-container__header">
                    <div>{props.translate('ProfitPercent')}</div>
                    <div style={{ color: '#e6bc73' }}>{props.userData.name}</div>
                </div>

                <ProfitForm
                    onSubmit={onSubmit}
                    handlerStatus={props.handlerStatus}
                    userData={props.userData}
                    dataModal={props.dataModal}
                    switchModal={props.switchModal}
                    translate={props.translate}
                />
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(ProfitModal);
