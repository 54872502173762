/* eslint-disable */
import React from 'react';
import './styles.scss';

import ModalWindow from '../ModalWindow';

const InfoTransaction: React.FC<any> = (props) => {
    const { translate, userData } = props;

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner Deposit">
                <div className="Deposit-table">
                    <div className="Deposit-table-heading">
                        <div className="Deposit-table-heading-item">{translate('info_transaction')}</div>
                    </div>
                    <div className="Deposit-table-body">
                        {Object.entries(userData).map(([key, value]) => (
                            <div className="Deposit-table-row" key={key}>
                                <div className="Deposit-table-row-item-first">{key}</div>
                                <div className="Deposit-table-row-item">{value ? value.toString() : '-'}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ModalWindow>
    );
};

export default InfoTransaction;
