/* eslint-disable */
import cn from 'classnames';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');
const roolEl = document.createElement('div');

interface IModalWindowProps {
    className?: string;
    dataModal?: string;
    closeModal: (e: React.MouseEvent<HTMLElement>) => void;
    children: any;
}

class ModalWindow extends Component<IModalWindowProps> {
    componentDidMount() {
        modalRoot.appendChild(roolEl);
    }

    componentWillUnmount() {
        // modalRoot.removeChild(roolEl);
    }

    closeModal = (e) => {
        const ell = e.target.classList[0];

        if (!this.props.closeModal) {
            return;
        }

        const el = e.target.classList[0];
        if (el === 'modal-window' || el === 'modal-container') {
            this.props.closeModal(e);
        }
    };

    render() {
        return ReactDOM.createPortal(
            <div
                className={cn('modal-window', this.props.className)}
                data-modal={this.props.dataModal}
                onMouseDown={this.closeModal}
            >
                <div className="modal-container">{this.props.children}</div>
            </div>,
            roolEl,
        );
    }
}

export default ModalWindow;
