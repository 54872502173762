import { Reducer } from 'redux';

import { IEvoGame } from '../../interfaces/IEvoGames';

import { ActionTypes } from './types';

interface IInitialState {
    gamesList: IEvoGame[] | {};
}
export const initialState: IInitialState = {
    gamesList: {},
};

const reducer: Reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GAMES_SUCCESS: {
            return {
                ...state,
                gamesList: [...action.payload.data],
                gamesLoaded: true,
            };
        }

        case ActionTypes.OPEN_GAME_SUCCESS: {
            return {
                ...state,
                currentGame: action.payload,
            };
        }

        case ActionTypes.OPEN_GAME_FAIL: {
            return {
                ...state,
            };
        }

        case ActionTypes.CLOSE_GAME_SUCCESS: {
            return {
                ...state,
                currentGame: null,
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
