import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import NotPage from 'src/pages/404';
import Balances from 'src/pages/balances';
import Report from 'src/pages/Betting';
import ChanceCommon from 'src/pages/ChanceCommon';
import EditSettings from 'src/pages/EditSettings';
import LoginHistory from 'src/pages/LoginHistory';
import RuleList from 'src/pages/RuleList';
import Rules from 'src/pages/Rules';
import SettingsBundle from 'src/pages/Settings';
import ChanceSpeed from 'src/pages/SpeedChance';
import TotalStatistic from 'src/pages/TotalStatistic';
import { getAuthToken } from 'src/utils/cookies';

import LoginModal from '../components/Modals/LoginModal';
import Sidebar from '../components/Sidebar';
import ManageUsers from '../pages/ManageUsers';
import Statistics from '../pages/Statistics';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth = getAuthToken();
    const location = useLocation();

    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

const NotRequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth = getAuthToken();

    if (auth) {
        return <Navigate to="/total-statistic" />;
    }

    return children;
};

const RenderRoutes: React.FC<any> = () => {
    const auth = getAuthToken();
    const location = useLocation();
    return (
        <>
            <Routes>
                <Route
                    path="/login"
                    element={
                        <NotRequireAuth>
                            <div className="login-page">
                                {' '}
                                <LoginModal />
                            </div>
                        </NotRequireAuth>
                    }
                />
                <Route
                    path="/total-statistic"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <TotalStatistic />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/manage-users"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <ManageUsers />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/login-history/:id"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <LoginHistory />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/rules"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <Rules />
                            </>
                        </RequireAuth>
                    }
                />

                <Route
                    path="/edit-settings/:id"
                    element={
                        <RequireAuth>
                            <>
                                <EditSettings />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/rule-list"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <RuleList />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/statistics"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <Statistics />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/report"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <Report />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/balances"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <Balances />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/settings-bundle"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <SettingsBundle />
                            </>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/1w-speed-chance"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <ChanceSpeed />
                            </>
                        </RequireAuth>
                    }
                />

                <Route
                    path="/chance"
                    element={
                        <RequireAuth>
                            <>
                                <Sidebar />
                                <ChanceCommon />
                            </>
                        </RequireAuth>
                    }
                />
                <Route path="/*" element={<NotPage />} />
            </Routes>
            {!auth && location.pathname !== '/login' && location.pathname === '/' && <Navigate to="/login" />}
            {auth && location.pathname === '/' && <Navigate to="/manage-users" />}
        </>
    );
};

export default RenderRoutes;
