import Resizer from 'react-image-file-resizer';
import { BalanceDictionary } from 'src/common/provider-common-type-util/dictionary/balance';

import { GameTypeDictionary } from './common/dictionary';
import { IBalanceGetResponse } from './interfaces/IBalance';

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
    );

    return JSON.parse(jsonPayload);
};

export const convertToBase64 = (image, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);

    reader.addEventListener('load', () => {
        const img: any = new Image();
        img.src = reader.result;

        img.onload = () => {
            callback(img.src);
        };
    });
};

export const imageResizer = (image, dimension, cb) => {
    Resizer.imageFileResizer(
        image,
        dimension,
        dimension,
        'PNG',
        100,
        0,
        (uri) => {
            cb(uri);
        },
        'blob',
    );
};

export const getPermissionLevelName = (permissionLevel: number): string => {
    let result = '';
    if (permissionLevel === 4) {
        result = 'masters';
    }
    if (permissionLevel === 2) {
        result = 'agents';
    }
    if (permissionLevel === 1) {
        result = 'players';
    }
    return result;
};

export const userTree = (data, type?) =>
    data.filter((item) => {
        item.children = [];
        const parent = data.find(({ id }) => id === item.parent?.id);
        if (parent) {
            item._isShow = type ? item._isShow : false;
            item._isShowChildren = type ? item._isShowChildren : false;
            parent.children.push(item);
        } else {
            item._isShow = type ? item._isShow : true;
            item._isShowChildren = type ? item._isShowChildren : false;
            return item;
        }

        return null;
    });

export const switchModalGlobal = (context, e) => {
    if (typeof e === 'string') {
        try {
            context.setState({
                [e]: !context.state[e],
            });
        } catch (error) {
            context.setState({ [e]: false });
        }
        return;
    }

    const { modal } = e.currentTarget.dataset;

    try {
        context.setState({
            [modal]: !context.state[modal],
        });
    } catch (error) {
        context.setState({ [modal]: false });
    }
};

export function changeBalance(data: number, type?: boolean, property?: string | string[]): number;
export function changeBalance(data: object[], type?: boolean, property?: string | string[]): object[];
export function changeBalance(data: any, type?: boolean, property: string | string[] = 'balance'): any {
    if (typeof data !== 'object') {
        return type ? data * 100 : data / 100;
    }

    if (Array.isArray(property)) {
        return data.map((item) => {
            for (const key in item) {
                if (property.includes(key)) {
                    item[key] /= 100;
                }
            }

            return item;
        });
    }
    return data.map((item) => ({
        ...item,
        [property]: item[property] / 100,
    }));
}

export const stringTrim = (str: string): string => {
    let shortString = '';

    if (str.length > 20) {
        shortString = str.split('').splice(0, 20).join('');

        return `${shortString}...`;
    }

    return str;
};

export const compareOptions = (option1, option2) => {
    function objectsAreSame(x, y) {
        let objectsAreSame = true;
        for (const propertyName in x) {
            if (x[propertyName].name !== (y[propertyName] ? y[propertyName].name : null)) {
                objectsAreSame = false;
                break;
            }
        }
        return objectsAreSame;
    }

    try {
        return objectsAreSame(option1, option2) ? 'regularOptions' : 'customOptions';
    } catch (error) {
        return 'regularOptions';
    }
};

export const sort = (array, property, reverse?) =>
    [...array].sort((a, b) => {
        if (a[property] > b[property]) {
            return reverse ? -1 : 1;
        }
        if (a[property] < b[property]) {
            return reverse ? 1 : -1;
        }
        return 0;
    });

export const setSelectedOptions = (matches, ticket) =>
    matches.map((item) => {
        const currentTicket = ticket.bet.find(({ matchId }) => matchId === item.id);
        if (currentTicket) {
            return {
                ...item,
                options: item.options.map((item) => (item.name === currentTicket.bet ? { ...item, status: true } : item)),
            };
        }

        return item;
    });

export const renderSwitch = (type: number): string => {
    switch (type) {
        case GameTypeDictionary.GAME_TYPE_BEST_AT_TABLE: {
            return 'Best at Table';
        }

        case GameTypeDictionary.GAME_TYPE_CHECKPOINT: {
            return 'Checkpoint';
        }

        case GameTypeDictionary.GAME_TYPE_ONE_ON_ONE: {
            return 'One On One';
        }

        case GameTypeDictionary.GAME_TYPE_MONTH_PRIZE: {
            return 'Month Prize';
        }

        case GameTypeDictionary.GAME_TYPE_DAILY: {
            return 'Group Bet';
        }

        case GameTypeDictionary.GAME_TYPE_JACKPOT: {
            return 'Jackpot';
        }

        case GameTypeDictionary.GAME_TYPE_CHANCE: {
            return 'Chance';
        }

        default:
            return 'GAME';
    }
};

export const balanceDescription = (item: IBalanceGetResponse): string[] => {
    const returnData: string[] = [];
    switch (item.type) {
        case BalanceDictionary.BALANCE_TYPE_ADD:
            returnData.push('BALANCE_TYPE_CASHBACK');
            returnData.push(item.providerGameName ? renderSwitch(Number(item.providerGameName)) : '');
            break;
        case BalanceDictionary.BALANCE_TYPE_REDUCE:
            returnData.push('BALANCE_TYPE_REDUCE');
            returnData.push(item.providerGameName ? renderSwitch(Number(item.providerGameName)) : '');
            break;
        case BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE:
        case BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE:
            returnData.push('BALANCE_TYPE_SCEDUAL_REDUCE');
            returnData.push(item.providerGameName ? renderSwitch(Number(item.providerGameName)) : '');
            break;
        case BalanceDictionary.BALANCE_TYPE_REFUND:
            returnData.push('BALANCE_TYPE_REFUND');
            returnData.push(item.providerGameName ? renderSwitch(Number(item.providerGameName)) : '');
            break;
        case BalanceDictionary.BALANCE_TYPE_TAKE:
            returnData.push('BALANCE_TYPE_TAKE');
            returnData.push(item.providerGameName ? renderSwitch(Number(item.providerGameName)) : '');
            break;
        case BalanceDictionary.BALANCE_TYPE_TRANSFER:
            returnData.push('BALANCE_TYPE_TRANSFER');
            returnData.push(item.providerGameName ? renderSwitch(Number(item.providerGameName)) : '');
            break;
        // case balanceDictionary.BALANCE_TYPE_EVOLUTION_DEBIT:
        // case balanceDictionary.BALANCE_TYPE_EVOLUTION_SCEDUAL_DEBIT:
        //   // return `BET ${item.evoGameName}`;
        //   return `BET EVOLUTION`;
        // case balanceDictionary.BALANCE_TYPE_EVOLUTION_CREDIT:
        //   // return `ADD ${item.evoGameName}`;
        //   return `WIN EVOLUTION`;
        // case balanceDictionary.BALANCE_TYPE_EVOLUTION_CANCEL:
        // case balanceDictionary.BALANCE_TYPE_EVOLUTION_SCEDUAL_CANCEL:
        //   // return `CANCEL ${item.evoGameName}`;
        //   return `ROLLBACK EVOLUTION`;
        default:
            returnData.push('Type is not defined');
            break;
    }

    return returnData;
};

export const getTypeByTypeName = (name: string): number[] => {
    switch (name) {
        case 'BALANCE_TYPE_ADD':
            return [BalanceDictionary.BALANCE_TYPE_ADD];
        case 'BALANCE_TYPE_REDUCE':
            return [BalanceDictionary.BALANCE_TYPE_REDUCE, BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE];
        case 'BALANCE_TYPE_REFUND':
            return [BalanceDictionary.BALANCE_TYPE_REFUND];
        case 'BALANCE_TYPE_TAKE':
            return [BalanceDictionary.BALANCE_TYPE_TAKE];
        case 'BALANCE_TYPE_TRANSFER':
            return [BalanceDictionary.BALANCE_TYPE_TRANSFER];
        case 'BALANCE_TYPE_SCEDUAL_REDUCE':
            return [BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE, BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE];
        default:
            return [];
    }
};

export const getCardByValue = (cardValue: number): string => {
    switch (cardValue) {
        case 0:
            return 'None';
        case 7:
            return '7';
        case 8:
            return '8';
        case 9:
            return '9';
        case 10:
            return '10';
        case 11:
            return 'J';
        case 12:
            return 'Q';
        case 13:
            return 'K';
        case 14:
            return 'A';
        default:
            return 'None';
    }
};
