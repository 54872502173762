import { startOfDay, endOfDay, subMonths, subDays } from 'date-fns';
import React, { FC } from 'react';
import { withTranslate } from 'react-redux-multilingual';
import './dateRangePickerComponent.scss';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

interface IProps {
    endDate: Date;
    startDate: Date;
    onChange: (date: Date[]) => void;
    className: string;
    translate: (value: string) => void;
}

const DateRangePickerComponent: FC<IProps> = ({
    startDate = new Date(),
    endDate = new Date(),
    onChange,
    className = '',
    translate,
}): JSX.Element => {
    const selecteStartdDate = startDate instanceof Date ? startDate : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    const selecteEnddDate = endDate instanceof Date ? endDate : new Date();
    const Ranges: any = [
        {
            label: translate('last-7-days'),
            value: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
        },
        {
            label: translate('last-month'),
            value: [startOfDay(subMonths(new Date(), 1)), endOfDay(new Date())],
        },
        {
            label: translate('last-6-months'),
            value: [startOfDay(subMonths(new Date(), 6)), endOfDay(new Date())],
        },
    ];
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    return (
        <DateRangePicker
            className={className}
            onChange={onChange}
            placement="auto"
            ranges={Ranges}
            format="MM/dd/yyyy HH:mm"
            value={[selecteStartdDate, selecteEnddDate]}
            shouldDisableDate={(date) => date > tomorrow}
        />
    );
};

export default withTranslate(DateRangePickerComponent);
