import React, { useState } from 'react';
import { PermissionEnum } from 'src/common/provider-common-type-util/dictionary/permission';

import CreateUserForm from '../Forms/CreateUserForm/Form';
import FormAddNewUser from '../Forms/FormAddNewUser';
import ModalWindow from '../ModalWindow';
import './styles.scss';

import ModalHOC from './ModalHOC';

const CreateUserModal: React.FC<any> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = async (values) => {
        setIsLoading(true);
        const data = {
            ...values,
        };

        for (const key in data) {
            if (data[key] === '') {
                delete data[key];
            }
        }

        if (props.isJoin) {
            await props.createUserJoinRequest(data);
            setTimeout(() => {
                setIsLoading(false);
                props.switchModal(props.dataModal);
            }, 500);
        } else {
            await props.createUserRequest(data);
            props.manageUsersRequest();

            setTimeout(() => {
                setIsLoading(false);
                props.switchModal(props.dataModal);
            }, 500);
        }
    };

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div
                className={`modal-container__inner ${
                    props.permissionLevel === PermissionEnum.USER_PLAYER_PERMISSION_LEVEL && 'Profile'
                }`}
            >
                <div className="modal-container__header">
                    <div>{props.translate('Add New')}</div>
                    {props.permissionLevel !== PermissionEnum.USER_PLAYER_PERMISSION_LEVEL && (
                        <span className="user-color__master">
                            {props.translate('Master')} / {props.translate('Agent')}
                        </span>
                    )}
                </div>

                {props.permissionLevel !== PermissionEnum.USER_PLAYER_PERMISSION_LEVEL ? (
                    <CreateUserForm
                        onSubmit={onSubmit}
                        user={props.user}
                        permissionLevel={props.permissionLevel}
                        handlerStatus={props.handlerStatus}
                        dataModal={props.dataModal}
                        switchModal={props.switchModal}
                        isJoin={props.isJoin}
                        userTree={props.userTree}
                        allUsers={props.allUsers}
                        translate={props.translate}
                        usersLoaded={props.usersLoaded}
                        isLoading={isLoading}
                        customValues={
                            props.permissionLevel === PermissionEnum.USER_PLAYER_PERMISSION_LEVEL && {
                                parent: props.user.id,
                            }
                        }
                    />
                ) : (
                    <FormAddNewUser
                        translation={props.translate}
                        permissionLevel={props.permissionLevel}
                        switchModal={props.switchModal}
                        dataModal={props.dataModal}
                    />
                )}
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(CreateUserModal);
