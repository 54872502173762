import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import { CSSTransition } from 'react-transition-group';
import { api } from 'src/api';
import loading from 'src/assets/images/preloader_olwin.gif';
import Button from 'src/components/Button';
import BundleDelete from 'src/components/Modals/BundleDelete';
import CreateBundle from 'src/components/Modals/CreateBundle';
import EditBundle from 'src/components/Modals/EditBundle';
import { withRouter } from 'src/withRouter';

import settingsData from '../../assets/tableTemplates/settings';
import NoContent from '../../components/NoContent';
import Paginate from '../../components/Paginate';
import Table from '../../components/Table';
import { switchModalGlobal, getTypeByTypeName } from '../../helpers';
import { IEvoGame } from '../../interfaces/IEvoGames';
import { IProfile, IHandlerStatus } from '../../interfaces/IProfile';
import { gamesRequest } from '../../store/evoGames/actions';
import { settingsRequest } from '../../store/manageUsers/actions';

import './styles.scss';

class SettingsBundle extends Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            searchLogin: '',
            searchType: '',
            currentLogin: '',
            currentType: [],
            userData: null,
            userBalanceConfirmIsOpen: false,
            editBundleIsOpen: false,
            bundleIsDeleteOpen: false,
            id: '',
            userId: '',
            amount: '',
            isLoading: false,
        };
    }

    loadData = async () => {
        this.setState({ isLoading: true });
        await this.props.settingsRequest({
            getParams: {
                pageNumber: 1,
            },
        });

        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 1000);
    };

    componentDidMount(): void {
        this.loadData();
    }

    deleteBundle = async (id: number) => {
        const resp = await api.post('/setting/bundle/rm', { id });
        if (!resp.error) {
            this.props.settingsRequest({
                getParams: {
                    pageNumber: 1,
                },
            });
            this.switchModal('bundleIsDeleteOpen' as any);
            NotificationManager.success(this.props.translate('Success'), '', 3500);
        }
    };

    editBundle = (e: any) => {
        const [name, nameShort, status, target, id, provider] = e.currentTarget.dataset.user.split('::');
        this.setState({
            userData: { name, nameShort, status, target, id, provider },
            editBundleIsOpen: true,
        });
    };

    switchModal = (e: React.MouseEvent<HTMLElement>): void => switchModalGlobal(this, e);

    clickSettings = (e: any) => {
        const [id] = e.currentTarget.dataset.user.split('::');

        this.props.history.push(`/edit-settings/${id}`);
    };

    handlePage = (pageNumber: number): void => {
        const requestUserStatistic: any = {
            getParams: {
                pageNumber,
            },
        };

        if (this.state.currentLogin) {
            requestUserStatistic.getParams.login = this.state.currentLogin;
        }

        if (this.state.currentType && this.state.currentType.length > 0) {
            requestUserStatistic.getParams.type = JSON.stringify(this.state.currentType);
        }

        if (this.state.id.length !== 0) {
            requestUserStatistic.getParams.id = this.state.id;
        }
        if (this.state.userId.length !== 0) {
            requestUserStatistic.getParams.target = this.state.userId;
        }
        if (this.state.amount.length !== 0) {
            requestUserStatistic.getParams.amount = this.state.amount;
        }

        this.props.settingsRequest(requestUserStatistic);
    };

    userBalanceConfirmAction = (): void => {
        this.setState({
            userBalanceConfirmIsOpen: true,
        });
    };

    filter = () => {
        const requestUserStatistic: any = {
            getParams: {
                pageNumber: 1,
            },
        };

        if (this.state.searchLogin) {
            requestUserStatistic.getParams.login = this.state.searchLogin;
        }

        if (this.state.searchType) {
            requestUserStatistic.getParams.status = this.state.searchType;
        }

        if (this.state.id.length !== 0) {
            requestUserStatistic.getParams.id = this.state.id;
        }
        if (this.state.userId.length !== 0) {
            requestUserStatistic.getParams.target = this.state.userId;
        }

        if (this.state.amount.length !== 0) {
            requestUserStatistic.getParams.amount = this.state.amount;
        }

        this.props.settingsRequest(requestUserStatistic);

        this.setState((prevState) => ({
            currentLogin: prevState.searchLogin,
            currentType: getTypeByTypeName(prevState.searchType),
        }));
    };

    changeSearchLogin = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;

        this.setState({
            searchLogin: value,
        });
    };

    changeId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        const regex = /^\d*$/;

        if (regex.test(value)) {
            this.setState({
                id: value,
            });
        }
    };

    changeUserId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        const regex = /^\d*$/;

        if (regex.test(value)) {
            this.setState({
                userId: value,
            });
        }
    };

    changeAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        const regex = /^\d*$/;

        if (regex.test(value)) {
            this.setState({
                amount: value,
            });
        }
    };

    changeSearchType = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const { value } = event.target;

        this.setState({
            searchType: value,
        });
    };

    deleteModalIsOpen = (e: any) => {
        const [id, name] = e.currentTarget.dataset.user.split('::');
        this.setState({ userData: { id, name }, bundleIsDeleteOpen: true });
    };

    render(): JSX.Element {
        const { userBalanceConfirmIsOpen, editBundleIsOpen, userData, bundleIsDeleteOpen, isLoading } = this.state;
        const { usersLoaded, settings, translate, settingsRequest } = this.props;

        return (
            <>
                <CSSTransition in={userBalanceConfirmIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <CreateBundle
                        dataModal="userBalanceConfirmIsOpen"
                        switchModal={this.switchModal}
                        translate={translate}
                        settingsRequest={settingsRequest}
                    />
                </CSSTransition>
                <CSSTransition in={bundleIsDeleteOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <BundleDelete
                        dataModal="bundleIsDeleteOpen"
                        switchModal={this.switchModal}
                        translate={translate}
                        deleteBundle={this.deleteBundle}
                        userData={userData}
                    />
                </CSSTransition>
                <CSSTransition in={editBundleIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <EditBundle
                        dataModal="editBundleIsOpen"
                        switchModal={this.switchModal}
                        translate={translate}
                        settingsRequest={settingsRequest}
                        userData={userData}
                    />
                </CSSTransition>

                {!isLoading ? (
                    <div className="statistics-page page-container">
                        <div className="card-line card-line__header">
                            <div>{translate('Settings')}</div>
                        </div>

                        <div className="bundle-filter">
                            <Button className="btn gold-btn nowrap" type="button" onClick={this.userBalanceConfirmAction}>
                                {this.props.translate('Create')}
                            </Button>
                        </div>
                        <CSSTransition in={usersLoaded} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                            {/* {isLoading && <Loading />} */}
                            {settings.data && settings.data.length ? (
                                <>
                                    <div className="statistics__content">
                                        <div className="statistics__branch">
                                            <Table
                                                tableData={{
                                                    ...settingsData,
                                                    body: settings.data,
                                                }}
                                                handlers={{
                                                    userBalanceConfirmAction: this.userBalanceConfirmAction,
                                                    deleteBundle: this.deleteModalIsOpen,
                                                    editBundle: this.editBundle,
                                                    settings: this.clickSettings,
                                                }}
                                                style={undefined}
                                                tbodyHeight=""
                                                additionalData={undefined}
                                            />
                                        </div>
                                    </div>
                                    <Paginate
                                        pageCount={settings.totalCount / settings.amountPerPage}
                                        handlePage={this.handlePage}
                                    />
                                </>
                            ) : (
                                <NoContent translate={translate} />
                            )}
                        </CSSTransition>
                    </div>
                ) : (
                    <div className="loading-page">
                        <img src={loading} alt="" />;
                    </div>
                )}
            </>
        );
    }
}

interface IStoreState {
    auth: {
        user: IProfile;
    };
    manageUsers: {
        settings: any;
    };
    manageHandlers: {
        handlerStatus: IHandlerStatus;
    };
    evoGames: {
        gamesList: IEvoGame[];
    };
    usersLoaded: boolean;
}

const mapStateToProps = (state: IStoreState) => ({
    user: state.auth.user,
    settings: state.manageUsers.settings,
    handlerStatus: state.manageHandlers.handlerStatus,
    gamesList: state.evoGames.gamesList,
    values: {
        start: new Date(new Date().getTime() - 604800000),
        end: new Date(),
    },
    errors: {
        start: '',
        end: '',
    },
    usersLoaded: true,
});

const mapDispatchToProps = {
    settingsRequest,
    gamesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(SettingsBundle)));
