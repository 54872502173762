/* eslint-disable */
import React, { useState } from 'react';
import './styles.scss';
import Input from 'src/components/Input';
import CheckBox from 'src/components/Checkbox';
import arrow from 'src/assets/images/arrow.png';
import { GlobalGameTypeEnum } from 'src/common/provider-common-type-util/dictionary/system-provider';

const Item = ({ props, handleChangeInput, handleChangeCheckBox, handleChangeInputTwo, handleChangeCheckBoxTwo, translate }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleAccordionClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="Item-wrapper">
            <span className="Item-title">{GlobalGameTypeEnum[props.providerGameType]}</span>
            <div className="Item">
                <div className="Item-header">
                    <span>{translate('Provider Name')}</span>
                    <span>{translate('Max Bet')}</span>
                    <span>{translate('Max Win')}</span>
                    <span>{translate('Disabled')}</span>
                </div>

                <div className="Item-list">
                    {props.games.map((item, index) => (
                        <div key={index}>
                            <div className="Item-list-item">
                                <div
                                    className="Item-list-item-name"
                                    onClick={() => handleAccordionClick(index)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img src={arrow} alt="" className={openIndex === index ? 'icon-reverse' : 'icon'} />{' '}
                                    <span>{item.gameProvider}</span>
                                </div>
                                <Input
                                    className="input gold-input input-filter-login"
                                    type="text"
                                    name="maxBet"
                                    value={item.maxBet}
                                    onChange={(e) => handleChangeInput(e, props.providerGameType, index)}
                                />
                                <Input
                                    className="input gold-input input-filter-login"
                                    type="text"
                                    name="maxWin"
                                    value={item.maxWin}
                                    onChange={(e) => handleChangeInput(e, props.providerGameType, index)}
                                />
                                <CheckBox
                                    onChange={() => handleChangeCheckBox(item.isDisabled, props.providerGameType, index)}
                                    style={{ width: '100%', justifyContent: 'center' }}
                                    label=""
                                    checked={item.isDisabled}
                                />
                            </div>
                            {openIndex === index && (
                                <div className="Item-list-second">
                                    {item.childrens.map((el, childIndex) => (
                                        <div key={childIndex} className="Item-list-item">
                                            <div className="Item-list-item-name">
                                                <span>{el.providerGameName}</span>
                                            </div>
                                            <Input
                                                className="input gold-input input-filter-login"
                                                type="text"
                                                name="maxBet"
                                                value={el.maxBet}
                                                onChange={(e) =>
                                                    handleChangeInputTwo(e, props.providerGameType, index, childIndex)
                                                }
                                            />
                                            <Input
                                                className="input gold-input input-filter-login"
                                                type="text"
                                                name="maxWin"
                                                value={el.maxWin}
                                                onChange={(e) =>
                                                    handleChangeInputTwo(e, props.providerGameType, index, childIndex)
                                                }
                                            />
                                            <CheckBox
                                                onChange={() =>
                                                    handleChangeCheckBoxTwo(
                                                        el.isDisabled,
                                                        props.providerGameType,
                                                        index,
                                                        childIndex,
                                                    )
                                                }
                                                style={{ width: '100%', justifyContent: 'center' }}
                                                label=""
                                                checked={el.isDisabled}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Item;
