import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { api } from 'src/api';
import { SettingDictionary } from 'src/common/provider-common-type-util/dictionary/setting';
import { SystemProviderEnum } from 'src/common/provider-common-type-util/dictionary/system-provider';

import ModalWindow from '../ModalWindow';
import Button from '../UI/Button';
import Input from '../UI/Input';
import OutlinedDropdown from '../UI/outlinedDropdown';

import ModalHOC from './ModalHOC';
import './styles.scss';

const EditBundle: React.FC<any> = (props) => {
    const { userData } = props;
    const [name, setName] = useState('');
    const [nameShort, setNameShort] = useState('');
    const [target, setTarget] = useState('');
    const [status, setStatus] = useState(null);
    const [provider, setProvider] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getOptionProvider = () => {
        const enumKeys = Object.keys(SystemProviderEnum)
            .filter((key) => Number.isNaN(Number(SystemProviderEnum[key])))
            .map((key) => ({
                label: SystemProviderEnum[key],
                value: key,
            }));
        return [{ label: props.translate('not_selected'), value: null }, ...enumKeys];
    };

    const optionsStatus = [
        { label: SettingDictionary[SettingDictionary.SETTING_STATUS_ENABLED], value: SettingDictionary.SETTING_STATUS_ENABLED },
        { label: SettingDictionary[SettingDictionary.SETTING_STATUS_DISABLED], value: SettingDictionary.SETTING_STATUS_DISABLED },
    ];

    useEffect(() => {
        const { name, nameShort, status, target, provider } = userData;
        setName(name);
        setNameShort(nameShort);
        setTarget(target);
        const defaultStatus = {
            label: SettingDictionary[status],
            value: status,
        };
        setStatus(defaultStatus);
        const defaultProvider = {
            label: SystemProviderEnum[provider],
            value: provider,
        };
        setProvider(defaultProvider);
    }, []);

    const handleCreateBundle = async () => {
        setIsLoading(true);
        const options = {
            name,
            nameShort,
            target,
            id: userData.id,
            status: status?.value || null,
            systemProvider: provider?.value || null,
        };

        const resp = await api.post('/setting/bundle/update', options);

        if (resp.data) {
            const requestUserStatistic = {
                getParams: {
                    pageNumber: 1,
                },
            };
            await props.settingsRequest(requestUserStatistic);
            props.switchModal(props.dataModal);
            NotificationManager.success(props.translate('Success'), '', 3500);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner">
                <div className="modal-container__header">
                    <div>{props.translate('edit_setting_bundle')}</div>
                </div>
                <div className="Bundle">
                    <Input
                        hideLabel
                        placeholder={props.translate('Name')}
                        value={name}
                        onChange={(e: any) => setName(e.target.value)}
                    />
                    <Input
                        hideLabel
                        placeholder={props.translate('name_short')}
                        value={nameShort}
                        onChange={(e: any) => setNameShort(e.target.value)}
                    />
                    <Input
                        hideLabel
                        placeholder={props.translate('Target')}
                        value={target}
                        onChange={(e: any) => setTarget(e.target.value)}
                    />
                    <OutlinedDropdown
                        className="select-bundle"
                        style
                        placeholder={props.translate('Status')}
                        options={optionsStatus}
                        onSelect={(e) => setStatus(e)}
                        defaultSelected={status}
                    />
                    <OutlinedDropdown
                        className="select-bundle"
                        style
                        placeholder={props.translate('System Provider')}
                        options={getOptionProvider()}
                        onSelect={(e) => setProvider(e)}
                        defaultSelected={provider}
                    />
                </div>

                <div className="modal-container__btns" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <Button
                        loading={isLoading}
                        className="Bundle-button"
                        title={props.translate('Edit')}
                        primary
                        onClick={handleCreateBundle}
                    />

                    <Button
                        className="Bundle-button"
                        title={props.translate('Cancel')}
                        onClick={() => {
                            props.switchModal(props.dataModal);
                        }}
                    />
                </div>
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(EditBundle);
