export default {
    _styles: [
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
        { width: '20%' },
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
    ],
    _template: 'settings',
    head: [
        { title: 'id' },
        { title: 'Name' },
        { title: 'name_short' },
        { title: 'Currency' },
        { title: 'Target' },
        { title: 'Creator' },
        { title: 'Settings' },
        { title: 'Status' },
        { title: 'System Provider' },
        { title: 'Date' },
        { title: 'Action' },
    ],
    body: [],
};
