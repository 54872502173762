import { action } from 'typesafe-actions';

import { IAction } from '../../interfaces/IAction';
import {
    IRequestParams,
    ICreateGameRequest,
    ICreateMatchRequest,
    IUpdateMatchRequest,
    IUpdateGameRequest,
    IResultMatchRequest,
    IMatch,
} from '../../interfaces/IGames';

import { ActionTypes } from './types';

// Games
export const gamesRequest = (data: IAction<IRequestParams>) => action(ActionTypes.GAMES_REQUEST, data);
export const gamesSuccess = (data: any) => action(ActionTypes.GAMES_SUCCESS, data);

export const createGameRequest = (data: IAction<ICreateGameRequest>) => action(ActionTypes.CREATE_GAME_REQUEST, data);
export const createGameSuccess = (data: any) => action(ActionTypes.CREATE_GAME_SUCCESS, data);

export const updateGameRequest = (data: IAction<IUpdateGameRequest>) => action(ActionTypes.UPDATE_GAME_REQUEST, data);
export const updateGameSuccess = (data: any) => action(ActionTypes.UPDATE_GAME_SUCCESS, data);

// Matches
export const matchesRequest = (data: IAction<IRequestParams>) => action(ActionTypes.MATCHES_REQUEST, data);
export const matchesSuccess = (data: any) => action(ActionTypes.MATCHES_SUCCESS, data);

export const createMatchRequest = (data: IAction<ICreateMatchRequest>) => action(ActionTypes.CREATE_MATCH_REQUEST, data);
export const createMatchSuccess = (data: any) => action(ActionTypes.CREATE_MATCH_SUCCESS, data);

export const updateMatchRequest = (data: IAction<IUpdateMatchRequest>) => action(ActionTypes.UPDATE_MATCH_REQUEST, data);
export const updateMatchSuccess = (data: any) => action(ActionTypes.UPDATE_MATCH_SUCCESS, data);

export const deleteMatchRequest = (data) => action(ActionTypes.DELETE_MATCH_REQUEST, data);
export const deleteMatchSuccess = (data: any) => action(ActionTypes.DELETE_MATCH_SUCCESS, data);

export const resultMatchRequest = (data: IAction<IResultMatchRequest>) => action(ActionTypes.RESULT_MATCH_REQUEST, data);
export const resultMatchSuccess = (data: IMatch) => action(ActionTypes.RESULT_MATCH_SUCCESS, data);
