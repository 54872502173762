export enum ActionTypes {
    // Games
    GAMES_TYPES_REQUEST = '@@gamesTypes/GAMES_TYPES_REQUEST',
    GAMES_TYPES_SUCCESS = '@@gamesTypes/GAMES_TYPES_SUCCESS',

    // Game
    GAME_TYPE_REQUEST = '@@gamesTypes/GAME_TYPE_REQUEST',
    GAME_TYPE_SUCCESS = '@@gamesTypes/GAME_TYPE_SUCCESS',
    CREATE_GAME_TYPE_REQUEST = '@@gamesTypes/CREATE_GAME_TYPE_REQUEST',
    CREATE_GAME_TYPE_SUCCESS = '@@gamesTypes/CREATE_GAME_TYPE_SUCCESS',
    GAME_TYPE_RESET = '@@gamesTypes/GAME_TYPE_RESET',

    // Tickets
    TICKETS_REQUEST = '@@gamesTypes/TICKET_REQUEST',
    TICKETS_SUCCESS = '@@gamesTypes/TICKETS_SUCCESS',

    // MONTH Tickets
    MONTH_TICKETS_SUCCESS = '@@gamesTypes/MONTH_TICKETS_SUCCESS',

    // Create ticket
    CREATE_TICKET_REQUEST = '@@gamesTypes/CREATE_TICKET_REQUEST',
    CREATE_TICKET_SUCCESS = '@@gamesTypes/CREATE_TICKET_SUCCESS',

    // Ticket point (Checkpoint game)
    TICKET_POINT_REQUEST = '@@gamesTypes/TICKET_POINT_REQUEST',
    TICKET_POINT_SUCCESS = '@@gamesTypes/TICKET_POINT_SUCCESS',

    // One on One
    CREATE_ONE_ON_ONE_TICKET_REQUEST = '@@gamesTypes/CREATE_ONE_ON_ONE_TICKET_REQUEST',
    CREATE_ONE_ON_ONE_TICKET_SUCCESS = '@@gamesTypes/CREATE_ONE_ON_ONE_TICKET_SUCCESS',

    SET_PRICE_CHANGE_REQUEST = '@@gamesTypes/SET_PRICE_CHANGE_REQUEST',
    CHANGE_PRICE_TICKET = '@@gamesTypes/CHANGE_PRICE_TICKET',

    // Chances
    CHANCES_REQUEST = '@@gamesTypes/CHANCES_REQUEST',
    CHANCES_SUCCESS = '@@gamesTypes/CHANCES_SUCCESS',
}
