import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import { CSSTransition } from 'react-transition-group';
import { BalanceOutStatusEnum } from 'src/common/provider-common-type-util/dictionary/balance';
import Input from 'src/components/Input';
import BalancesConfirm from 'src/components/Modals/BalancesConfirm';
import DateRangePickerComponent from 'src/components/UI/DateRangePickerComponent/DateRangePickerComponent';
import { dateToTimestamp } from 'src/utils/helpers';

import balancesData from '../../assets/tableTemplates/balances';
import Button from '../../components/Button';
import UserBalanceModal from '../../components/Modals/UserBalanceModal';
import NoContent from '../../components/NoContent';
import Paginate from '../../components/Paginate';
import Table from '../../components/Table';
import { switchModalGlobal, getTypeByTypeName } from '../../helpers';
import { IEvoGame } from '../../interfaces/IEvoGames';
import { IProfile, IHandlerStatus } from '../../interfaces/IProfile';
// import { IRequestUserStatistic } from '../../interfaces/IStatistics';
import { gamesRequest } from '../../store/evoGames/actions';
import { balancesRequest } from '../../store/manageUsers/actions';

class Balances extends Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            userData: null,
            searchLogin: '',
            searchType: '',
            currentLogin: '',
            currentType: [],
            userBalanceIsOpen: false,
            userBalanceConfirmIsOpen: false,
            startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
            id: '',
            userId: '',
            amount: '',
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.props.balancesRequest({
            getParams: {
                timeTo: dateToTimestamp(this.state.endDate),
                timeFrom: dateToTimestamp(this.state.startDate),
                pageNumber: 1,
            },
        });
    }

    switchModal = (e: React.MouseEvent<HTMLElement>): void => switchModalGlobal(this, e);

    handlePage = (pageNumber: number): void => {
        const requestUserStatistic: any = {
            getParams: {
                timeTo: dateToTimestamp(this.state.endDate),
                timeFrom: dateToTimestamp(this.state.startDate),
                pageNumber,
            },
        };

        if (this.state.currentLogin) {
            requestUserStatistic.getParams.login = this.state.currentLogin;
        }

        if (this.state.currentType && this.state.currentType.length > 0) {
            requestUserStatistic.getParams.type = JSON.stringify(this.state.currentType);
        }

        if (this.state.id.length !== 0) {
            requestUserStatistic.getParams.id = this.state.id;
        }
        if (this.state.userId.length !== 0) {
            requestUserStatistic.getParams.target = this.state.userId;
        }
        if (this.state.amount.length !== 0) {
            requestUserStatistic.getParams.amount = Number(this.state.amount) * 100;
        }

        this.props.balancesRequest(requestUserStatistic);
    };

    selectDate = (dates: Date[]) => {
        const [start, end] = dates;
        this.setState({ startDate: start, endDate: end });
    };

    userBalance = (e: any): void => {
        const [target, name, type] = e.currentTarget.dataset.user.split('::');

        this.setState({
            userData: { target, name, type },
            userBalanceIsOpen: true,
        });
    };

    userBalanceConfirmAction = (e: any): void => {
        const [target, name, statuses, reason, id] = e.currentTarget.dataset.user.split('::');
        const status = Number(statuses);

        this.setState({
            userData: { target, name, status, reason, id },
            userBalanceConfirmIsOpen: true,
        });
    };

    filter = () => {
        this.setState({
            isLoading: true,
        });
        const requestUserStatistic: any = {
            getParams: {
                timeTo: dateToTimestamp(this.state.endDate),
                timeFrom: dateToTimestamp(this.state.startDate),
                pageNumber: 1,
            },
        };

        if (this.state.searchLogin) {
            requestUserStatistic.getParams.login = this.state.searchLogin;
        }

        if (this.state.searchType) {
            requestUserStatistic.getParams.status = this.state.searchType;
        }

        if (this.state.id.length !== 0) {
            requestUserStatistic.getParams.id = this.state.id;
        }
        if (this.state.userId.length !== 0) {
            requestUserStatistic.getParams.target = this.state.userId;
        }

        if (this.state.amount.length !== 0) {
            requestUserStatistic.getParams.amount = Number(this.state.amount) * 100;
        }

        this.props.balancesRequest(requestUserStatistic);

        this.setState((prevState) => ({
            currentLogin: prevState.searchLogin,
            currentType: getTypeByTypeName(prevState.searchType),
        }));

        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 500);
    };

    changeSearchLogin = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;

        this.setState({
            searchLogin: value,
        });
    };

    changeId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        const regex = /^\d*$/;

        if (regex.test(value)) {
            this.setState({
                id: value,
            });
        }
    };

    changeUserId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        const regex = /^\d*$/;

        if (regex.test(value)) {
            this.setState({
                userId: value,
            });
        }
    };

    changeAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        const regex = /^\d*$/;

        if (regex.test(value)) {
            this.setState({
                amount: value,
            });
        }
    };

    changeSearchType = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const { value } = event.target;

        this.setState({
            searchType: value,
        });
    };

    render(): JSX.Element {
        const { userData, userBalanceIsOpen, userBalanceConfirmIsOpen } = this.state;
        const { usersLoaded, balances, translate } = this.props;

        return (
            <>
                <CSSTransition in={userBalanceIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <UserBalanceModal dataModal="userBalanceIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>

                <CSSTransition in={userBalanceConfirmIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <BalancesConfirm dataModal="userBalanceConfirmIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>

                <div className="statistics-page page-container">
                    <div className="card-line card-line__header">
                        <div>{translate('Balances')}</div>
                    </div>

                    <div
                        className="statistics__filter"
                        style={{ marginTop: '10px', padding: '0 5px', display: 'flex', gap: '5px' }}
                    >
                        <div className="DTPicker-container">
                            <DateRangePickerComponent
                                onChange={this.selectDate}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                className="DTPicker-container"
                            />
                        </div>
                        <Button
                            className="btn gold-btn nowrap"
                            type="button"
                            disabled={this.props.handlerStatus.status === 'loading'}
                            onClick={this.filter}
                            isLoading={this.state.isLoading}
                        >
                            {this.props.translate('Apply')}
                        </Button>
                    </div>
                    <div className="transactions-filter">
                        <div>
                            <Input
                                className="input gold-input input-filter-login"
                                placeholder="ID"
                                type="text"
                                name="id"
                                error=""
                                value={this.state.id}
                                onChange={this.changeId}
                            />
                        </div>
                        <div>
                            <Input
                                className="input gold-input input-filter-login"
                                placeholder={this.props.translate('Target')}
                                type="text"
                                name="id"
                                error=""
                                value={this.state.userId}
                                onChange={this.changeUserId}
                            />
                        </div>
                        <div>
                            <Input
                                className="input gold-input input-filter-login"
                                placeholder={this.props.translate('Amount')}
                                type="text"
                                name="id"
                                error=""
                                value={this.state.amount}
                                onChange={this.changeAmount}
                            />
                        </div>
                        <div>
                            <select
                                value={this.state.searchType}
                                className="select gold-select select-filter-type"
                                name="status"
                                onChange={this.changeSearchType}
                            >
                                <option value="">{translate('status')}</option>
                                <option value={BalanceOutStatusEnum.BALANCE_OUT_STATUS_APPROVED}>{translate('approve')}</option>
                                <option value={BalanceOutStatusEnum.BALANCE_OUT_STATUS_CREATED}>{translate('create')}</option>
                                <option value={BalanceOutStatusEnum.BALANCE_OUT_STATUS_FINISHED}>{translate('finish')}</option>
                                <option value={BalanceOutStatusEnum.BALANCE_OUT_STATUS_REJECTED}>{translate('reject')}</option>
                            </select>
                        </div>
                    </div>
                    <CSSTransition in={usersLoaded} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                        {balances.data.length ? (
                            <>
                                <div className="statistics__content">
                                    <div className="statistics__branch">
                                        <Table
                                            tableData={{
                                                ...balancesData,
                                                body: balances.data,
                                            }}
                                            handlers={{
                                                userBalance: this.userBalance,
                                                userBalanceConfirmAction: this.userBalanceConfirmAction,
                                            }}
                                            style={undefined}
                                            tbodyHeight=""
                                            additionalData={undefined}
                                        />
                                    </div>
                                </div>

                                {/* <div className="card-line card-line__footer">
                  <div>{translate("Profit")} {statistics.changeOfBalance}</div>
                </div> */}
                                <Paginate pageCount={balances.totalCount / balances.amountPerPage} handlePage={this.handlePage} />
                            </>
                        ) : (
                            <NoContent translate={translate} />
                        )}
                    </CSSTransition>
                </div>
            </>
        );
    }
}

interface IStoreState {
    auth: {
        user: IProfile;
    };
    manageUsers: {
        balances: any;
    };
    manageHandlers: {
        handlerStatus: IHandlerStatus;
    };
    evoGames: {
        gamesList: IEvoGame[];
    };
    usersLoaded: boolean;
}

const mapStateToProps = (state: IStoreState) => ({
    user: state.auth.user,
    balances: state.manageUsers.balances,
    handlerStatus: state.manageHandlers.handlerStatus,
    gamesList: state.evoGames.gamesList,
    values: {
        start: new Date(new Date().getTime() - 604800000),
        end: new Date(),
    },
    errors: {
        start: '',
        end: '',
    },
    usersLoaded: true,
});

const mapDispatchToProps = {
    balancesRequest,
    gamesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(Balances));
