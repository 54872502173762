/* eslint-disable */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import cn from 'classnames';

import { profileLogout, balanceHistoryRequest, profileRequest } from '../../store/auth/actions';
import { createUserJoinRequest } from '../../store/manageUsers/actions';

import MobileMenu from '../Mobile/MobileMenu';

import { IProfile } from '../../interfaces/IProfile';
import { IBalanceHistory, IRequestHistoryBalance } from '../../interfaces/IBalance';
import { IUserRequestCreate } from '../../interfaces/IManageUsers';
import { IAction } from '../../interfaces/IAction';
import moment from 'moment';
import { withRouter } from 'src/withRouter';
import Locale from '../Locale';
import '../styles.scss';
import { PermissionEnum } from 'src/common/provider-common-type-util/dictionary/permission';
import Deposit from 'src/components/Deposit';

interface IHeader {
    user: IProfile;
    // history: History;
    history: any;
    isAuthenticated: boolean;
    balanceHistory: IBalanceHistory;
    createUserJoinRequest(data: IAction<IUserRequestCreate>): void;
    balanceHistoryRequest(getParams: IRequestHistoryBalance): void;
    profileRequest(): void;
    translate(key: string): string;
    profileLogout(): void;
}

const Header: React.FC<IHeader> = (props) => {
    const [showMobileMenuModal, switchMobileMenu] = React.useState(false);
    const [isTimerStarted, setIsTimerStarted] = React.useState(false);
    const [isNotificationShow, setIsNotificationShow] = React.useState(false);
    const [isOpenDeposit, setIsOpenDeposit] = React.useState(false);
    const { history } = props;

    const checkRoute = () => {
        return history?.location?.pathname === '/login';
    };

    let timer = null;

    useEffect(() => {
        if (
            props.isAuthenticated &&
            props.user.permissionLevel === PermissionEnum.USER_PLAYER_PERMISSION_LEVEL &&
            !props.balanceHistory.balanceLoaded &&
            props.balanceHistory.data.balances.length === 0
        ) {
            const currentTuesday: Date =
                new Date(moment().day(2).valueOf()).setHours(7, 0, 0, 0) > new Date().getTime()
                    ? new Date(moment().day(-5).valueOf())
                    : new Date(moment().day(2).valueOf());

            currentTuesday.setHours(7, 0, 0, 0);

            const endOfPeriod: Date =
                new Date() > new Date(currentTuesday.getTime() + 604800000)
                    ? new Date(currentTuesday.getTime() + 604800000)
                    : new Date();

            props.balanceHistoryRequest({
                getParams: {
                    pageNumber: 1,
                    target: props.user.id,
                    timeFrom: Math.round(currentTuesday.getTime() / 1000),
                    timeTo: Math.round(endOfPeriod.getTime() / 1000),
                },
                isRequestFromHeader: true,
            });
        }
    }, [props]);

    useEffect(() => {
        if (props.isAuthenticated && !isTimerStarted) {
            setIsTimerStarted(true);

            timer = setTimeout(async function updateBalance() {
                props.profileRequest();

                setTimeout(updateBalance, 15000);
            }, 15000);
        }

        if (!isNotificationShow && localStorage.roomNotifications) {
            JSON.parse(localStorage.roomNotifications).forEach((notification) => {
                window.NotificationManager.info('', notification, 86400000, () => {
                    const currentStorageState = localStorage.roomNotifications ? JSON.parse(localStorage.roomNotifications) : '';
                    const newStorageState = currentStorageState.filter((item) => item !== notification);
                    localStorage.roomNotifications = JSON.stringify(newStorageState);
                });
            });

            setIsNotificationShow(true);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [isTimerStarted, isNotificationShow, props]);

    const handleLogout = () => {
        props.profileLogout();
        props.history.push('/');
        switchMobileMenu(false);
    };

    return (
        <>
            <MobileMenu
                isOpen={showMobileMenuModal}
                user={props.user}
                profileLogout={props.profileLogout}
                translate={props.translate}
                switchMobileMenu={() => switchMobileMenu(!showMobileMenuModal)}
            />

            {props.isAuthenticated && !checkRoute() && (
                <header
                    className={cn({
                        'best-at-table-color': history.location.pathname === '/best-at-table/games',
                        'one-on-one-color': history.location.pathname === '/one-on-one',
                        'checkpoint-color': history.location.pathname === '/checkpoint',
                        'month-prize-color': props.history.location.pathname === '/month-prize',
                        'jackpot-color': props.history.location.pathname === '/jackpot',
                        'daily-color': props.history.location.pathname === '/daily',
                    })}
                >
                    <div className="header__inner">
                        <div className="right-side">
                            <div className="links">
                                <Locale />
                                {props.isAuthenticated ? (
                                    <>
                                        <div style={{}}>
                                            {props.user.permissionLevel === PermissionEnum.USER_AGENT_PERMISSION_LEVEL ? (
                                                <>
                                                    <div
                                                        className="links__link links__link--balance"
                                                        onClick={() => setIsOpenDeposit(true)}
                                                    >
                                                        <>
                                                            {props.translate('Balance') + ':'}
                                                            {props.user.balance + props.user.scedualPaymentBalance}
                                                        </>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div
                                            className="userBlock"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',
                                                height: '30px',
                                                fontSize: '10px',
                                            }}
                                        >
                                            <div style={{ fontSize: '18px' }} className="links__link string-trim">
                                                {props.user.login}
                                            </div>
                                            <div
                                                style={{ height: '30px', fontSize: '18px', padding: '0px 8px' }}
                                                onClick={handleLogout}
                                                className="logoutButton btn gold-btn"
                                            >
                                                {props.translate('Logout')}
                                            </div>
                                        </div>

                                        <div
                                            className="links__link mobile-menu__btn"
                                            onClick={() => switchMobileMenu(!showMobileMenuModal)}
                                        >
                                            <div /> <div /> <div />
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </header>
            )}
            {isOpenDeposit && <Deposit open={isOpenDeposit} handleClose={() => setIsOpenDeposit(false)} />}
        </>
    );
};

interface IStoreState {
    auth: {
        user: IProfile;
        isAuthenticated: boolean;
        balanceHistory: IBalanceHistory;
    };
}

export default withRouter(
    connect(
        (state: any) => ({
            user: state.auth.user,
            isAuthenticated: state.auth.isAuthenticated,
            balanceHistory: state.auth.balanceHistory,
        }),
        {
            profileLogout,
            createUserJoinRequest,
            balanceHistoryRequest,
            profileRequest,
        },
    )(withTranslate(Header)),
);
