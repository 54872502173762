import React from 'react';

import balance from './balance';
import balances from './balances';
import betting from './betting';
import chance from './chance';
import manageUsers from './manageUsers';
import provider from './provider';
import providerStatistics from './providerStatistics';
import settings from './settings';
import statistics from './statistics';

export const theadTemplate = ({ head, _styles, additionalData, translate }) => (
    <tr>
        {head.map((item, i) => {
            /* <Head setting start> */
            // if the column has a permission level and a column permission level above or equal my permission level
            if (item.permissionLevel && additionalData.permissionLevel <= item.permissionLevel) {
                return null;
            }
            if (item.disableMatchEditBtn && additionalData.disableMatchEditBtn) {
                return null;
            }
            /* </ Head setting end> */
            let positionStyle = {};

            if (item.title === 'Login') {
                positionStyle =
                    localStorage.locale === 'he' ? { paddingLeft: '30px', right: 0 } : { paddingLeft: '30px', left: 0 };
            }
            return (
                <th
                    className={item.title === 'Login' ? 'td-sticky' : ''}
                    style={{
                        ..._styles[i],
                        ...positionStyle,
                    }}
                    key={i}
                >
                    {translate(item.title) || item.title}
                </th>
            );
        })}
    </tr>
);

export const tbodyTemplate = ({
    body,
    _template,
    translate,
    additionalData,
    handlers,
    _styles,
    searchedId,
    externalDataObj,
    country,
}) =>
    body.map((item, index, arr) => {
        const isLastElem = index === arr.length - 1;

        const dataTemplate: any = {
            additionalData,
            handlers,
            _styles,
            searchedId,
            externalDataObj,
            translate,
            isLastElem,
            country,
        };

        switch (_template) {
            case 'balance':
                return balance(item, dataTemplate);
            case 'chance':
                return chance(item, dataTemplate);
            case 'manage-users':
                return manageUsers(item, dataTemplate);
            case 'statistics':
                return statistics(item, dataTemplate);
            case 'betting':
                return betting(item, dataTemplate);
            case 'provider':
                return provider(item, dataTemplate);
            case 'provider-statistics':
                return providerStatistics(item, dataTemplate);
            case 'balances':
                return balances(item, dataTemplate);
            case 'settings':
                return settings(item, dataTemplate);

            default:
                return null;
        }
    });
