import { action } from 'typesafe-actions';

import { IAction } from '../../interfaces/IAction';
import { IRequestTransactionBalance } from '../../interfaces/IBalance';
import { IComment, IRequestComment } from '../../interfaces/IComment';
import { ILoginHistory } from '../../interfaces/ILoginHistory';
import {
    IProfileWithChildren,
    IRequestCashback,
    IRequestProfit,
    IUserRequestCreate,
    IRequestBanUser,
    IRequestChangePassword,
    IRequestProviderMaxLimit,
} from '../../interfaces/IManageUsers';
import { IProfile } from '../../interfaces/IProfile';
import {
    IRequestGetScedualPayment,
    IScedualPayment,
    IRequestCreateScedualPayment,
    IRequestDeleteScedualPayment,
} from '../../interfaces/IScedualPayment';
import { IStatisticGet, IRequestUserStatistic, IProviderStatisticGet } from '../../interfaces/IStatistics';

import { ActionTypes } from './types';

export const manageUsersRequest = (data: any) => action(ActionTypes.MANAGE_USERS_REQUEST, data);
export const manageUsersSuccess = (data: IProfileWithChildren[]) => action(ActionTypes.MANAGE_USERS_SUCCESS, data);
export const setFilterReport = (data: number) => action(ActionTypes.FILTER_REPORT, data);
export const setFilterTransaction = (data: { date: string; provider: number }) => action(ActionTypes.FILTER_TRANSACTION, data);
export const manageUsersExpansion = (data: any) => action(ActionTypes.MANAGE_USERS_EXPANSION, data);

export const statisticsRequest = (data: IAction<IRequestUserStatistic>) => action(ActionTypes.STATISTICS_REQUEST, data);
export const statisticsSuccess = (data: IStatisticGet) => action(ActionTypes.STATISTICS_SUCCESS, data);

export const bettingRequest = (data: IAction<IRequestUserStatistic>) => action(ActionTypes.BETTING_REQUEST, data);
export const bettingSuccess = (data: IStatisticGet) => action(ActionTypes.BETTING_SUCCESS, data);

export const balancesRequest = (data: IAction<any>) => action(ActionTypes.BALANCES_REQUEST, data);
export const balancesSuccess = (data: any) => action(ActionTypes.BALANCES_SUCCESS, data);

export const settingsRequest = (data: IAction<any>) => action(ActionTypes.SETTINGS_REQUEST, data);
export const settingsSuccess = (data: any) => action(ActionTypes.SETTINGS_SUCCESS, data);

export const settingsUpdate = (data: any) => action(ActionTypes.SETTINGS_UPDATE, data);

export const providerStatisticsRequest = (data: IAction<IRequestUserStatistic>) =>
    action(ActionTypes.PROVIDER_STATISTICS_REQUEST, data);
export const providerStatisticsSuccess = (data: IProviderStatisticGet[]) => action(ActionTypes.PROVIDER_STATISTICS_SUCCESS, data);
export const providerUsersExpansion = (data: any) => action(ActionTypes.PROVIDER_USERS_EXPANSION, data);

export const createUserRequest = (data: IAction<IUserRequestCreate>) => action(ActionTypes.CREATE_USER_REQUEST, data);
export const createUserSuccess = (data: IProfile) => action(ActionTypes.CREATE_USER_SUCCESS, data);

export const createUserJoinRequest = (data: IAction<IUserRequestCreate>) => action(ActionTypes.CREATE_USER_JOIN_REQUEST, data);
export const createUserJoinSuccess = (data: IProfile) => action(ActionTypes.CREATE_USER_JOIN_SUCCESS, data);

export const addBalanceRequest = (data: IAction<IRequestTransactionBalance>) => action(ActionTypes.ADD_BALANCE_REQUEST, data);
export const takeBalanceRequest = (data: IAction<IRequestTransactionBalance>) => action(ActionTypes.TAKE_BALANCE_REQUEST, data);
export const userBalanceSuccess = (data: { target: IProfile; creator: IProfile }) =>
    action(ActionTypes.USER_BALANCE_SUCCESS, data);

export const userBanRequest = (data: IAction<IRequestBanUser>) => action(ActionTypes.USER_BAN_REQUEST, data);
export const userBanSuccess = (data: IProfile) => action(ActionTypes.USER_BAN_SUCCESS, data);

export const cashbackRequest = (data: IAction<IRequestCashback>) => action(ActionTypes.CASHBACK_REQUEST, data);
export const cashbackSuccess = (data: IProfile) => action(ActionTypes.CASHBACK_SUCCESS, data);

export const profitRequest = (data: IAction<IRequestProfit>) => action(ActionTypes.PROFIT_REQUEST, data);
export const profitSuccess = (data: IProfile) => action(ActionTypes.PROFIT_SUCCESS, data);

export const scheludeRequest = (data: IAction<any>) => action(ActionTypes.SCHELUDE_REQUEST, data);
export const scheludeSuccess = (data: any) => action(ActionTypes.SCHELUDE_SUCCESS, data);

export const providerMaxLimitRequest = (data: IAction<IRequestProviderMaxLimit>) =>
    action(ActionTypes.PROVIDER_MAX_LIMIT_REQUEST, data);
export const providerMaxLimitSuccess = (data: IProfile) => action(ActionTypes.PROVIDER_MAX_LIMIT_SUCCESS, data);

export const changePasswordRequest = (data: IAction<IRequestChangePassword>) => action(ActionTypes.CHANGE_PASSWORD_REQUEST, data);
export const changePasswordSuccess = () => action(ActionTypes.CHANGE_PASSWORD_SUCCESS);

export const commentRequest = (data: IAction<IRequestComment>) => action(ActionTypes.GET_COMMENT_REQUEST, data);
export const commentSuccess = (data: IComment) => action(ActionTypes.COMMENT_SUCCESS, data);
export const changeCommentRequest = (data: IAction<IRequestComment>) => action(ActionTypes.CHANGE_COMMENT_REQUEST, data);

export const scedualPaymentRequest = (data: IAction<IRequestGetScedualPayment>) =>
    action(ActionTypes.SCEDUAL_PAYMENT_REQUEST, data);
export const scedualPaymentSuccess = (data: IScedualPayment) => action(ActionTypes.SCEDUAL_PAYMENT_SUCCESS, data);

export const setScedualPaymentRequest = (data: IAction<IRequestCreateScedualPayment>) =>
    action(ActionTypes.SET_SCEDUAL_PAYMENT_REQUEST, data);

export const updateStatusDocuments = (data: IAction<any>) => action(ActionTypes.UPDATE_STATUS_DOCUMENT, data);

export const deleteScedualPaymentRequest = (data: IAction<IRequestDeleteScedualPayment>) =>
    action(ActionTypes.DELETE_SCEDUAL_PAYMENT_REQUEST, data);

export const loginHistoryRequest = (id: number) => action(ActionTypes.GET_LOGIN_HISTORY_REQUEST, id);
export const loginHistorySuccess = (data: ILoginHistory) => action(ActionTypes.GET_LOGIN_HISTORY_SUCCESS, data);

export const onUserSelect = (login: string) => action(ActionTypes.ON_USER_SELECT, login);
export const chancesRequest = (data: IAction<any>) => action(ActionTypes.CHANCES_REQUEST, data);
export const chancesSuccess = (data: any) => action(ActionTypes.CHANCES_SUCCESS, data);
export const createTicketRequest = (data: any) => action(ActionTypes.CREATE_TICKET_REQUEST, data);
