/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as Sorted } from 'src/assets/images/sorted.svg';
import { EnumSortDirection } from 'src/common/enum';

const TableReport = ({ showColumn, translate, searchType, handleChangeSorted, activeSorted, sortedPosition, currentItems }) => {
    return (
        <div className="statistics__content">
            <div className="statistics__branch">
                <CSSTransition in={true} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <div
                        className={cn('table', {
                            'tbody-height': '40px',
                        })}
                    >
                        <table id="report">
                            <thead>
                                <tr style={{ borderBottom: '1px solid #cda663' }}>
                                    <th
                                        style={{
                                            width: '',
                                            borderRight: '1px solid #cda663',
                                            textAlign: 'center',
                                            display: 'flex',
                                            gap: '10px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    ></th>
                                    {showColumn('PROVIDER_CASINO') && (
                                        <th
                                            colSpan={4}
                                            style={{
                                                borderRight: '1px solid #cda663',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {translate('Casino')}
                                        </th>
                                    )}
                                    {showColumn('PROVIDER_RACING') && (
                                        <th
                                            colSpan={4}
                                            style={{
                                                borderRight: '1px solid #cda663',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {translate('Racing')}
                                        </th>
                                    )}
                                    {showColumn('SYSTEM_GAME') && (
                                        <th
                                            colSpan={4}
                                            style={{
                                                textAlign: 'center',
                                                borderRight: !searchType ? '1px solid #cda663' : 'none',
                                            }}
                                        >
                                            {translate('system-game')}
                                        </th>
                                    )}
                                    {!searchType && (
                                        <th colSpan={1} style={{ textAlign: 'center' }}>
                                            {translate('total')}
                                        </th>
                                    )}
                                </tr>
                                <tr style={{ borderBottom: '1px solid #cda663' }}>
                                    <th
                                        style={{
                                            width: '100px',
                                            borderRight: '1px solid #cda663',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {translate('UserName')}
                                    </th>
                                    {showColumn('PROVIDER_CASINO') && (
                                        <>
                                            {' '}
                                            <th
                                                className="item-table"
                                                onClick={() => handleChangeSorted('PROVIDER_CASINO', 'played')}
                                            >
                                                {translate('Played')}
                                                {activeSorted('PROVIDER_CASINO', 'played') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('PROVIDER_CASINO', 'played') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                            <th
                                                className="item-table"
                                                onClick={() => handleChangeSorted('PROVIDER_CASINO', 'profit')}
                                            >
                                                {translate('Profit')}
                                                {activeSorted('PROVIDER_CASINO', 'profit') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('PROVIDER_CASINO', 'profit') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                            <th
                                                className="item-table"
                                                onClick={() => handleChangeSorted('PROVIDER_CASINO', 'refunded')}
                                            >
                                                {translate('Refunded')}
                                                {activeSorted('PROVIDER_CASINO', 'refunded') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('PROVIDER_CASINO', 'refunded') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                            <th
                                                className="item-table"
                                                style={{
                                                    borderRight: '1px solid #cda663',
                                                }}
                                                onClick={() => handleChangeSorted('PROVIDER_CASINO', 'won')}
                                            >
                                                {translate('Won')}
                                                {activeSorted('PROVIDER_CASINO', 'won') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('PROVIDER_CASINO', 'won') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                        </>
                                    )}
                                    {showColumn('PROVIDER_RACING') && (
                                        <>
                                            <th
                                                onClick={() => handleChangeSorted('PROVIDER_RACING', 'played')}
                                                className="item-table"
                                            >
                                                {translate('Played')}
                                                {activeSorted('PROVIDER_RACING', 'played') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('PROVIDER_RACING', 'played') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                            <th
                                                onClick={() => handleChangeSorted('PROVIDER_RACING', 'profit')}
                                                className="item-table"
                                            >
                                                {translate('Profit')}
                                                {activeSorted('PROVIDER_RACING', 'profit') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('PROVIDER_RACING', 'profit') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                            <th
                                                onClick={() => handleChangeSorted('PROVIDER_RACING', 'refunded')}
                                                className="item-table"
                                            >
                                                {translate('Waiting')}
                                                {activeSorted('PROVIDER_RACING', 'refunded') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('PROVIDER_RACING', 'refunded') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                            <th
                                                className="item-table"
                                                style={{
                                                    borderRight: '1px solid #cda663',
                                                }}
                                                onClick={() => handleChangeSorted('PROVIDER_RACING', 'won')}
                                            >
                                                {translate('Won')}
                                                {activeSorted('PROVIDER_RACING', 'won') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('PROVIDER_RACING', 'won') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                        </>
                                    )}
                                    {showColumn('SYSTEM_GAME') && (
                                        <>
                                            <th
                                                onClick={() => handleChangeSorted('SYSTEM_GAME', 'played')}
                                                className="item-table"
                                            >
                                                {translate('Played')}
                                                {activeSorted('SYSTEM_GAME', 'played') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('SYSTEM_GAME', 'played') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                            <th
                                                onClick={() => handleChangeSorted('SYSTEM_GAME', 'profit')}
                                                className="item-table"
                                            >
                                                {translate('Profit')}
                                                {activeSorted('SYSTEM_GAME', 'profit') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('SYSTEM_GAME', 'profit') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                            <th
                                                onClick={() => handleChangeSorted('SYSTEM_GAME', 'refunded')}
                                                className="item-table"
                                            >
                                                {translate('Refunded')}
                                                {activeSorted('SYSTEM_GAME', 'refunded') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('SYSTEM_GAME', 'refunded') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                            <th
                                                onClick={() => handleChangeSorted('SYSTEM_GAME', 'won')}
                                                className="item-table"
                                                style={{
                                                    borderRight: !searchType ? '1px solid #cda663' : 'none',
                                                }}
                                            >
                                                {translate('Won')}
                                                {activeSorted('SYSTEM_GAME', 'won') && (
                                                    <Sorted
                                                        className={
                                                            activeSorted('SYSTEM_GAME', 'won') === EnumSortDirection.DESC
                                                                ? 'iconSortDesc'
                                                                : 'iconSortAsc'
                                                        }
                                                    />
                                                )}
                                            </th>
                                        </>
                                    )}

                                    {!searchType && (
                                        <th onClick={() => handleChangeSorted('totalProfit', 'profit')} className="item-table">
                                            {translate('Profit')}
                                            {activeSorted('totalProfit', 'profit') && (
                                                <Sorted
                                                    className={
                                                        activeSorted('totalProfit', 'profit') === EnumSortDirection.DESC
                                                            ? 'iconSortDesc'
                                                            : 'iconSortAsc'
                                                    }
                                                />
                                            )}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {sortedPosition(currentItems).map((item) => (
                                    <tr key={item.username} style={{ borderBottom: '1px solid #cda663' }}>
                                        <td
                                            style={{
                                                width: '100px',
                                                borderRight: '1px solid #cda663',
                                                textAlign: 'center',
                                                whiteSpace: 'break-spaces',
                                            }}
                                        >
                                            {item.name}
                                        </td>
                                        {showColumn('PROVIDER_CASINO') && (
                                            <>
                                                {' '}
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {item.report_with_profit.PROVIDER_CASINO.played / 100}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {item.report_with_profit.PROVIDER_CASINO.profit / 100}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {item.report_with_profit.PROVIDER_CASINO.refunded / 100}
                                                </td>
                                                <td
                                                    style={{
                                                        width: '100px',
                                                        borderRight: '1px solid #cda663',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {item.report_with_profit.PROVIDER_CASINO.won / 100}
                                                </td>
                                            </>
                                        )}
                                        {showColumn('PROVIDER_RACING') && (
                                            <>
                                                {' '}
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {item.report_with_profit.PROVIDER_RACING.played / 100}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {item.report_with_profit.PROVIDER_RACING.profit / 100}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {item.report_with_profit.PROVIDER_RACING.waiting / 100}
                                                </td>
                                                <td
                                                    style={{
                                                        width: '100px',
                                                        borderRight: '1px solid #cda663',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {item.report_with_profit.PROVIDER_RACING.won / 100}
                                                </td>
                                            </>
                                        )}
                                        {showColumn('SYSTEM_GAME') && (
                                            <>
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {item.report_with_profit.SYSTEM_GAME.played / 100}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {item.report_with_profit.SYSTEM_GAME.profit / 100}
                                                </td>
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {item.report_with_profit.SYSTEM_GAME.refunded / 100}
                                                </td>
                                                <td
                                                    style={{
                                                        width: '100px',
                                                        textAlign: 'center',
                                                        borderRight: !searchType ? '1px solid #cda663' : 'none',
                                                    }}
                                                >
                                                    {item.report_with_profit.SYSTEM_GAME.won / 100}
                                                </td>
                                            </>
                                        )}

                                        {!searchType && (
                                            <td style={{ width: '100px', textAlign: 'center' }}>
                                                {item.report_with_profit.totalProfit / 100}
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
};

export default TableReport;
