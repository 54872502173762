/* eslint-disable */
import React from 'react';
import { formatUserTimeZone } from 'src/utils/helpers';
import { ReactComponent as Edit } from 'src/assets/images/edit.svg';
import { ReactComponent as Delete } from 'src/assets/images/delete.svg';
import { ReactComponent as Settings } from 'src/assets/images/settings.svg';

import './styles.scss';
import { SettingDictionary } from 'src/common/provider-common-type-util/dictionary/setting';
import { SystemProviderEnum } from 'src/common/provider-common-type-util/dictionary/system-provider';

export default (item, { _styles, handlers, translate }) => (
    <tr key={item.id}>
        <td style={{ ..._styles[0] }}>{item.id}</td>
        <td style={{ ..._styles[1] }}>{item.name}</td>
        <td style={{ ..._styles[2] }}>{item.nameShort}</td>
        <td style={{ ..._styles[3] }}>{item.currency}</td>
        <td style={{ ..._styles[4] }}>{item.target.name}</td>
        <td style={{ ..._styles[5] }}>{item.creator}</td>
        <td style={{ ..._styles[6] }}>{item.settings}</td>
        <td style={{ ..._styles[7] }}>{SettingDictionary[item.status]}</td>
        <td style={{ ..._styles[7] }}>{item.systemProvider ? SystemProviderEnum[item.systemProvider] : ''}</td>
        <td style={{ ..._styles[8] }}>{formatUserTimeZone(item.createDate).split('T')[0]}</td>
        <td style={{ ..._styles[4], display: 'flex', gap: '10px' }} className="btn-link-styles">
            <span data-user={`${item.id}`} onClick={handlers.settings}>
                <div className="icon">
                    <Settings />
                </div>
            </span>
            <span
                data-user={`${item.name}::${item.nameShort}::${item.status}::${item.target.id}::${item.id}::${item.systemProvider}`}
                onClick={handlers.editBundle}
            >
                <div className="icon">
                    <Edit />
                </div>
            </span>
            <span data-user={`${item.id}::${item.name}`} onClick={handlers.deleteBundle}>
                <div className="icon">
                    <Delete />
                </div>
            </span>
        </td>
    </tr>
);
