/* eslint-disable */
import React, { useMemo, useState } from 'react';

import FormHOC from '../FormHOC';
import imageLogin from 'src/assets/images/loginPreview.png';
import { ReactComponent as User } from 'src/assets/images/user.svg';
import { ReactComponent as PasswordIcon } from 'src/assets/images/closed.svg';
import Input from 'src/components/NewUI/inputV2/input';
import Button from 'src/components/NewUI/buttonV2/Button';
import CheckBox from 'src/components/NewUI/checkBoxV2';
import Dropdown from 'src/components/NewUI/dropdown/Dropdown';
import { translationOptions } from 'src/utils/utils';
import validator from './validator';
import './login.scss';
import { useDispatch } from 'react-redux';
import { IntlActions } from 'react-redux-multilingual';

interface ILoginForm {
    values: {
        login: string;
        password: string;
    };
    errors: {
        login: any;
        password: any;
    };
    isLoading: boolean;
    onSubmit: () => void;
    handleChange: () => void;
    translate: (key: string) => string;
    loginRequest: any;
}

const Form: React.FC<ILoginForm> = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [disbaled, setDisbaled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState('password');
    const dispatch = useDispatch();

    const defaulLanguge = useMemo(() => {
        const lang = localStorage.getItem('locale');
        return translationOptions.find((el) => el.value === lang) || translationOptions[0];
    }, []);

    const changeType = (type: string) => {
        setType(type);
    };

    const loginHandle = async () => {
        setIsLoading(true);
        const option = {
            login: username,
            password,
            remember,
        };
        setDisbaled(true);
        await props.loginRequest(option);

        setTimeout(() => {
            setIsLoading(false);
            setDisbaled(false);
        }, 1000);
    };

    const selectLocale = (selectedOption) => {
        localStorage.setItem('locale', selectedOption);

        const body = document.querySelector('body');
        if (selectedOption === 'he') {
            body.classList.add('rtl');
        } else {
            body.classList.remove('rtl');
        }

        dispatch(IntlActions.setLocale(selectedOption));
    };

    return (
        <div className="Login">
            <div className="shine2" />
            <div className="shine" />
            <Dropdown
                onSelect={({ value }) => {
                    selectLocale(value);
                }}
                options={translationOptions}
                defaultSelected={defaulLanguge}
                className="Login-dropdown"
                translate={props.translate}
            />
            <div className="Login-logo">
                <img src={imageLogin} alt="" />
            </div>
            <div className="Login-form">
                <div className="Login-content">
                    <div className="Login-content-title">
                        <p>{props.translate('sign-in')}</p>
                    </div>
                    <form className="Login-content-form">
                        <div className="Login-content-inputs">
                            <Input
                                icon={<User />}
                                name="nickname"
                                type="email"
                                id="email"
                                className="Login-content-inputs-input"
                                placeholder={props.translate('Login')}
                                value={username}
                                onChange={({ target }) => {
                                    setUsername(target.value.toLowerCase());
                                }}
                            />
                            <Input
                                icon={<PasswordIcon />}
                                name="password"
                                id="password"
                                className="Login-content-inputs-input"
                                placeholder={props.translate('enter_password')}
                                value={password}
                                password
                                changeType={changeType}
                                type={type}
                                onChange={({ target }) => {
                                    setPassword(target.value);
                                }}
                            />
                        </div>
                        <div className="Login-content-checkboxs">
                            <CheckBox
                                className="Login-content-checkbox"
                                label={props.translate('remember')}
                                checked={remember}
                                onChange={() => {
                                    setRemember(!remember);
                                }}
                            />
                        </div>
                        <Button
                            className="Login-content-signin"
                            title={props.translate('sign-in')}
                            primary
                            onClick={loginHandle}
                            loading={isLoading}
                            disbaled={disbaled}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FormHOC(
    Form,
    {
        login: '',
        password: '',
    },
    validator,
);
