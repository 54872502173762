import React from 'react';

import { stringTrim } from '../../helpers';
import Button from '../Button';
import ModalWindow from '../ModalWindow';

import ModalHOC from './ModalHOC';

const BundleDelete: React.FC<any> = (props) => {
    const { userData } = props;

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner">
                <div className="modal-container__header">
                    <div>{props.translate('Delete')}?</div>
                    <div style={{ color: '#e6bc73' }}>{stringTrim(userData.name)}</div>
                </div>

                <div className="modal-container__btns">
                    <Button onClick={() => props.deleteBundle(Number(userData.id))} className="btn gold-btn">
                        Confirm
                    </Button>
                    <Button className="btn grey-btn" type="button" data-modal={props.dataModal} onClick={props.switchModal}>
                        Cancel
                    </Button>
                </div>
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(BundleDelete);
