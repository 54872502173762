export enum ActionTypes {
    LOGOS_TEAM_REQUEST = '@@logos/LOGOS_TEAM_REQUEST',
    LOGOS_TEAM_SUCCESS = '@@logos/LOGOS_TEAM_SUCCESS',
    LOGOS_LEAGUE_REQUEST = '@@logos/LOGOS_LEAGUE_REQUEST',
    LOGOS_LEAGUE_SUCCESS = '@@logos/LOGOS_LEAGUE_SUCCESS',

    CREATE_LOGO_REQUEST = '@@logos/CREATE_LOGO_REQUEST',
    CREATE_LOGO_SUCCESS = '@@logos/CREATE_LOGO_SUCCESS',

    UPDATE_LOGO_REQUEST = '@@logos/UPDATE_LOGO_REQUEST',
    UPDATE_LOGO_SUCCESS = '@@logos/UPDATE_LOGO_SUCCESS',

    DELETE_LOGO_REQUEST = '@@logos/DELETE_LOGO_REQUEST',
    DELETE_LOGO_SUCCESS = '@@logos/DELETE_LOGO_SUCCESS',
}
