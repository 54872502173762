import React, { useMemo } from 'react';
import { DocumentStatusEnum } from 'src/common/provider-common-type-util/dictionary/document';

import Button from '../Button';
import ModalWindow from '../ModalWindow';

import ModalHOC from './ModalHOC';
import './styles.scss';

const DocumentsModal = (props) => {
    const { userData, translate } = props;

    const handleClickButton = (status: number) => {
        const options = {
            id: userData.documentId,
            status,
        };
        props.updateStatusDocuments(options);
    };

    const handleImageClick = (imageUrl) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'image';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const status = useMemo(() => {
        switch (userData.statusDocument) {
            case DocumentStatusEnum.DOCUMENT_STATUS_CREATED:
                return translate('on_review');
            case DocumentStatusEnum.DOCUMENT_STATUS_REJECTED:
                return translate('rejected');
            case DocumentStatusEnum.DOCUMENT_STATUS_APPROVED:
                return translate('approved');
            default:
                return '';
        }
    }, [translate, userData.statusDocument]);

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner" style={{ maxWidth: '800px' }}>
                <div className="modal-container__header">
                    <div>{props.translate('documents')}</div>
                    <div>{userData.name}</div>
                    <div>({status})</div>
                </div>
                <div className="list-documents">
                    {userData.documents.map((item, index) => (
                        <img key={index} src={item} alt="" onClick={() => handleImageClick(item)} style={{ cursor: 'pointer' }} />
                    ))}
                </div>
                <div className="modal-container__btns">
                    {(userData.statusDocument === DocumentStatusEnum.DOCUMENT_STATUS_CREATED ||
                        userData.statusDocument === DocumentStatusEnum.DOCUMENT_STATUS_REJECTED) && (
                        <Button
                            onClick={() => handleClickButton(DocumentStatusEnum.DOCUMENT_STATUS_APPROVED)}
                            className="btn gold-btn"
                            type="submit"
                        >
                            {props.translate('approve')}
                        </Button>
                    )}
                    {(userData.statusDocument === DocumentStatusEnum.DOCUMENT_STATUS_CREATED ||
                        userData.statusDocument === DocumentStatusEnum.DOCUMENT_STATUS_APPROVED) && (
                        <Button
                            onClick={() => handleClickButton(DocumentStatusEnum.DOCUMENT_STATUS_REJECTED)}
                            className="btn grey-btn"
                            type="button"
                        >
                            {props.translate('reject')}
                        </Button>
                    )}
                </div>
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(DocumentsModal);
