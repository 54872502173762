/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import './styles.scss';
import { api } from 'src/api';
import { ReactComponent as Loading } from 'src/assets/images/loading.svg';
import { UserGenderEnum } from 'src/common/provider-common-type-util/dictionary/user';

import ModalWindow from '../ModalWindow';
import { premisionLevel } from '../Table/balances';

const InfoUser: React.FC<any> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { handlers, translate, country } = props;

    const handleClickLogInAccount = async () => {
        setIsLoading(true);

        const options = {
            target: props.userData.user.id,
        };

        const resp = await api.post('/auth/child', options);

        if (resp.data) {
            const token = resp.data;
            window.open(`${process.env.REACT_APP_API_URL || window.runtimeConfig.API_URL}/?token=${token}`, '_blank');
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner Deposit">
                {props.userData?.user && (
                    <div className="Deposit-table">
                        <div className="Deposit-table-heading">
                            <div className="Deposit-table-heading-item">
                                {translate('info-user')} {`( ${props.userData.user.login} )`}
                            </div>
                        </div>
                        <div className="Deposit-table-body">
                            <div className="Deposit-table-row">
                                <div className="Deposit-table-row-item-first">{props.translate('Password')}</div>
                                <div
                                    className="Deposit-table-row-item  btn-link-styles"
                                    data-user={`${props.userData.user.id}::${props.userData.user.name}`}
                                    onClick={handlers.changePassword}
                                >
                                    {props.translate('Change')}
                                </div>
                            </div>
                            {premisionLevel() === 8 && (
                                <>
                                    <div className="Deposit-table-row">
                                        <div className="Deposit-table-row-item-first">{translate('secondName')}</div>
                                        <div className="Deposit-table-row-item">{props.userData.user.secondName}</div>
                                    </div>
                                    <div className="Deposit-table-row">
                                        <div className="Deposit-table-row-item-first">{translate('birth')}</div>
                                        <div className="Deposit-table-row-item">{props.userData.user.birth}</div>
                                    </div>
                                    <div className="Deposit-table-row">
                                        <div className="Deposit-table-row-item-first">{translate('country')}</div>
                                        <div className="Deposit-table-row-item">
                                            {country.find((el) => el.value === props.userData.user.country)?.label}
                                        </div>
                                    </div>
                                    <div className="Deposit-table-row">
                                        <div className="Deposit-table-row-item-first">{translate('city')}</div>
                                        <div className="Deposit-table-row-item">{props.userData.user.city}</div>
                                    </div>
                                    <div className="Deposit-table-row">
                                        <div className="Deposit-table-row-item-first">{translate('email')}</div>
                                        <div className="Deposit-table-row-item">
                                            {props.userData.user.emails.length !== 0 ? props.userData.user.emails[0].payload : ''}
                                        </div>
                                    </div>
                                    <div className="Deposit-table-row">
                                        <div className="Deposit-table-row-item-first">{translate('gender')}</div>
                                        <div className="Deposit-table-row-item">{UserGenderEnum[props.userData.user.gender]}</div>
                                    </div>
                                    <div className="Deposit-table-row">
                                        <div className="Deposit-table-row-item-first">{translate('phone')}</div>
                                        <div className="Deposit-table-row-item">
                                            {props.userData.user.phones.length !== 0 ? props.userData.user.phones[0].payload : ''}
                                        </div>
                                    </div>
                                    <div className="Deposit-table-row">
                                        <div className="Deposit-table-row-item-first">{translate('login-account')}</div>
                                        <div
                                            className="Deposit-table-row-item  btn-link-styles"
                                            data-user={`${props.userData.user.id}::${props.userData.user.name}`}
                                            onClick={handleClickLogInAccount}
                                        >
                                            {isLoading ? <Loading className="loading" /> : translate('sign-in')}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </ModalWindow>
    );
};

export default InfoUser;
