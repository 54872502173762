import React from 'react';
import onClickOutside from 'react-onclickoutside';

import Input from './Input';
import Logo from './Logo';

class InputFilter extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            listData: this.filterListData(this.props.inputProps.value),
            isOpen: false,
        };
    }

    setOpen = () => {
        this.setState({
            isOpen: true,
        });
    };

    filterListData = (name) =>
        this.props.listData.filter(
            (item) => item?.name?.toLowerCase().lastIndexOf(typeof name === 'string' ? name.toLowerCase() : null) !== -1 && item,
        );

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;

        this.setState({
            listData: this.filterListData(name),
        });

        this.props.inputProps.onChange(e);
    };

    selectItem = (e: React.MouseEvent<HTMLInputElement>) => {
        const { name } = e.currentTarget.dataset;
        const { id } = e.currentTarget.dataset;

        this.setState({
            listData: this.filterListData(name),
            isOpen: false,
        });

        this.props.selectInputFilter(this.props.selectById ? id : name, this.props.inputProps.name);
    };

    // TODO handleClickOutside
    handleClickOutside = () => {
        this.setState({
            isOpen: false,
        });
    };

    render() {
        const { isOpen } = this.state;

        return (
            <div className="input-filter">
                <Input {...this.props.inputProps} onFocus={this.setOpen} onChange={this.handleChange} />
                {isOpen && (
                    <div className="input-filter__list">
                        {this.state.listData.map((item, i) => (
                            <div
                                key={i}
                                className="input-filter__list__item"
                                data-name={item.name}
                                data-id={item.id}
                                onClick={this.selectItem}
                            >
                                {this.props.hasLogo ? <Logo url={item.url} name={item.name} /> : null}
                                {this.props.hasLogo ? <span>{item.name}</span> : <span>{item.login}</span>}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default onClickOutside(InputFilter);
