import { action } from 'typesafe-actions';

import { IAction } from '../../interfaces/IAction';
import { IRequestHistoryBalance, IBalanceHistoryResponse } from '../../interfaces/IBalance';
import { IProfile } from '../../interfaces/IProfile';

import { ActionTypes } from './types';

export const loginRequest = (data: { username: string; password: string }) => action(ActionTypes.LOGIN_REQUEST, data);
export const authSuccess = (data: IProfile) => action(ActionTypes.AUTH_SUCCESS, data);

export const profileRequest = () => action(ActionTypes.PROFILE_REQUEST);
export const profileError = () => {
    localStorage.removeItem('ACCESS_TOKEN');
    return action(ActionTypes.PROFILE_ERROR);
};

export const userEdit = (data: { balance: number; scedualPaymentBalance: number }) => action(ActionTypes.USER_EDIT, data);
export const profileLogout = () => action(ActionTypes.PROFILE_LOGOUT);

// Balance history
export const balanceHistoryRequest = (data: IAction<IRequestHistoryBalance>) => action(ActionTypes.BALANCE_HISTORY_REQUEST, data);
export const balanceHistorySuccess = (data: IBalanceHistoryResponse) => action(ActionTypes.BALANCE_HISTORY_SUCCESS, data);

// Ticket history
export const ticketHistoryRequest = (data: any) => action(ActionTypes.TICKET_HISTORY_REQUEST, data);
export const ticketHistorySuccess = (data: any) => action(ActionTypes.TICKET_HISTORY_SUCCESS, data);
