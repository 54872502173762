import { AxiosResponse } from 'axios';
import qs from 'qs';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { getAuthToken, removeAuthToken, setAuthToken } from 'src/utils/cookies';
import { checkPermission } from 'src/utils/helpers';

import { api } from '../../api';
import { IAction } from '../../interfaces/IAction';
import { IRequestHistoryBalance, IBalanceHistoryResponse } from '../../interfaces/IBalance';
import { IResponseLogin } from '../../interfaces/IProfile';
import manageHandlers from '../manageHandlers/actions';
import setAuthorizationHeader from '../setAuthorizationHeader';

import { profileError, authSuccess, balanceHistorySuccess, ticketHistorySuccess } from './actions';
import { ActionTypes } from './types';

export interface ILogin {
    getParams: {
        username: string;
        password: string;
    };
}

function* loginRequest(action: IAction<ILogin>) {
    try {
        const response: AxiosResponse<IResponseLogin> = yield api.post(`/auth/login`, action.payload);
        const validate = yield checkPermission(response.data.ACCESS_TOKEN);
        if (validate) {
            setAuthToken(response.data.ACCESS_TOKEN);
            setAuthorizationHeader(response.data.ACCESS_TOKEN);
            yield put(authSuccess(response.data.user));
        } else {
            yield put(manageHandlers.handleError({ message: 'Premission incorect' }));
        }
    } catch (err) {
        // yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* profileRequest() {
    try {
        const token = getAuthToken();
        setAuthorizationHeader(token);
        const response: any = yield api.get(`/auth/profile`);
        if (response?.error) {
            removeAuthToken();
            setAuthorizationHeader(null);
            return;
        }

        yield put(authSuccess(response.data));
    } catch (err) {
        yield put(profileError());
    }
}

// Balance history
function* balanceHistoryRequest(action: IAction<IRequestHistoryBalance>) {
    try {
        if (!action.payload.isRequestFromHeader) {
        }

        const parsedParams: string = qs.stringify({
            ...action.payload.getParams,
            amountPerPage: 20,
        });

        const { data }: AxiosResponse<IBalanceHistoryResponse> = yield api.get(`/balance?${parsedParams}`);

        yield put(balanceHistorySuccess(data));
        if (!action.payload.isRequestFromHeader) {
        }
    } catch (err) {}
}

// Ticket history
function* ticketHistoryRequest(action) {
    try {
        const parsedParams = qs.stringify({
            ...action.payload.getParams,
            amountPerPage: 20,
        });

        const response = yield api.get(`/ticket?${parsedParams}`);
        yield put(ticketHistorySuccess(response.data));
    } catch (err) {}
}

function* watchRequests() {
    yield takeEvery(ActionTypes.LOGIN_REQUEST, loginRequest);
    yield takeEvery(ActionTypes.PROFILE_REQUEST, profileRequest);

    yield takeEvery(ActionTypes.BALANCE_HISTORY_REQUEST, balanceHistoryRequest);

    yield takeEvery(ActionTypes.TICKET_HISTORY_REQUEST, ticketHistoryRequest);
}

function* rootSaga() {
    yield all([fork(watchRequests)]);
}

export default rootSaga;
