import { AxiosResponse } from 'axios';
import qs from 'qs';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import { api } from '../../api';
import { IAction } from '../../interfaces/IAction';
import {
    ITicker,
    IGetTickerRequest,
    ICreateTickerRequest,
    IRule,
    IGetRulesRequest,
    ICreateRulesRequest,
} from '../../interfaces/IRules';
import manageHandlers from '../manageHandlers/actions';

import {
    getRulesSuccess,
    createRulesSuccess,
    getRulesError,
    getTickerError,
    getTickerSuccess,
    createTickerSuccess,
    deleteRulesSuccess,
} from './actions';
import { ActionTypes } from './types';

function* getRulesRequest(action: IAction<IGetRulesRequest>) {
    try {
        const parsedParams = qs.stringify({
            ...action.payload,
        });

        const { data }: AxiosResponse<IRule[]> = yield api.get(`/game-rule?${parsedParams}`);
        yield put(getRulesSuccess(data));
    } catch (err) {
        yield put(getRulesError(err.response.data));
        // yield put(manageHandlers.handleError({ message: err.response.data.message }))
    }
}

function* createRulesRequest(action: IAction<ICreateRulesRequest>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const { data }: AxiosResponse<IRule> = yield api.post(`/game-rule/create`, action.payload);

        yield put(createRulesSuccess(data));
        yield put(manageHandlers.handleSuccess({ message: 'The rule was successful updated' }));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* deleteRulesRequest(action: IAction<any>) {
    try {
        yield put(manageHandlers.handleRequest({}));
        yield api.post(`/game-rule/delete`, action.payload);
        yield put(deleteRulesSuccess({ gameName: action.payload.gameName }));
        yield put(manageHandlers.handleSuccess({ message: 'The rule was successful deleted' }));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* getTickerRequest(action: IAction<IGetTickerRequest>) {
    try {
        const parsedParams = qs.stringify({
            ...action.payload,
        });

        const { data }: AxiosResponse<ITicker> = yield api.get(`/ticker?${parsedParams}`);
        yield put(getTickerSuccess(data));
    } catch (err) {
        yield put(getTickerError(err.response.data));
        // yield put(manageHandlers.handleError({ message: err.response.data.message }))
    }
}

function* createTickerRequest(action: IAction<ICreateTickerRequest>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const { data }: AxiosResponse<ITicker> = yield api.post(`/ticker/create`, action.payload);

        yield put(createTickerSuccess(data));
        yield put(manageHandlers.handleSuccess({ message: 'The ticker was successful updated' }));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* watchRequests() {
    yield takeEvery(ActionTypes.GET_RULES_REQUEST, getRulesRequest);
    yield takeEvery(ActionTypes.CREATE_RULES_REQUEST, createRulesRequest);
    yield takeEvery(ActionTypes.DELETE_RULES_REQUEST, deleteRulesRequest);
    yield takeEvery(ActionTypes.GET_TICKER_REQUEST, getTickerRequest);
    yield takeEvery(ActionTypes.CREATE_TICKER_REQUEST, createTickerRequest);
}

function* rootSaga() {
    yield all([fork(watchRequests)]);
}

export default rootSaga;
