import React from 'react';

import Links from './Links';
// import Locale from './Locale';

const Sidebar: React.FC = () => (
    <div className="sidebar">
        {/* <Locale /> */}
        <Links />
        {/* <MobileMenu
            isOpen={true}
            user={{ name: 'test' }}
            profileLogout={() => {}}
            translate={() => {}}
            switchMobileMenu={() => {}}
        /> */}
    </div>
);

export default React.memo(Sidebar);
