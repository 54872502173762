import classNames from 'classnames';
import React, { FC } from 'react';
import './styles.scss';
import { DocumentStatusEnum } from 'src/common/provider-common-type-util/dictionary/document';

interface RadioButtonProps {
    className?: string;
    value: number | null;
    hideLabel?: boolean;
    label?: string;
    classNameButton?: string;
}

const RadioButton: FC<RadioButtonProps> = ({
    className,
    value = null,
    label = '',
    hideLabel = false,
    classNameButton = '',
}): JSX.Element => {
    const factoryColorwithStatusButton = () => {
        if (value === DocumentStatusEnum.DOCUMENT_STATUS_APPROVED) {
            return 'RadioButton-button-approve';
        }
        if (value === DocumentStatusEnum.DOCUMENT_STATUS_REJECTED) {
            return 'RadioButton-button-rejected';
        }
        if (value === DocumentStatusEnum.DOCUMENT_STATUS_CREATED) {
            return 'RadioButton-button-create';
        }
    };
    const factoryColorwithStatusDot = () => {
        if (value === DocumentStatusEnum.DOCUMENT_STATUS_APPROVED) {
            return 'RadioButton-dot-approve';
        }
        if (value === DocumentStatusEnum.DOCUMENT_STATUS_REJECTED) {
            return 'RadioButton-dot-rejected';
        }
        if (value === DocumentStatusEnum.DOCUMENT_STATUS_CREATED) {
            return 'RadioButton-dot-create';
        }
    };

    return (
        <div className={classNames(factoryColorwithStatusButton(), className, { 'RadioButton-selected': value })}>
            <div className={classNames(factoryColorwithStatusButton(), classNameButton)}>
                <div className={factoryColorwithStatusDot()} />
            </div>
            {!hideLabel && label && <span className="RadioButton-label">{label}</span>}
        </div>
    );
};

export default RadioButton;
