import React from 'react';

import Button from '../../Button';
import FormHOC from '../FormHOC';

import validator from './validator';

interface IUserBanForm {
    dataModal: string;
    handlerStatus: {
        status: string;
        message: string;
    };
    switchModal: () => void;
    onSubmit: () => void;
}

const Form: React.FC<IUserBanForm> = (props) => (
    <form onSubmit={props.onSubmit}>
        <div className="modal-container__btns">
            <Button className="btn gold-btn" type="submit" disabled={props.handlerStatus.status === 'loading'}>
                Confirm
            </Button>
            <Button className="btn grey-btn" type="button" data-modal={props.dataModal} onClick={props.switchModal}>
                Cancel
            </Button>
        </div>
    </form>
);

export default FormHOC(Form, {}, validator);
