import cn from 'classnames';
import React from 'react';
import DatePicker from 'react-datepicker';

export interface IDateOnChangeParams {
    date: number;
    dateUTC: number;
}

interface IDTPicker {
    value: string;
    error: string;
    placeholder?: string;
    dateFormat?: string;
    type: number | string;
    onChange: (dates: IDateOnChangeParams, type: number | string) => void;
}

const DTPicker: React.FC<IDTPicker> = (props) => {
    const [startDate, setStartDate] = React.useState(props.value ? new Date(props.value) : null);
    const inputRef: any = React.useRef(null);

    const closeDatePicker = (e: React.MouseEvent<HTMLElement>) => {
        const { id } = e.target as HTMLButtonElement;
        if (id === 'react-datepicker-popper__wrapper') {
            inputRef.current.setOpen(false);
        }
    };

    const MyContainer = ({ className, children }) => (
        <div id="react-datepicker-popper__wrapper" onClick={closeDatePicker}>
            <div className={className}>{children}</div>
        </div>
    );

    return (
        <div
            className={cn('datepicker__input', {
                datepicker__input__error: props.error,
            })}
        >
            <DatePicker
                selected={startDate}
                onChange={(date) => {
                    const timezone = date.getTimezoneOffset();
                    const diffTime = ((3600 * -timezone) / 60) * 1000;

                    const dateUTC = Math.floor((date.getTime() + diffTime) / 1000);
                    const dateInSeconds = Math.floor(date.getTime() / 1000);

                    setStartDate(date);
                    props.onChange({ date: dateInSeconds, dateUTC }, props.type);
                }}
                showTimeInput
                timeIntervals={15}
                timeCaption="Time"
                dateFormat={props.dateFormat || 'dd.MM.yyyy HH:mm'}
                placeholderText={props.placeholder || 'DD/MM/YYYY HH:MM'}
                ref={inputRef}
                calendarContainer={MyContainer}
            />
        </div>
    );
};

export default DTPicker;
