/* eslint-disable */
import React, { useState } from 'react';

import { stringTrim } from '../../helpers';
import ModalWindow from '../ModalWindow';
import { api } from 'src/api';
import './styles.scss';

import ModalHOC from './ModalHOC';
import Input from 'src/components/Input';
import Button from 'src/components/Button';

const UserPercentModal: React.FC<any> = (props) => {
    const { userData } = props;
    const [data, setData] = useState(userData);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const onSubmit = async () => {
        if (isLoading || error) return;
        setIsLoading(true);
        const options = {
            percent: data.percent,
            id: data.target,
        };

        try {
            const resp = await api.post('/user/update/percent', options);
            if (resp) {
                await props.loadData();
                props.switchModal(props.dataModal);
            }
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;

        const regex = /^(100|[1-9]?\d)$/;

        if (regex.test(value) || value === '') {
            setError(false);
        } else {
            setError(true);
        }

        setData({ ...data, percent: value });
    };

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className={`modal-container__inner`}>
                <div className="modal-container__header">
                    <div>{props.translate('Percent')}</div>
                    <div style={{ color: '#189970' }}>{stringTrim(data.name)}</div>
                </div>
                <div className="modal-container__inputs">
                    <Input
                        className="input gold-input"
                        placeholder={props.translate('Percent')}
                        type="text"
                        name="amount"
                        data-pattern="trimLeadingZero, onlyNumbers"
                        value={data.percent}
                        onChange={handleChangeInput}
                    />
                    {error && (
                        <div className="error-message" style={{ color: 'red' }}>
                            {props.translate('validate_percent')}
                        </div>
                    )}
                </div>
                <div className="modal-container__btns">
                    <Button isLoading={isLoading} onClick={onSubmit} className="btn gold-btn" type="button">
                        {props.translate('Save')}
                    </Button>
                    <Button className="btn grey-btn" type="button" data-modal={props.dataModal} onClick={props.switchModal}>
                        {props.translate('Cancel')}
                    </Button>
                </div>
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(UserPercentModal);
