/* eslint-disable */
import copy from 'copy-to-clipboard';
import React, { useCallback, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { api } from 'src/api';
import copyimg from 'src/assets/images/copy.svg';
import OutlinedDropdown from 'src/components/UI/outlinedDropdown';
import './deposit.scss';

import { Modal } from 'rsuite';
import Button from 'src/components/UI/Button';
import { CurrencyEnum } from 'src/common/provider-common-type-util/dictionary/currency';

const Deposit = ({ open, handleClose }) => {
    const [isLoadingWallet, setIsLoadingWallet] = useState(false);
    const [wallet, setWallet] = useState<string>(null);
    const [currency, setCurrency] = useState({ label: 'USDT-TRC20', value: 'USDT-TRC20' });

    const t = (key: string) => {
        return key;
    };

    const onCopy = useCallback(() => {
        NotificationManager.success(`${t('copied')}`, '', 2000);
        copy(wallet);
    }, [wallet]);

    const getWallet = async () => {
        setIsLoadingWallet(true);
        const resp = await api.get(`/balance/in/address?currency=${currency.value}`);
        if (resp.data) {
            setIsLoadingWallet(false);
            setWallet(resp.data as string);
        } else {
            setIsLoadingWallet(false);
        }
    };

    const handleChangeCurrency = (element: { value: string; label: string }) => {
        setCurrency(element);
    };

    const isDisabledButton = () => {
        return false;
    };

    const optionsCurrency = Object.values(CurrencyEnum).map((currency) => ({
        label: currency,
        value: currency,
    }));

    return (
        <Modal className="modal-deposit" open={open} onClose={handleClose}>
            <div className="Deposit">
                <div className="Deposit-get-wallet">
                    <span className="Deposit-get-wallet-title">Get wallet number</span>
                    <div className="Deposit-get-wallet-content">
                        {wallet === null && (
                            <div className="Deposit-get-wallet-content-form">
                                <OutlinedDropdown
                                    className="Deposit-get-wallet-content-form-dropdown"
                                    placeholder={t('currency')}
                                    options={optionsCurrency}
                                    onSelect={handleChangeCurrency}
                                    defaultSelected={currency}
                                />
                                <Button
                                    className="Deposit-get-wallet-content-form-button"
                                    title={'Get'}
                                    primary
                                    disabled={isDisabledButton()}
                                    onClick={getWallet}
                                    loading={isLoadingWallet}
                                />
                            </div>
                        )}
                        {wallet && (
                            <div className="Deposit-get-wallet-content-wallet" onClick={onCopy}>
                                <code>{wallet}</code>
                                <img src={copyimg} alt="copy" className="Deposit-copy-icon" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Deposit;
