export default {
    _styles: [{ width: '10%' }, { width: '10%' }, { width: '10%' }, { width: '30%' }, { width: '20%' }, { width: '20%' }],
    _template: 'chance',
    head: [
        { title: 'Id' },
        { title: 'currentTickets' },
        { title: 'result' },
        { title: 'status' },
        { title: 'error' },
        { title: 'startDate' },
        { title: 'createDate' },
    ],
    body: [],
};
