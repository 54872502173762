import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { api } from 'src/api';
import { BalanceOutStatusEnum } from 'src/common/provider-common-type-util/dictionary/balance';

import ModalWindow from '../ModalWindow';
import Button from '../UI/Button';
import Input from '../UI/Input';

import ModalHOC from './ModalHOC';
import './styles.scss';

const BalancesConfirm: React.FC<any> = (props) => {
    const { userData } = props;
    const defaultStateReason = userData.reason !== 'null' ? userData.reason : '';
    const [reason, setReason] = useState(defaultStateReason);

    const handleChangeStatusDeposit = async (status: number) => {
        const options = {
            reason,
            status,
            id: userData.target,
        };

        const resp = await api.post('/balance/out/update', options);

        if (resp.data) {
            props.switchModal(props.dataModal);
            NotificationManager.success(props.translate('Success'), '', 3500);
        }
    };

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner">
                <div className="modal-container__header">
                    <div>{props.translate('change_balance')}</div>
                    <div style={{ color: '#e6bc73' }}>{userData.name}</div>
                    <div style={{ color: '#e6bc73', fontSize: '16px', marginTop: '10px' }}>
                        {props.translate('Status')}: {BalanceOutStatusEnum[userData.status]}
                    </div>
                </div>
                <div className="Balances">
                    <Input
                        hideLabel
                        placeholder={props.translate('reason')}
                        value={reason}
                        onChange={(e: any) => setReason(e.target.value)}
                    />
                </div>

                <div className="modal-container__btns" style={{ display: 'flex', gap: '10px' }}>
                    {userData.status === BalanceOutStatusEnum.BALANCE_OUT_STATUS_CREATED && (
                        <>
                            <Button
                                className="Balances-button"
                                title={props.translate('approve')}
                                primary
                                onClick={() => handleChangeStatusDeposit(BalanceOutStatusEnum.BALANCE_OUT_STATUS_APPROVED)}
                            />

                            <Button
                                className="Balances-button"
                                title={props.translate('reject')}
                                onClick={() => handleChangeStatusDeposit(BalanceOutStatusEnum.BALANCE_OUT_STATUS_REJECTED)}
                            />
                        </>
                    )}
                </div>
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(BalancesConfirm);
