import React from 'react';
import { UserStatusEnum } from 'src/common/provider-common-type-util/dictionary/user';

import { stringTrim } from '../../helpers';
import UserBanForm from '../Forms/UserBanForm/Form';
import ModalWindow from '../ModalWindow';

import ModalHOC from './ModalHOC';

const UserBanModal: React.FC<any> = (props) => {
    const { userData } = props;

    const onSubmit = () => {
        props.userBanRequest({
            id: userData.userId,
            userStatus: userData.userStatus,
        });
    };

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner">
                <div className="modal-container__header">
                    <div>{+userData.userStatus === UserStatusEnum.USER_STATUS_BANNED ? 'User Ban' : 'User Unban'}?</div>
                    <div style={{ color: '#e6bc73' }}>{stringTrim(userData.name)}</div>
                </div>

                <UserBanForm
                    onSubmit={onSubmit}
                    handlerStatus={props.handlerStatus}
                    dataModal={props.dataModal}
                    switchModal={props.switchModal}
                />
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(UserBanModal);
