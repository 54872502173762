export default {
    _styles: [
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
        { width: '20%' },
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
    ],
    _template: 'balances',
    head: [
        { title: 'id' },
        { title: 'Username' },
        { title: 'amount' },
        { title: 'target_current_balance' },
        { title: 'Status' },
        { title: 'date' },
        { title: 'Action' },
    ],
    body: [],
};
