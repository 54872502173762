/* eslint-disable no-unused-vars */
export class ScedualPaymentDictionary {
  // Scedual Payment Status
  static SCEDUAL_PAYMENT_STATUS_CREATED: number = 1;
  static SCEDUAL_PAYMENT_STATUS_PAID: number = 2;
  static SCEDUAL_PAYMENT_STATUS_DELETED: number = 3;
}

export enum ScedualPaymentStatusEnum {
  SCEDUAL_PAYMENT_STATUS_CREATED = ScedualPaymentDictionary.SCEDUAL_PAYMENT_STATUS_CREATED,
  SCEDUAL_PAYMENT_STATUS_PAID = ScedualPaymentDictionary.SCEDUAL_PAYMENT_STATUS_PAID,
  SCEDUAL_PAYMENT_STATUS_DELETED = ScedualPaymentDictionary.SCEDUAL_PAYMENT_STATUS_DELETED
}
