import { isValidPhoneNumber } from 'libphonenumber-js';
import { EnumValidate } from '../../../common/enum';
export const validationNameOrSecondName = (value) => {
    if (value.length === 0) return null;
    else if (value.length < EnumValidate.VALIDATE_NAME_LENGTH_MIN || value.length > EnumValidate.VALIDATE_NAME_LENGTH_MAX)
        return 'validate_longer_4';
    return null;
};

export const validationPassword = (value) => {
    if (value.length === 0) return null;
    else if (value.length < EnumValidate.VALIDATE_PASSWORD_LENGTH_MIN || value.length > EnumValidate.VALIDATE_PASSWORD_LENGTH_MAX)
        return 'validate_password';
    return null;
};

export const validationEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value.length === 0) return null;
    if (!emailRegex.test(value)) return 'validate_email';
    return null;
};

export const validationLogin = (value) => {
    if (value.length === 0) return null;
    else if (value.length < EnumValidate.VALIDATE_LOGIN_LENGTH_MIN || value.length > EnumValidate.VALIDATE_LOGIN_LENGTH_MAX)
        return 'validate_longer_4';
    return null;
};

export const validatePhoneNumber = (value, country) => {
    if (value.length === 0) return null;

    try {
        if (isValidPhoneNumber(value, country)) {
            return null;
        } else {
            return 'validate_phone';
        }
    } catch (error) {
        return 'validate_phone';
    }
};
