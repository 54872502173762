import { action } from 'typesafe-actions';

import { IAction } from '../../interfaces/IAction';
import {
    IGetTickerRequest,
    ICreateTickerRequest,
    ITicker,
    IGetRulesRequest,
    IRule,
    ICreateRulesRequest,
} from '../../interfaces/IRules';

import { ActionTypes } from './types';

export const getRulesRequest = (data: IAction<IGetRulesRequest>) => action(ActionTypes.GET_RULES_REQUEST, data);
export const getRulesSuccess = (data: IRule[]) => action(ActionTypes.GET_RULES_SUCCESS, data);
export const getRulesError = (data: any) => action(ActionTypes.GET_RULES_ERROR, data);

export const createRulesRequest = (data: IAction<ICreateRulesRequest>) => action(ActionTypes.CREATE_RULES_REQUEST, data);
export const createRulesSuccess = (data: IRule) => action(ActionTypes.CREATE_RULES_SUCCESS, data);

export const deleteRulesRequest = (data: IAction<any>) => action(ActionTypes.DELETE_RULES_REQUEST, data);
export const deleteRulesSuccess = (data: { gameName: string }) => action(ActionTypes.DELETE_RULES_SUCCESS, data);

export const getTickerRequest = (data: IAction<IGetTickerRequest>) => action(ActionTypes.GET_TICKER_REQUEST, data);
export const getTickerSuccess = (data: ITicker) => action(ActionTypes.GET_TICKER_SUCCESS, data);
export const getTickerError = (data: any) => action(ActionTypes.GET_TICKER_ERROR, data);

export const createTickerRequest = (data: IAction<ICreateTickerRequest>) => action(ActionTypes.CREATE_TICKER_REQUEST, data);
export const createTickerSuccess = (data: ITicker) => action(ActionTypes.CREATE_TICKER_SUCCESS, data);
