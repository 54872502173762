/* eslint-disable */
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { api } from '../../api';
import { ICreateRulesRequest, IRule } from '../../interfaces/IRules';
import Button from '../Button';

interface Languages {
    en: string;
    ru: string;
    he: string;
}

const defaultRules: Languages = {
    en: '<p>There is no description</p>',
    ru: '<p>Нету описания</p>',
    he: '<p>אין תיאור</p>',
};

interface TextEditorProps {
    currentGameType: number;
    currentGameName: string;
    rules: IRule[];
    createRulesRequest(data: any): void;
    translate(text: string): string;
}

interface TextEditorState {
    editorState: any;
    isLoading: boolean;
}

class TextEditor extends Component<TextEditorProps, TextEditorState> {
    constructor(props) {
        super(props);

        const editorState = this.getEditorState();

        this.state = {
            editorState,
            isLoading: false,
        };
    }

    componentDidUpdate(previousProps) {
        if (previousProps.currentGameType !== this.props.currentGameType) {
            const editorState = this.getEditorState();

            this.setState({
                editorState,
            });
        }
    }

    getEditorState = () => {
        const currentRule: IRule | undefined = this.props.rules.find(
            (rule) => rule.gameType === this.props.currentGameType && rule.gameName === this.props.currentGameName,
        );
        const text: string =
            currentRule && JSON.parse(currentRule.text)[localStorage.locale]
                ? JSON.parse(currentRule.text)[localStorage.locale]
                : `<body>${defaultRules[localStorage.locale]}</body>`;

        const contentBlock = htmlToDraft(text);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

        return EditorState.createWithContent(contentState);
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    onSubmit = (values) => {
        this.setState({ isLoading: true });
        values.preventDefault();
        const currentRule: IRule | undefined = this.props.rules.find((rule) => rule.gameType === this.props.currentGameType);
        const text: string = currentRule ? JSON.parse(currentRule.text)[localStorage.locale] : defaultRules[localStorage.locale];
        const content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())).slice(0, -1);

        if (content === defaultRules[localStorage.locale] || (currentRule && content === text)) {
            return;
        }

        let newText: Languages;

        const data: ICreateRulesRequest = {
            gameType: this.props.currentGameType,
            gameName: this.props.currentGameName,
            text: currentRule
                ? ((newText = JSON.parse(currentRule.text)), (newText[localStorage.locale] = content), JSON.stringify(newText))
                : JSON.stringify({ en: content }),
        };

        this.props.createRulesRequest(data);
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 500);
    };

    uploadCallback(file) {
        return new Promise((resolve) => {
            const url = process.env.REACT_APP_API_URL;
            const formData = new FormData();
            formData.append('image', file);
            api.post(`/game-rule/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((resp: any) => {
                const link: string = url + resp.data.image;
                resolve({ data: { link, name: 'kek.png' } });
            });
        });
    }

    render() {
        const { editorState } = this.state;
        return (
            <>
                <form className="rules-form" onSubmit={this.onSubmit}>
                    <Editor
                        toolbar={{
                            image: {
                                uploadCallback: this.uploadCallback,
                                previewImage: true,
                                alt: { present: true, mandatory: false },
                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            },
                        }}
                        editorState={editorState}
                        wrapperClassName="editor-wrapper"
                        editorClassName="editor-content"
                        onEditorStateChange={this.onEditorStateChange}
                    />
                    <Button isLoading={this.state.isLoading} className="btn gold-btn" type="submit">
                        {this.props.translate('Update rule')}
                    </Button>
                </form>
            </>
        );
    }
}

export default TextEditor;
