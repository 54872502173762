import './styles.scss';
import { countryCode } from 'src/common/countries';

import { api } from 'src/api';
import React, { useEffect, useMemo, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { getCountryCallingCode } from 'libphonenumber-js';
import { getDays, getYears, getMount, monthsDays } from '../../../utils/utils';
import {
    validatePhoneNumber,
    validationEmail,
    validationLogin,
    validationNameOrSecondName,
    validationPassword,
} from './validation';
import * as i18nIsoCountries from 'i18n-iso-countries';
import Input from '../../UI/Input';
import OutlinedDropdown from '../../UI/outlinedDropdown';
import Button from '../../UI/Button';
import RadioButton from '../../UI/RadioButton';
import CheckBox from 'src/components/Checkbox';
import { UserGenderEnum } from '../../../common/provider-common-type-util/dictionary/user';

function FormAddNewUser(props) {
    const lang = localStorage.getItem('locale');
    const languages = lang ? lang : 'en';
    i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/${languages}.json`));
    const countryCodeDefault = 'IL';
    const translatedName = i18nIsoCountries.getName(countryCodeDefault, languages);
    const countryDefault = {
        label: translatedName,
        value: countryCodeDefault,
    };
    const [isLoading, setIsLoading] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [registerOption, setRegisterOption] = useState({
        name: '',
        secondName: '',
        login: '',
        email: '',
        phone: '',
        password: '',
        passwordSign: '',
        day: null,
        month: null,
        year: null,
        codeCountry: null,
        gender: UserGenderEnum.USER_GENDER_MALE,
        country: countryDefault,
        city: '',
        isSchedule: false,
        parentId: '',
    });

    const loadData = async () => {
        const countryPhoneCodes = countryCode.map((item) => {
            const code = `+${getCountryCallingCode(item)}`;
            return {
                label: code,
                value: code,
                country: item,
            };
        });

        if (registerOption.country.value === countryCodeDefault) {
            const filter = countryPhoneCodes.filter((item) => item.country === countryCodeDefault);
            setCountryCodes(filter);
            return;
        }

        setCountryCodes(countryPhoneCodes);
    };

    useEffect(() => {
        loadData();
    }, []);

    const days = useMemo(() => {
        return getDays;
    }, []);

    const mounths = useMemo(() => {
        return getMount;
    }, []);

    const years = useMemo(() => {
        return getYears;
    }, []);

    const handleChangeInput = (e) => {
        let value = e.target.value;
        const key = e.target.id;
        if (key === 'login') {
            value = value.toLowerCase();
        }
        setRegisterOption({ ...registerOption, [key]: value });
    };
    const handleDayChange = (selectedDay) => {
        setRegisterOption({ ...registerOption, day: selectedDay });
    };

    const handleMonthChange = (selectedMonth) => {
        if (registerOption.day !== null) {
            if (registerOption.day.value > monthsDays[selectedMonth.value]) {
                setRegisterOption({
                    ...registerOption,
                    day: { label: monthsDays[selectedMonth.value], value: monthsDays[selectedMonth.value] },
                    month: selectedMonth,
                });
                return;
            }
        }
        setRegisterOption({ ...registerOption, month: selectedMonth });
    };

    const handleYearChange = (selectedYear) => {
        setRegisterOption({ ...registerOption, year: selectedYear });
    };

    const handleYearCode = (selectedCode) => {
        setRegisterOption({ ...registerOption, codeCountry: selectedCode });
    };

    const handleChangeCountry = (selectedCountry) => {
        const phoneCode = `+${getCountryCallingCode(selectedCountry.value)}`;
        setRegisterOption({
            ...registerOption,
            country: selectedCountry,
            codeCountry: { label: phoneCode, value: phoneCode, country: selectedCountry.value },
        });
        // setCity(selectedCountry);
    };

    const handleChangeCity = (selectedCity) => {
        setRegisterOption({ ...registerOption, city: selectedCity.value });
    };

    const getCountryNamesByCodes = () => {
        const languages = lang ? lang : 'en';
        i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/${languages}.json`));
        const countries = countryCode.map((code) => {
            const translatedName = i18nIsoCountries.getName(code, languages);
            return {
                label: translatedName || 'Unknown',
                value: code,
            };
        });
        return countries;
    };

    const handleClickButtonRegister = async () => {
        setIsLoading(true);
        const { day, month, year, codeCountry, phone, country, parentId, ...remainingOptions } = registerOption;
        const newDate = day && month && year ? new Date(year.value, month.value, day.value) : null;
        const formattedDate = newDate ? newDate.toISOString().split('T')[0] : '';
        const setPhone = codeCountry ? codeCountry?.value + phone : '';

        const options = {
            ...remainingOptions,
            birth: formattedDate,
            phone: setPhone !== '' ? setPhone : '',
            country: country?.value,
            permissionLevel: props.permissionLevel,
            parent: parentId,
        };
        for (const key in options) {
            if (options[key] === '') {
                delete options[key];
            }
        }
        const resp = await api.post('/user/create', options);
        if (resp.data) {
            setIsLoading(false);
            NotificationManager.success(`${props.translation('register_success')}`, '', 3500);
            props.switchModal(props.dataModal);
        } else {
            setIsLoading(false);
        }
    };

    const handleGenderChange = (value) => {
        setRegisterOption({ ...registerOption, gender: value });
    };

    const getOptionsDays = () => {
        if (registerOption.month !== null) {
            return days.slice(0, monthsDays[registerOption.month.value]);
        }

        return days;
    };

    return (
        <div className="Account">
            <div className="UserInfo">
                <div className="UserInfo-content-step-1">
                    <div className="UserInfo-content-step-1-inputs">
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translation('user_name_input')}
                            placeholder={props.translation('enter_name')}
                            value={registerOption.name}
                            id="name"
                            onChange={handleChangeInput}
                            translate={props.translation}
                            error={validationNameOrSecondName(registerOption.name)}
                        />
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translation('user_surname_input')}
                            placeholder={props.translation('enter_surname')}
                            value={registerOption.secondName}
                            id="secondName"
                            onChange={handleChangeInput}
                            translate={props.translation}
                            error={validationNameOrSecondName(registerOption.secondName)}
                        />
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translation('login_input')}
                            placeholder={props.translation('login_input')}
                            value={registerOption.login}
                            id="login"
                            onChange={handleChangeInput}
                            translate={props.translation}
                            error={validationLogin(registerOption.login)}
                        />
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translation('user_password_input')}
                            placeholder={props.translation('user_password_input')}
                            value={registerOption.password}
                            id="password"
                            onChange={handleChangeInput}
                            translate={props.translation}
                            error={validationPassword(registerOption.password)}
                        />
                    </div>
                    <div className="UserInfo-content-step-1-inputs">
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translation('e_mail')}
                            placeholder={props.translation('enter_e_mail')}
                            value={registerOption.email}
                            id="email"
                            onChange={handleChangeInput}
                            translate={props.translation}
                            error={validationEmail(registerOption.email)}
                        />
                        <div className="UserInfo-content-step-1-country">
                            <span className="UserInfo-content-step-1-country-title">{props.translation('country')}</span>
                            <OutlinedDropdown
                                className="UserInfo-content-step-1-country-dropdown"
                                placeholder={props.translation('select_country')}
                                options={getCountryNamesByCodes()}
                                onSelect={handleChangeCountry}
                                disabled
                                defaultSelected={registerOption.country}
                            />
                        </div>
                    </div>
                    <div className="UserInfo-content-step-1-inputs">
                        {/* <div className="UserInfo-content-step-1-country">
                            <span className="UserInfo-content-step-1-country-title">{props.translation('city')}</span>
                            <OutlinedDropdown
                                className="UserInfo-content-step-1-country-dropdown"
                                placeholder={props.translation('select_city')}
                                options={cityOptions}
                                onSelect={handleChangeCity}
                            />
                        </div> */}
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translation('select_city')}
                            placeholder={props.translation('select_city')}
                            value={registerOption.city}
                            id="city"
                            onChange={handleChangeInput}
                        />
                        <div className="UserInfo-content-step-1-phone">
                            <OutlinedDropdown
                                className="UserInfo-content-step-1-code-dropdown"
                                placeholder={'+355'}
                                options={countryCodes}
                                onSelect={handleYearCode}
                                defaultSelected={registerOption.codeCountry}
                            />
                            <Input
                                hideLabel
                                className="UserInfo-content-step-1-phone-input"
                                placeholder={'97404013'}
                                value={registerOption.phone}
                                id="phone"
                                translate={props.translation}
                                onChange={handleChangeInput}
                                error={validatePhoneNumber(
                                    (registerOption.codeCountry !== null ? registerOption.codeCountry.value : '') +
                                        registerOption.phone,
                                    registerOption.codeCountry?.country,
                                )}
                            />
                        </div>
                    </div>
                    <div className="UserInfo-content-step-1-inputs">
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translation('parentId')}
                            placeholder={props.translation('parentId')}
                            value={registerOption.parentId}
                            id="parentId"
                            onChange={handleChangeInput}
                        />
                        <CheckBox
                            label={props.translation('isSchedule')}
                            classNameBox="checkBox"
                            checked={registerOption.isSchedule}
                            onChange={() => setRegisterOption({ ...registerOption, isSchedule: !registerOption.isSchedule })}
                        />
                    </div>
                    <div className="UserInfo-content-step-1-personal-info">
                        <div className="UserInfo-content-step-1-birhtday">
                            <span className="UserInfo-content-step-1-birhtday-title">{props.translation('birthaday_date')}</span>
                            <div className="UserInfo-content-step-1-birhtday-dropdowns">
                                <OutlinedDropdown
                                    className="UserInfo-content-step-1-birhtday-dropdown"
                                    placeholder={props.translation('day')}
                                    options={getOptionsDays()}
                                    onSelect={handleDayChange}
                                    defaultSelected={registerOption.day}
                                />
                                <OutlinedDropdown
                                    className="UserInfo-content-step-1-birhtday-dropdown"
                                    placeholder={props.translation('month')}
                                    options={mounths}
                                    onSelect={handleMonthChange}
                                    defaultSelected={registerOption.month}
                                />
                                <OutlinedDropdown
                                    className="UserInfo-content-step-1-birhtday-dropdown"
                                    placeholder={props.translation('year')}
                                    options={years}
                                    onSelect={handleYearChange}
                                    defaultSelected={registerOption.year}
                                />
                            </div>
                        </div>
                        <div className="UserInfo-content-step-1-gender-info">
                            <div className="UserInfo-content-step-1-gender">
                                <span className="UserInfo-content-step-1-gender-title">{props.translation('gender')}</span>
                                <div className="UserInfo-content-step-1-gender-radio-button">
                                    <RadioButton
                                        label={props.translation('male')}
                                        onChange={() => handleGenderChange(UserGenderEnum.USER_GENDER_MALE)}
                                        value={registerOption.gender === UserGenderEnum.USER_GENDER_MALE}
                                    />
                                    <RadioButton
                                        label={props.translation('female')}
                                        onChange={() => handleGenderChange(UserGenderEnum.USER_GENDER_FEMALE)}
                                        value={registerOption.gender === UserGenderEnum.USER_GENDER_FEMALE}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="UserInfo-content-step-1-buttons">
                        <Button
                            className="UserInfo-content-step-2-prev"
                            title={props.translation('Create')}
                            primary
                            onClick={handleClickButtonRegister}
                            loading={isLoading}
                        />
                        <Button
                            className="UserInfo-content-step-2-prev"
                            title={props.translation('Cancel')}
                            onClick={() => props.switchModal(props.dataModal)}
                            disabled={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormAddNewUser;
