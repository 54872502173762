import { action } from 'typesafe-actions';

import { IAction } from '../../interfaces/IAction';
import { IEvoGame, IEvoCurrentGame, IEvoOpenGameRequest } from '../../interfaces/IEvoGames';

import { ActionTypes } from './types';

export const gamesRequest = () => action(ActionTypes.GAMES_REQUEST);
export const gamesSuccess = (data: IEvoGame[]) => action(ActionTypes.GAMES_SUCCESS, data);

export const openGameRequest = (data: IAction<IEvoOpenGameRequest>) => action(ActionTypes.OPEN_GAME_REQUEST, data);
export const openGameSuccess = (data: IEvoCurrentGame) => action(ActionTypes.OPEN_GAME_SUCCESS, data);
export const openGameFail = () => action(ActionTypes.OPEN_GAME_FAIL);

export const closeGameRequest = () => action(ActionTypes.CLOSE_GAME_REQUEST);
export const closeGameSuccess = () => action(ActionTypes.CLOSE_GAME_SUCCESS);
