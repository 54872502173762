export default {
    locale: 'ru',
    messages: {
        // Templates
        'Cashback Percent': 'Процент кэшбэка',
        'Login': 'Логин',
        'Time/Date': 'Время/Дата',
        'Status': 'Статус',
        'Amount': 'Количество',
        'Password': 'Пароль',
        'Masters': 'Мастера',
        'Agents': 'Агенты',
        'Players': 'Игроки',
        'Balance': 'Баланс',
        'Cashback': 'Кэшбэк',
        'Rotatable Payment': 'Ротационная Оплата',
        'Date': 'Дата',
        'Total Balance': 'Итоговый Баланс',
        'Profit': 'Прибыль',
        'Agent Commission': 'Коммисия Агента',
        'Master Commission': 'Коммисия Мастера',
        'System Commission': 'Коммисия Системы',
        'createDate': 'Дата создания',
        'startDate': 'Дата начала',
        'currentTickets': 'Текущие билеты',
        'cashbackPercent': 'Процент кэшбэка',
        'result': 'Результат',
        'targetCurrentBalance': 'Текущий баланс цели',
        'Type': 'Тип',
        'Id': 'Id',
        'Creator': 'Инициатор',
        'Target': 'Цель',
        'CashbackPlayer': 'Кэшбэк пользователь',
        'creatorCurrentBalance': 'Текущий баланс инициатора',
        'UserId': 'Id пользователя',
        'Bets Count': 'Кол-во ставок',
        'Canceled bets': 'Отмененные ставки',
        'Debit amount': 'Кол-во списаных средств',
        'Win amount': 'Кол-во выйгранных средств',
        'Profit amount': 'Кол-во прибыли',
        'Comment': 'Комментарий',
        'ProfitPercent': 'Процент прибыли',

        // Links
        'Statistics': 'Статистика',
        'Transactions': 'Транзакции',
        'Manage Users': 'Упр-ние Пользователями',
        'Logout': 'Выход',

        // Components
        'Add': 'Добавить',
        'Take': 'Взять',
        'Cancel': 'Отменить',
        'Add New': 'Добавить Нового Пользователя',
        'Checkpoint': 'Чекпоинт',
        'One On One': 'Один На Один',
        'Price': 'Цена',
        'Month Prize': 'Приз Месяца',
        'Jackpot': 'Джекпот',
        'Master': 'Мастер',
        'Agent': 'Агент',
        'User': 'Игрок',
        'Confirm': 'Подтвердить',
        'User Ban': 'Бан Пользователя',
        'User Unban': 'Убрать Бан Пользователя',
        'No content': 'Нет данных',
        'Profit Percent': 'Процент прибыли',
        'Discount': 'Cкидка',
        'Change': 'Изменить',
        'New password': 'Новый пароль',
        'Confirm password': 'Подтвердите пароль',
        'Reset': 'Сбросить',
        'Change password': 'Изменить пароль',
        'Change Comment': 'Изменить комментарий',
        'New Comment': 'Новый комментарий',
        'Accept': 'Принять',
        'Update': 'Обновить',
        'Create': 'Создать',
        'Delete': 'Удалить',
        'Update rule': 'Обновить правило',
        'Apply': 'Применить',
        'Set': 'Установить',
        'Chance': 'Шанс',
        'more': 'Больше',
        'less': 'Меньше',
        'odd': 'Непарное',
        'even': 'Парное',
        'Special': 'Точная',
        'Parity': 'Парность',
        'MoreLess': 'Больше/меньше',
        'Make bet': 'Поставить ставку',
        'isSchedule': 'График',

        // Pages
        'Best at Table': 'Лучший на Столе',
        'Group Bet': 'Групповая Ставка',
        'Daily': 'Групповая Ставка',
        'Game': 'Игра',
        'Users': 'Пользователи',
        'Add Master': 'Добавить Мастера',
        'Add Agent': 'Добавить Агента',
        'Add User': 'Добавить Игрока',
        'Name': 'Имя',
        'Edit': 'Редактировать',
        'Rules': 'Правила',
        'Ticker': 'Бегущая строка',
        'RuleList': 'Список правил',

        // Other
        'Username': 'Имя пользователя',
        'country': 'Страна',
        'team': 'Команда',
        'parentId': 'Родительское ID',
        'This user is already getting scedual payment': 'Этот пользователь уже получает регулярный платёж',
        'Payment date': 'Дата платежа',
        'Set scedual payment': 'Установить регулярный платёж',
        'Weekly': 'Еженедельный',
        'Monthly': 'Ежемесячный',
        'Wait': 'Подождите',
        'Set result': 'Установить результат',
        'documents': 'Документы',
        'approve': 'Одобрить',
        'reject': 'Отклонить',
        'on_review': 'На рассмотрении',
        'approved': 'Верефицирован',
        'rejected': 'Отклонено',
        'secondName': 'Фамилия',
        'birth': 'День рождения',
        'сountry': 'Страна',
        'city': 'Город',
        'email': 'E-mail',
        'gender': 'Пол',
        'phone': 'Номер телфона',

        'Possible gain': 'Возможный выигрыш',

        // Create User
        'male': 'Мужской',
        'female': 'Женский',
        'user_name_input': 'Имя пользвоателя',
        'enter_surname': 'Введите вашу фамилию',
        'user_surname_input': 'Фамилия пользвоателя',
        'e_mail': 'E-mail',
        'enter_e_mail': 'Введите ваш у-mail',
        'login_input': 'Login',
        'birthaday_date': 'Дата рождения',
        'day': 'День',
        'year': 'Год',
        'month': 'Месяц',
        'user_password_input': 'Пароль',
        'enter_password': 'Введите пароль',
        'enter_name': 'Имя пользвоателя',
        'select_country': 'Выберите страну',
        'select_city': 'Город',
        'validate_longer_4': 'Поле должно быть длиннее 4 символов',
        'validate_longer_2': 'Поле должно быть длиннее 2 символов',
        'validate_email': 'Введите корректный E-mail',
        'validate_phone': 'Введите корректный номер телефона',
        'validate_password': 'Пароль должен состоять из 4-32 символов',
        'register_success': 'Пользователь создан',

        // Create Balance

        'Balance_type': 'Тип баланса',
        'System_provider': 'Системный провайдер',
        'Game_provider': 'Провайдер игры',
        'Provider_game_type': 'Тип игры провайдера',
        'Provider_game_id': 'Идентификатор игры провайдера',
        'Provider_game_name': 'Название игры провайдера',
        'Provider_transaction_id': 'Идентификатор транзакции провайдера',
        'Success': 'Успех',
        'reason': 'Причина',
        'change_balance': 'Изменение баланса',
        'amount': 'Сумма',
        'date': 'Дата',
        'target_current_balance': 'Целевой текущий баланс',
        'create': 'Создание',
        'finish': 'Завершено',
        'status': 'Статус',
        'Action': 'Действие',
        'Balances': 'Балансы вывод',
        'game_provider': 'Провайдер игры',
        'game_type': 'Тип игры',
        'game_name': 'Название игры',
        'system_provider': 'Cистемный провайдер',
        'provider_type': 'Тип провайдера',
        'provider_game_id': 'ID провайдера игры',
        'provider_transaction_id': 'ID провайдера транзакции',

        // Settings
        'Settings': 'Набор настроек',
        'name_short': 'Краткое имя',
        'create_setting_bundle': 'Создание пакета настроек',
        'edit_setting_bundle': 'Изменение пакета настроек',
        'olwin_chance': 'OLWIN шанс',
        'System Provider': 'Системный провайдер',
        'confirm_settings': 'Вы уверены, что хотите выйти без сохранения изменений?',
        'Provider Name': 'Провайдер',
        'Max Bet': 'Макс. ставка',
        'Max Win': 'Макс. выигрыш',
        'Disabled': 'Отключено',
        'Back': 'Назад',
        'Save': 'Сохранить',

        // New Translate
        'Betting': 'Cтавки',
        'betCount': 'Подсчет ставок',
        'debitAmount': 'Сумма дебета',
        'profitAmount': 'Сумма прибыли',
        'totalCashback': 'Общий кэшбэк',
        'winAmount': 'Сумма выигрыша',
        'info-user': 'Информация пользователя',
        'role': 'Роль',
        'Add Player': 'Добавить пользователя',
        'last-7-days': 'Последние 7 дней',
        'last-month': 'Последний месяц',
        'last-6-months': 'Последние 6 месяцев',
        'login-account': 'Вход в аккаунт',
        'sign-in': 'Войти',
        'user-agent': 'Агент пользователя',
        'ip-address': 'IP Адрес',
        'transaction-by-user': 'по User',
        'transaction-by-tree': 'по Tree',
        'canceledBet': 'Отменённая ставка',
        'agentProfitAmount': 'Сумма прибыли агента',
        'masterProfitAmount': 'Сумма прибыли мастера',
        'Report': 'Отчёт',
        'Provider': 'Провайдер',
        'LoginPriority': '"Логин" приоритетнее "Цели"',

        'Casino': 'Казино',
        'Racing': 'Скачки',
        'Played': 'Сыгранно',
        'Refunded': 'Возврат',
        'Won': 'Выигрыш',
        'UserName': 'Имя',
        'Waiting': 'Ожидание',
        'only-player': 'Может быть только Player',
        'not-player': 'Не может быть Player',

        'total-profit-casino': 'Прибыль казино',
        'total-profit-racing': 'Прибыль скачек',
        'total-profit-game': 'Прибыль системных игр',
        'total-profit-sport': 'Прибыль спорта',
        'total-profit': 'Общая прибыль',
        'total-deposit': 'Общий депозит',
        'total-withdrawal': 'Общий вывод',
        'logged-players': 'Вошедшие игроки',
        'players-balance': 'Баланс игроков',
        'show-details': 'Показать детали',
        'oldest-date': 'Самая старая дата',
        'Total Statistic': 'Общая статистика',

        // new translate part 2

        'system-game': 'Системные игры',
        'total': 'Всего',
        'info_transaction': 'Информация транзакшина',

        'remember': 'Запомнить меня',
        'choose-language': 'Изменить язык',
        'the_current': 'Текущая страница могла быть удалена, изменена или временно недоступна.',
        'return_home': 'Вернуться на главную страницу',

        'Percent': 'Процент',
        'validate_percent': 'Пожалуйста, введите корректный процент от 0 до 100.',
        'BALANCE_TYPE_TRANSFER': 'Перевод',
        'BALANCE_TYPE_TAKE': 'Забрать',
        'BALANCE_TYPE_DEPOSIT': 'Депозит',
        'BALANCE_TYPE_OUT': 'Вывод',
        'BALANCE_TYPE_REDUCE': 'Уменьшить',
        'BALANCE_TYPE_ADD': 'Добавить',
        'BALANCE_TYPE_REFUND': 'Возврат',
        'BALANCE_TYPE_SCEDUAL_REDUCE': 'Плановое уменьшение',
        'BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE': 'Плановое уменьшение баланса',
        'BALANCE_TYPE_INFO': 'Информация',
        'BALANCE_TYPE_RACING_DEPOSIT': 'Депозит на гонки',
        'BALANCE_TYPE_RACING_WITHDRAW': 'Вывод с гонок',
        'BALANCE_TYPE_CASINO_DEBIT': 'Казино дебет',
        'BALANCE_TYPE_CASINO_CREDIT': 'Казино кредит',
        'BALANCE_TYPE_CASINO_ROLLBACK': 'Казино откат',
        'BALANCE_TYPE_SPORT_DEBIT': 'Спорт дебет',
        'BALANCE_TYPE_SPORT_CREDIT': 'Спорт кредит',
        'BALANCE_TYPE_SPORT_ROLLBACK': 'Спорт откат',
    },
};
