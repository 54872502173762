import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { api } from 'src/api';
import { SystemProviderEnum } from 'src/common/provider-common-type-util/dictionary/system-provider';

import ModalWindow from '../ModalWindow';
import Button from '../UI/Button';
import Input from '../UI/Input';
import OutlinedDropdown from '../UI/outlinedDropdown';

import ModalHOC from './ModalHOC';

import './styles.scss';

const CreateBundle: React.FC<any> = (props) => {
    const [name, setName] = useState('');
    const [nameShort, setNameShort] = useState('');
    const [target, setTarget] = useState('');
    const [provider, setProvider] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getOptionProvider = () => {
        const enumKeys = Object.keys(SystemProviderEnum)
            .filter((key) => Number.isNaN(Number(SystemProviderEnum[key])))
            .map((key) => ({
                label: SystemProviderEnum[key],
                value: key,
            }));
        return [{ label: props.translate('not_selected'), value: null }, ...enumKeys];
    };

    const handleCreateBundle = async () => {
        setIsLoading(true);
        const options = {
            name,
            nameShort,
            target,
            systemProvider: provider?.value || null,
        };

        const resp = await api.post('/setting/bundle', options);

        if (resp.data) {
            const requestUserStatistic = {
                getParams: {
                    pageNumber: 1,
                },
            };
            await props.settingsRequest(requestUserStatistic);
            props.switchModal(props.dataModal);
            NotificationManager.success(props.translate('Success'), '', 3500);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner">
                <div className="modal-container__header">
                    <div>{props.translate('create_setting_bundle')}</div>
                </div>
                <div className="Bundle">
                    <Input
                        hideLabel
                        placeholder={props.translate('Name')}
                        value={name}
                        onChange={(e: any) => setName(e.target.value)}
                    />
                    <Input
                        hideLabel
                        placeholder={props.translate('name_short')}
                        value={nameShort}
                        onChange={(e: any) => setNameShort(e.target.value)}
                    />
                    <Input
                        hideLabel
                        placeholder={props.translate('Target')}
                        value={target}
                        onChange={(e: any) => setTarget(e.target.value)}
                    />
                    <OutlinedDropdown
                        className="select-bundle"
                        style
                        placeholder={props.translate('System Provider')}
                        options={getOptionProvider()}
                        onSelect={(e) => setProvider(e)}
                        defaultSelected={provider}
                    />
                </div>

                <div className="modal-container__btns" style={{ display: 'flex', gap: '10px' }}>
                    <Button
                        loading={isLoading}
                        className="Bundle-button"
                        title={props.translate('Create')}
                        primary
                        onClick={handleCreateBundle}
                    />

                    <Button
                        className="Bundle-button"
                        title={props.translate('Cancel')}
                        onClick={() => {
                            props.switchModal(props.dataModal);
                        }}
                    />
                </div>
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(CreateBundle);
