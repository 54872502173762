import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { withTranslate } from 'react-redux-multilingual';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from 'src/api';
import { ReactComponent as Loading } from 'src/assets/images/loading.svg';
import Button from 'src/components/Button';
import Paginate from 'src/components/Paginate';

import Item from './item';

const EditSettings = (props) => {
    const isMobile = window.innerWidth <= 900;
    const defaultPerPage = isMobile ? 1 : 2;
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [settings, setSettings] = useState([]);
    const [changeSettings, setChangeSettings] = useState([]);
    const [amountPerPage, setAmountPerPage] = useState(defaultPerPage);
    const navigate = useNavigate();

    const { id } = useParams();
    const handleConfirmExit = () => {
        if (changeSettings.length === 0) {
            navigate('/settings-bundle');
            return;
        }
        const confirmExit = window.confirm(props.translate('confirm_settings'));
        if (confirmExit) {
            navigate('/settings-bundle');
        }
    };

    const handleBeforeUnload = (event) => {
        if (changeSettings.length > 0) {
            event.returnValue = true;
            return 'Ви впевнені, що хочете вийти без збереження змін?';
        }
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changeSettings]);

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>, providerGameType, indexItem) => {
        const { value } = e.target;
        const regex = /^\d*(\.\d{0,2})?$/;

        if (regex.test(value)) {
            const updatedSettings = settings.map((el) => {
                if (el.games.some((sss) => sss.providerGameType === providerGameType)) {
                    const updatedGames = el.games.map((sss, index) => {
                        if (index === indexItem) {
                            const updatedGame = { ...sss, [e.target.name]: value, idIndex: indexItem };

                            if (changeSettings.length !== 0) {
                                const existingElementIndex = changeSettings.find(
                                    (el) => el.idIndex === indexItem && el.providerGameType === providerGameType,
                                );

                                if (existingElementIndex) {
                                    const updatedChangeSettings = changeSettings.map((elemnts) => {
                                        if (elemnts.idIndex === indexItem && elemnts.providerGameType === providerGameType)
                                            return updatedGame;
                                        return elemnts;
                                    });
                                    setChangeSettings(updatedChangeSettings);
                                } else {
                                    setChangeSettings([...changeSettings, updatedGame]);
                                }
                            } else {
                                setChangeSettings([...changeSettings, updatedGame]);
                            }

                            return updatedGame;
                        }
                        return sss;
                    });

                    return { ...el, games: updatedGames };
                }
                return el;
            });

            setSettings(updatedSettings);
        }
    };

    const handleChangeInputTwo = (e: React.ChangeEvent<HTMLInputElement>, providerGameType, indexFirstItem, indexSecondItem) => {
        const { value } = e.target;
        const regex = /^\d*$/;

        if (regex.test(value)) {
            const updatedSettings = settings.map((item) => {
                if (item.providerGameType !== providerGameType) return item;

                return {
                    ...item,
                    games: item.games.map((el, index) => {
                        if (index !== indexFirstItem) return el;

                        return {
                            ...el,
                            childrens: el.childrens.map((element, indexSecond) => {
                                if (indexSecond !== indexSecondItem) return element;

                                const itemUpdate = { ...element, [e.target.name]: value };

                                const existingElementIndex = changeSettings.find(
                                    (el) => el.providerGameName === element.providerGameName,
                                );

                                if (existingElementIndex) {
                                    const map = changeSettings.map((el) => {
                                        if (el.providerGameName === itemUpdate.providerGameName) return itemUpdate;
                                        return el;
                                    });

                                    setChangeSettings(map);
                                } else {
                                    setChangeSettings([...changeSettings, itemUpdate]);
                                }

                                return itemUpdate;
                            }),
                        };
                    }),
                };
            });

            setSettings(updatedSettings);
        }
    };

    const handleChangeCheckBoxTwo = (checked: boolean, providerGameType, indexFirstItem, indexSecondItem) => {
        const updatedSettings = settings.map((item) => {
            if (item.providerGameType !== providerGameType) return item;

            return {
                ...item,
                games: item.games.map((el, index) => {
                    if (index !== indexFirstItem) return el;

                    return {
                        ...el,
                        childrens: el.childrens.map((element, indexSecond) => {
                            if (indexSecond !== indexSecondItem) return element;

                            const itemUpdate = { ...element, isDisabled: !checked };

                            const existingElementIndex = changeSettings.find(
                                (el) => el.providerGameName === element.providerGameName,
                            );

                            if (existingElementIndex) {
                                const map = changeSettings.map((el) => {
                                    if (el.providerGameName === itemUpdate.providerGameName) return itemUpdate;
                                    return el;
                                });

                                setChangeSettings(map);
                            } else {
                                setChangeSettings([...changeSettings, itemUpdate]);
                            }
                            return itemUpdate;
                        }),
                    };
                }),
            };
        });

        setSettings(updatedSettings);
    };

    const handleChangeCheckBox = (checked: boolean, providerGameType, indexFirstItem) => {
        const updatedSettings = settings.map((el) => {
            if (el.games.some((sss) => sss.providerGameType === providerGameType)) {
                const updatedGames = el.games.map((sss, index) => {
                    if (index === indexFirstItem) {
                        const updatedGame = { ...sss, isDisabled: !checked };

                        if (changeSettings.length !== 0) {
                            const existingElementIndex = changeSettings.findIndex((el, index) => index === indexFirstItem);

                            if (existingElementIndex !== -1) {
                                const updatedChangeSettings = [...changeSettings];
                                updatedChangeSettings[existingElementIndex] = updatedGame;
                                setChangeSettings(updatedChangeSettings);
                            } else {
                                setChangeSettings([...changeSettings, updatedGame]);
                            }
                        } else {
                            setChangeSettings([...changeSettings, updatedGame]);
                        }

                        return updatedGame;
                    }
                    return sss;
                });

                return { ...el, games: updatedGames };
            }
            return el;
        });

        setSettings(updatedSettings);
    };

    const handleClickSave = async () => {
        if (changeSettings.length === 0) return;
        setIsLoadingSave(true);
        const options = {
            target: id,
            data: changeSettings
                .map((item) => {
                    const { childrens, createDate, updateDate, version, idIndex, ...remain } = item;
                    return remain;
                })
                .map((el) => ({
                    ...el,
                    maxBet: (Number(el.maxBet) * 100).toFixed(2),
                    maxWin: (Number(el.maxWin) * 100).toFixed(2),
                })),
        };

        const resp = await api.post('/setting/update', options);
        if (resp.data) {
            NotificationManager.success(`${props.translate('Success')}`, '', 3500);
            setIsLoadingSave(false);
            setChangeSettings([]);
        } else {
            setIsLoadingSave(false);
        }
    };

    const loadData = async () => {
        setIsLoading(true);

        const resp = await api.get(`/setting?target=${id}`);

        if (resp.data) {
            const mapped = {
                ...resp.data,
                data: resp.data.data.map((item) => ({
                    ...item,
                    maxBet: Number(item.maxBet) / 100,
                    maxWin: Number(item.maxWin) / 100,
                })),
            };
            const gamesMap = new Map();

            mapped.data.forEach((elem) => {
                if (gamesMap.has(elem.providerGameType)) {
                    const existingArray = gamesMap.get(elem.providerGameType);
                    existingArray.push(elem);
                } else {
                    gamesMap.set(elem.providerGameType, [elem]);
                }
            });

            const resultArray = [];

            for (const [key, value] of gamesMap.entries()) {
                const gameProviderMap = new Map();
                value.forEach((elem) => {
                    if (gameProviderMap.has(elem.gameProvider)) {
                        const existingArray = gameProviderMap.get(elem.gameProvider);
                        existingArray.push(elem);
                    } else {
                        gameProviderMap.set(elem.gameProvider, [elem]);
                    }
                });

                const gamesArray = Array.from(gameProviderMap.values());

                const hamess = gamesArray
                    .map((element) => {
                        const gamesWithChildrens = element
                            .filter((item) => item.providerGameId === null)
                            .map((game) => ({
                                ...game,
                                childrens: element.filter((child) => child.providerGameId !== null),
                            }));
                        return gamesWithChildrens;
                    })
                    .flat();

                resultArray.push({
                    providerGameType: key,
                    games: hamess,
                });
            }

            setSettings(resultArray);

            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const handlePage = (pageNumber: number): void => {
        setAmountPerPage(pageNumber * defaultPerPage);
    };

    useEffect(() => {
        loadData();
    }, []);

    if (isLoading)
        return (
            <div className="Loading">
                <Loading />
            </div>
        );

    return (
        <div className="Wrapper">
            <h1 className="Wrapper-button-back" onClick={handleConfirmExit}>
                {props.translate('Back')}
            </h1>

            <div className="Wrapper-list">
                {settings.slice(amountPerPage - defaultPerPage, amountPerPage).map((item, index) => (
                    <Item
                        key={index}
                        props={item}
                        handleChangeInput={handleChangeInput}
                        handleChangeCheckBox={handleChangeCheckBox}
                        handleChangeInputTwo={handleChangeInputTwo}
                        handleChangeCheckBoxTwo={handleChangeCheckBoxTwo}
                        translate={props.translate}
                    />
                ))}
            </div>
            <div className="Wrapper-layout">
                <Paginate pageCount={settings.length / defaultPerPage} handlePage={handlePage} />
                <Button className="btn gold-btn nowrap" type="button" onClick={handleClickSave} isLoading={isLoadingSave}>
                    {props.translate('Save')}
                </Button>
            </div>
        </div>
    );
};

export default withTranslate(EditSettings);
