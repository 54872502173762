import React from 'react';
import { PermissionEnum } from 'src/common/provider-common-type-util/dictionary/permission';
import CheckBox from 'src/components/Checkbox';

import { IProfileWithChildren } from '../../../interfaces/IManageUsers';
import Button from '../../Button';
import Input from '../../Input';
import InputFilter from '../../InputFilter';
import FormHOC from '../FormHOC';

import validator from './validator';

import './styles.scss';

interface ICreateUserForm {
    allUsers: IProfileWithChildren[];
    user: IProfileWithChildren;
    permissionLevel: number;
    dataModal: string;
    values: {
        login: string;
        password: string;
        name: string;
        parent: number;
        percent?: number;
        isSchedule?: boolean;
        permissionLevel?: number;
    };
    errors: {
        login: any;
        password: any;
        name: any;
        parent: any;
        percent?: number;
    };
    handlerStatus: {
        status: string;
    };
    isLoading: boolean;
    onSubmit: (e: any) => void;
    handleChange: any;
    handleChangeCheckbox: any;
    selectInputFilter: () => void;
    switchModal: () => void;
    translate: (key: string) => string;
}

const Form: React.FC<ICreateUserForm> = (props) => {
    let sortedUsers: IProfileWithChildren[] = [];

    if (Array.isArray(props.allUsers)) {
        sortedUsers = props.allUsers.filter((user) => user.permissionLevel > props.permissionLevel);

        if (props.user.permissionLevel === PermissionEnum.USER_ADMIN_PERMISSION_LEVEL) {
            sortedUsers.push(props.user);
        }

        if (props.user.permissionLevel === PermissionEnum.USER_AGENT_PERMISSION_LEVEL) {
            sortedUsers = [];
            sortedUsers.push(props.user);
        }
    }

    return (
        <form onSubmit={props.onSubmit}>
            <div className="modal-container__inputs">
                <div>
                    <select
                        value={props.values.permissionLevel}
                        className="select gold-select select-filter-type select-user"
                        name="permissionLevel"
                        onChange={props.handleChange}
                    >
                        <option value="" disabled selected>
                            {props.translate('role')}
                        </option>
                        <option value={PermissionEnum.USER_MASTER_PERMISSION_LEVEL}>{props.translate('Master')}</option>
                        <option value={PermissionEnum.USER_AGENT_PERMISSION_LEVEL}>{props.translate('Agent')}</option>
                    </select>
                </div>
                <Input
                    className="input gold-input"
                    placeholder={props.translate('Login')}
                    type="login"
                    name="login"
                    value={props.values.login}
                    error={props.errors.login}
                    onChange={props.handleChange}
                />
                <Input
                    className="input gold-input"
                    placeholder={props.translate('Password')}
                    type="text"
                    name="password"
                    error={props.errors.password}
                    value={props.values.password}
                    onChange={props.handleChange}
                />
                <Input
                    className="input gold-input"
                    placeholder={props.translate('Name')}
                    type="text"
                    name="name"
                    value={props.values.name}
                    error={props.errors.name}
                    onChange={props.handleChange}
                />
                <InputFilter
                    inputProps={{
                        className: 'input gold-input',
                        placeholder: props.translate('parentId'),
                        type: 'text',
                        name: 'parent',
                        value: props.values.parent,
                        error: props.errors.parent,
                        onChange: props.handleChange,
                        autoComplete: 'off',
                    }}
                    selectById={true}
                    selectInputFilter={props.selectInputFilter}
                    listData={sortedUsers}
                />
                {Number(props.values.permissionLevel) === PermissionEnum.USER_AGENT_PERMISSION_LEVEL ? (
                    <Input
                        className="input gold-input"
                        placeholder={props.translate('Percent')}
                        type="text"
                        name="percent"
                        data-pattern="onlyNumbers, rangeNumbers"
                        value={props.values.percent}
                        error={props.errors.percent}
                        onChange={props.handleChange}
                    />
                ) : null}
                {props.permissionLevel === PermissionEnum.USER_PLAYER_PERMISSION_LEVEL && (
                    <CheckBox
                        label={props.translate('isSchedule')}
                        checked={props.values.isSchedule}
                        onChange={() => props.handleChangeCheckbox(!props.values.isSchedule, 'isSchedule')}
                    />
                )}
            </div>
            <div className="modal-container__btns">
                <Button
                    isLoading={props.isLoading}
                    className="btn gold-btn"
                    type="submit"
                    disabled={props.handlerStatus.status === 'loading'}
                >
                    {props.translate('Add')}
                </Button>
                <Button className="btn grey-btn" type="button" data-modal={props.dataModal} onClick={props.switchModal}>
                    {props.translate('Cancel')}
                </Button>
            </div>
        </form>
    );
};

export default FormHOC(
    Form,
    {
        name: '',
        login: '',
        password: '',
        parent: '',
        profitPercent: '',
        cashbackPercent: '',
        isSchedule: false,
    },
    validator,
);
