import React from 'react';

import { IUserData, IHandlerStatus } from '../../../interfaces/IProfile';
import Button from '../../Button';
import Input from '../../Input';
import FormHOC from '../FormHOC';

import validator from './validator';

interface IProfitForm {
    userData: IUserData;
    dataModal: string;
    handlerStatus: IHandlerStatus;
    values: { amount: number };
    errors: { amount: number };
    switchModal(e: React.MouseEvent<HTMLElement>): void;
    onSubmit(params: any): void;
    translate(key: string): string;
    handleChange(e: any): void;
}

const Form: React.FC<IProfitForm> = (props) => (
    <form onSubmit={props.onSubmit}>
        <div className="modal-container__inputs">
            <Input
                className="input gold-input"
                placeholder={props.userData.profitPercent}
                type="text"
                name="amount"
                data-pattern="onlyNumbers, rangeNumbers"
                value={props.values.amount}
                error={props.errors.amount}
                onChange={props.handleChange}
            />
        </div>
        <div className="modal-container__btns">
            <Button className="btn gold-btn" type="submit" disabled={props.handlerStatus.status === 'loading'}>
                {props.translate('Apply')}
            </Button>
            <Button className="btn grey-btn" type="button" data-modal={props.dataModal} onClick={props.switchModal}>
                {props.translate('Cancel')}
            </Button>
        </div>
    </form>
);

export default FormHOC(
    Form,
    {
        amount: '',
    },
    validator,
);
