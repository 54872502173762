import React from 'react';

const Preloader: React.FC = () => (
    <div>
        <div className="loadingio-spinner-rolling-pafula8xkz">
            <div className="ldio-nh0bcceol4k">
                <div />
            </div>
        </div>
    </div>
);

export default React.memo(Preloader);
