import React from 'react';
import { withTranslate } from 'react-redux-multilingual';
import './404.scss';

const NotPage = ({ translate }) => (
    <div className="NotPage">
        <span>OLWIN</span>
        <p>{translate('the_current')}</p>
        <a href="/">{translate('return_home')}</a>
    </div>
);

export default withTranslate(NotPage);
