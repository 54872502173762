/* eslint-disable no-unused-vars */
export class SettingDictionary {
  // Setting status
  static SETTING_STATUS_ENABLED: number = 1;
  static SETTING_STATUS_DISABLED: number = 2;
}

export enum SettingStatusEnum {
  SETTING_STATUS_ENABLED = SettingDictionary.SETTING_STATUS_ENABLED,
  SETTING_STATUS_DISABLED = SettingDictionary.SETTING_STATUS_DISABLED
}
