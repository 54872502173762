import { jwtDecode } from 'jwt-decode';

export const setAuthToken = (token: string) => {
    document.cookie = `authToken=${token}; path=/`;
};

export const getAuthToken = () => {
    const name = 'authToken=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
};

export const removeAuthToken = () => {
    document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export const isTokenExpired = (token) => {
    if (!token) return true;

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decoded.exp < currentTime;
    } catch (error) {
        return true;
    }
};

// export const isTokenExpired = () => {
//     const token = getAuthToken();
//     if (!token) return true;
//     const expiry = JSON.parse(atob(token.split('.')[1])).exp;
//     return Math.floor(new Date().getTime() / 1000) >= expiry;
// };
