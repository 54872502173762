export default {
    locale: 'en-US',
    messages: {
        // Templates
        'Cashback Percent': 'Cashback Percent',
        'Login': 'Login',
        'Time/Date': 'Time/Date',
        'Status': 'Status',
        'Amount': 'Amount',
        'Password': 'Password',
        'Masters': 'Masters',
        'Agents': 'Agents',
        'Players': 'Players',
        'Balance': 'Balance',
        'Cashback': 'Cashback',
        'Rotatable Payment': 'Rotatable Payment',
        'Date': 'Date',
        'Total Balance': 'Total Balance',
        'Profit': 'Profit',
        'Agent Commission': 'Agent Commission',
        'Master Commission': 'Master Commission',
        'System Commission': 'System Commission',
        'createDate': 'Creation Date',
        'startDate': 'Start Date',
        'currentTickets': 'Current Tickets',
        'cashbackPercent': 'Cashback Percent',
        'result': 'Result',
        'targetCurrentBalance': 'Target Current Balance',
        'Type': 'Type',
        'Id': 'Id',
        'Creator': 'Creator',
        'Target': 'Target',
        'CashbackPlayer': 'Cashback Player',
        'creatorCurrentBalance': 'Creator Current Balance',
        'UserId': 'User Id',
        'Bets Count': 'Bets Count',
        'Canceled bets': 'Canceled Bets',
        'Debit amount': 'Debit Amount',
        'Win amount': 'Win Amount',
        'Profit amount': 'Profit Amount',
        'Comment': 'Comment',
        'ProfitPercent': 'Profit Percent',

        // Links
        'Statistics': 'Statistics',
        'Transactions': 'Transactions',
        'Manage Users': 'Manage Users',
        'Logout': 'Logout',

        // Components
        'Add': 'Add',
        'Take': 'Take',
        'Cancel': 'Cancel',
        'Add New': 'Add New User',
        'Checkpoint': 'Checkpoint',
        'One On One': 'One On One',
        'Price': 'Price',
        'Month Prize': 'Month Prize',
        'Jackpot': 'Jackpot',
        'Master': 'Master',
        'Agent': 'Agent',
        'User': 'User',
        'Confirm': 'Confirm',
        'User Ban': 'User Ban',
        'User Unban': 'User Unban',
        'No content': 'No content',
        'Profit Percent': 'Profit Percent',
        'Discount': 'Discount',
        'Change': 'Change',
        'New password': 'New Password',
        'Confirm password': 'Confirm Password',
        'Reset': 'Reset',
        'Change password': 'Change Password',
        'Change Comment': 'Change Comment',
        'New Comment': 'New Comment',
        'Accept': 'Accept',
        'Update': 'Update',
        'Create': 'Create',
        'Delete': 'Delete',
        'Update rule': 'Update Rule',
        'Apply': 'Apply',
        'Set': 'Set',
        'Chance': 'Chance',
        'more': 'More',
        'less': 'Less',
        'odd': 'Odd',
        'even': 'Even',
        'Special': 'Special',
        'Parity': 'Parity',
        'MoreLess': 'More/Less',
        'Make bet': 'Make Bet',
        'isSchedule': 'Schedule',

        // Pages
        'Best at Table': 'Best at Table',
        'Group Bet': 'Group Bet',
        'Daily': 'Daily',
        'Game': 'Game',
        'Users': 'Users',
        'Add Master': 'Add Master',
        'Add Agent': 'Add Agent',
        'Add User': 'Add User',
        'Name': 'Name',
        'Edit': 'Edit',
        'Rules': 'Rules',
        'Ticker': 'Ticker',
        'RuleList': 'Rule List',

        // Other
        'Username': 'Username',
        'country': 'Country',
        'team': 'Team',
        'parentId': 'Parent ID',
        'This user is already getting scedual payment': 'This user is already getting scheduled payment',
        'Payment date': 'Payment Date',
        'Set scedual payment': 'Set scheduled payment',
        'Weekly': 'Weekly',
        'Monthly': 'Monthly',
        'Wait': 'Wait',
        'Set result': 'Set result',
        'documents': 'Documents',
        'approve': 'Approve',
        'reject': 'Reject',
        'on_review': 'On Review',
        'approved': 'Verified',
        'rejected': 'Rejected',
        'secondName': 'Last Name',
        'birth': 'Birthdate',
        'сountry': 'Country',
        'city': 'City',
        'email': 'E-mail',
        'gender': 'Gender',
        'phone': 'Phone Number',

        'Possible gain': 'Possible Gain',

        // Create User
        'male': 'Male',
        'female': 'Female',
        'user_name_input': 'Username',
        'enter_surname': 'Enter your surname',
        'user_surname_input': 'User Surname',
        'e_mail': 'E-mail',
        'enter_e_mail': 'Enter your E-mail',
        'login_input': 'Login',
        'birthaday_date': 'Birthdate',
        'day': 'Day',
        'year': 'Year',
        'month': 'Month',
        'user_password_input': 'Password',
        'enter_password': 'Enter password',
        'enter_name': 'Enter username',
        'select_country': 'Select Country',
        'select_city': 'City',
        'validate_longer_4': 'Field must be longer than 4 characters',
        'validate_longer_2': 'Field must be longer than 2 characters',
        'validate_email': 'Enter a valid E-mail',
        'validate_phone': 'Enter a valid phone number',
        'validate_password': 'Password must be between 4-32 characters',
        'register_success': 'User created',

        // Create Balance
        'Balance_type': 'Balance Type',
        'System_provider': 'System Provider',
        'Game_provider': 'Game Provider',
        'Provider_game_type': 'Provider Game Type',
        'Provider_game_id': 'Provider Game ID',
        'Provider_game_name': 'Provider Game Name',
        'Provider_transaction_id': 'Provider Transaction ID',
        'Success': 'Success',
        'reason': 'Reason',
        'change_balance': 'Change Balance',
        'amount': 'Amount',
        'date': 'Date',
        'target_current_balance': 'Target Current Balance',
        'create': 'Create',
        'finish': 'Finish',
        'status': 'Status',
        'Action': 'Action',
        'Balances': 'Balances',
        'game_provider': 'Game Provider',
        'game_type': 'Game Type',
        'game_name': 'Game Name',
        'system_provider': 'System Provider',
        'provider_type': 'Provider Type',
        'provider_game_id': 'Provider Game ID',
        'provider_transaction_id': 'Provider Transaction ID',

        // Settings
        'Settings': 'Settings',
        'name_short': 'Short Name',
        'create_setting_bundle': 'Create Setting Bundle',
        'edit_setting_bundle': 'Edit Setting Bundle',
        'olwin_chance': 'OLWIN Chance',
        'System Provider': 'System Provider',
        'confirm_settings': 'Are you sure you want to exit without saving the changes?',
        'Max Bet': 'Max Bet',
        'Max Win': 'Max Win',
        'Disabled': 'Disabled',
        'Provider Name': 'Provider Name',
        'Back': 'Back',
        'Save': 'Save',

        // New Translate
        'Betting': 'Betting',
        'betCount': 'Bet Count',
        'debitAmount': 'Debit Amount',
        'profitAmount': 'Profit Amount',
        'totalCashback': 'Total Cashback',
        'winAmount': 'Win Amount',
        'info-user': 'User info',
        'role': 'Role',
        'Add Player': 'Add player',
        'last-7-days': 'Last 7 days',
        'last-month': 'Last month',
        'last-6-months': 'Last 6 months',
        'login-account': 'Account Login',
        'sign-in': 'Sign in',
        'user-agent': 'User Agent',
        'ip-address': 'IP Address',
        'transaction-by-user': 'by User',
        'transaction-by-tree': 'by Tree',
        'canceledBet': 'Canceled Bet',
        'agentProfitAmount': 'Agent profit amount',
        'masterProfitAmount': 'Master profit amount',
        'Report': 'Report',
        'Provider': 'Provider',
        'LoginPriority': '"Login" priority over "Target"',

        'Casino': 'Casino',
        'Racing': 'Racing',
        'Played': 'Played',
        'Refunded': 'Refunded',
        'Won': 'Won',
        'UserName': 'User name',
        'Waiting': 'Waiting',
        'only-player': 'Can only be Player',
        'not-player': "Can't be Player",

        'total-profit-casino': 'Profit casino',
        'total-profit-racing': 'Profit racing',
        'total-profit-game': 'Profit system game',
        'total-profit-sport': 'Profit sport',
        'total-profit': 'Total profit',
        'total-deposit': 'Total deposit',
        'total-withdrawal': 'Total withdrawal',
        'logged-players': 'Logged in players',
        'players-balance': 'Players balance',
        'show-details': 'Show details',
        'oldest-date': 'Oldest date',
        'Total Statistic': 'Total Statistic',

        // new translate part 2

        'system-game': 'System Game',
        'total': 'Total',
        'info_transaction': 'Info Transaction',

        'remember': 'Remember me',
        'choose-language': 'Choose language',
        'the_current': 'The current page may have been removed, changed or is temporarily unavailable.',
        'return_home': 'Return to the homepage',

        'Percent': 'Percent',
        'validate_percent': 'Please enter a valid percent between 0 and 100.',
        'BALANCE_TYPE_TRANSFER': 'Transfer',
        'BALANCE_TYPE_TAKE': 'Take',
        'BALANCE_TYPE_DEPOSIT': 'Deposit',
        'BALANCE_TYPE_OUT': 'Out',
        'BALANCE_TYPE_REDUCE': 'Reduce',
        'BALANCE_TYPE_ADD': 'Add',
        'BALANCE_TYPE_REFUND': 'Refund',
        'BALANCE_TYPE_SCEDUAL_REDUCE': 'Scheduled Reduce',
        'BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE': 'Scheduled Reduce Balance',
        'BALANCE_TYPE_INFO': 'Info',
        'BALANCE_TYPE_RACING_DEPOSIT': 'Racing Deposit',
        'BALANCE_TYPE_RACING_WITHDRAW': 'Racing Withdraw',
        'BALANCE_TYPE_CASINO_DEBIT': 'Casino Debit',
        'BALANCE_TYPE_CASINO_CREDIT': 'Casino Credit',
        'BALANCE_TYPE_CASINO_ROLLBACK': 'Casino Rollback',
        'BALANCE_TYPE_SPORT_DEBIT': 'Sport Debit',
        'BALANCE_TYPE_SPORT_CREDIT': 'Sport Credit',
        'BALANCE_TYPE_SPORT_ROLLBACK': 'Sport Rollback',
    },
};
