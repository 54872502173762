import React from 'react';

interface Props {
    url: string;
    name: string;
}

const Logo: React.FC<Props> = (props) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    return !props.url.includes('default.png') ? (
        <img className="logo-image" src={`${baseUrl}/${props.url}`} alt={props.name} />
    ) : (
        <div />
    );
};

export default React.memo(Logo);
