export enum ActionTypes {
    // Games
    GAMES_REQUEST = '@@games/GAMES_REQUEST',
    GAMES_SUCCESS = '@@games/GAMES_SUCCESS',

    CREATE_GAME_REQUEST = '@@games/CREATE_GAME_REQUEST',
    CREATE_GAME_SUCCESS = '@@games/CREATE_GAME_SUCCESS',

    UPDATE_GAME_REQUEST = '@@games/UPDATE_GAME_REQUEST',
    UPDATE_GAME_SUCCESS = '@@games/UPDATE_GAME_SUCCESS',

    // Matches
    MATCHES_REQUEST = '@@games/MATCHES_REQUEST',
    MATCHES_SUCCESS = '@@games/MATCHES_SUCCESS',

    CREATE_MATCH_REQUEST = '@@games/CREATE_MATCH_REQUEST',
    CREATE_MATCH_SUCCESS = '@@games/CREATE_MATCH_SUCCESS',

    UPDATE_MATCH_REQUEST = '@@games/UPDATE_MATCH_REQUEST',
    UPDATE_MATCH_SUCCESS = '@@games/UPDATE_MATCH_SUCCESS',

    DELETE_MATCH_REQUEST = '@@games/DELETE_MATCH_REQUEST',
    DELETE_MATCH_SUCCESS = '@@games/DELETE_MATCH_SUCCESS',

    RESULT_MATCH_REQUEST = '@@games/RESULT_MATCH_REQUEST',
    RESULT_MATCH_SUCCESS = '@@games/RESULT_MATCH_SUCCESS',
}
