export enum ActionTypes {
    MANAGE_USERS_REQUEST = '@@manageUsers/MANAGE_USERS_REQUEST',
    MANAGE_USERS_SUCCESS = '@@manageUsers/MANAGE_USERS_SUCCESS',
    MANAGE_USERS_EXPANSION = '@@manageUsers/MANAGE_USERS_EXPANSION',
    FILTER_REPORT = '@@manageUsers/FILTER_REPORT',
    FILTER_TRANSACTION = '@@manageUsers/FILTER_TRANSACTION',

    STATISTICS_REQUEST = '@@manageUsers/STATISTICS_REQUEST',
    STATISTICS_SUCCESS = '@@manageUsers/STATISTICS_SUCCESS',

    BETTING_REQUEST = '@@manageUsers/BETTING_REQUEST',
    BETTING_SUCCESS = '@@manageUsers/BETTING_SUCCESS',

    BALANCES_REQUEST = '@@manageUsers/BALANCES_REQUEST',
    BALANCES_SUCCESS = '@@manageUsers/BALANCES_SUCCESS',

    SETTINGS_REQUEST = '@@manageUsers/SETTINGS_REQUEST',
    SETTINGS_SUCCESS = '@@manageUsers/SETTINGS_SUCCESS',
    SETTINGS_UPDATE = '@@manageUsers/SETTINGS_UPDATE',

    CHANCES_REQUEST = '@@gamesTypes/CHANCES_REQUEST',
    CHANCES_SUCCESS = '@@gamesTypes/CHANCES_SUCCESS',
    CREATE_TICKET_REQUEST = '@@gamesTypes/CREATE_TICKET_REQUEST',

    PROVIDER_STATISTICS_REQUEST = '@@manageUsers/PROVIDER_STATISTICS_REQUEST',
    PROVIDER_STATISTICS_SUCCESS = '@@manageUsers/PROVIDER_STATISTICS_SUCCESS',
    PROVIDER_USERS_EXPANSION = '@@manageUsers/PROVIDER_USERS_EXPANSION',

    CREATE_USER_REQUEST = '@@manageUsers/CREATE_USER_REQUEST',
    CREATE_USER_ERROR = '@@manageUsers/CREATE_USER_ERROR',
    CREATE_USER_SUCCESS = '@@manageUsers/CREATE_USER_SUCCESS',

    CREATE_USER_JOIN_REQUEST = '@@manageUsers/CREATE_USER_JOIN_REQUEST',
    CREATE_USER_JOIN_ERROR = '@@manageUsers/CREATE_USER_JOIN_ERROR',
    CREATE_USER_JOIN_SUCCESS = '@@manageUsers/CREATE_USER_JOIN_SUCCESS',

    ADD_BALANCE_REQUEST = '@@manageUsers/ADD_BALANCE_REQUEST',
    TAKE_BALANCE_REQUEST = '@@manageUsers/TAKE_BALANCE_REQUEST',
    USER_BALANCE_SUCCESS = '@@manageUsers/USER_BALANCE_SUCCESS',

    USER_BAN_REQUEST = '@@manageUsers/USER_BAN_REQUEST',
    USER_BAN_SUCCESS = '@@manageUsers/USER_BAN_SUCCESS',

    CASHBACK_REQUEST = '@@manageUsers/CASHBACK_REQUEST',
    CASHBACK_SUCCESS = '@@manageUsers/CASHBACK_SUCCESS',

    PROFIT_REQUEST = '@@manageUsers/PROFIT_REQUEST',
    PROFIT_SUCCESS = '@@manageUsers/PROFIT_SUCCESS',

    SCHELUDE_REQUEST = '@@manageUsers/SCHELUDE_REQUEST',
    SCHELUDE_SUCCESS = '@@manageUsers/SCHELUDE_SUCCESS',

    PROVIDER_MAX_LIMIT_REQUEST = '@@manageUsers/PROVIDER_MAX_LIMIT_REQUEST',
    PROVIDER_MAX_LIMIT_SUCCESS = '@@manageUsers/PROVIDER_MAX_LIMIT_SUCCESS',

    CHANGE_PASSWORD_REQUEST = '@@manageUsers/CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS = '@@manageUsers/CHANGE_PASSWORD_SUCCESS',

    GET_COMMENT_REQUEST = '@@manageUsers/GET_COMMENT_REQUEST',
    COMMENT_SUCCESS = '@@manageUsers/COMMENT_SUCCESS',
    CHANGE_COMMENT_REQUEST = '@@manageUsers/CHANGE_COMMENT_REQUEST',

    SCEDUAL_PAYMENT_REQUEST = '@@manageUsers/SCEDUAL_PAYMENT_REQUEST',
    SCEDUAL_PAYMENT_SUCCESS = '@@manageUsers/SCEDUAL_PAYMENT_SUCCESS',
    SET_SCEDUAL_PAYMENT_REQUEST = '@@manageUsers/SET_SCEDUAL_PAYMENT_REQUEST',
    DELETE_SCEDUAL_PAYMENT_REQUEST = '@@manageUsers/DELETE_SCEDUAL_PAYMENT_REQUEST',

    GET_LOGIN_HISTORY_REQUEST = '@@manageUsers/GET_LOGIN_HISTORY_REQUEST',
    GET_LOGIN_HISTORY_SUCCESS = '@@manageUsers/GET_LOGIN_HISTORY_SUCCESS',

    ON_USER_SELECT = '@@manageUsers/ON_USER_SELECT',
    UPDATE_STATUS_DOCUMENT = '@@manageUsers/UPDATE_STATUS_DOCUMENT',
}
