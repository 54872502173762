/* eslint-disable */
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import loginHistoryIcon from 'src/assets/images/loginHistoryIcon.png';
import note from 'src/assets/images/note.png';
import { PermissionEnum } from 'src/common/provider-common-type-util/dictionary/permission';
import { UserStatusEnum } from 'src/common/provider-common-type-util/dictionary/user';

import { stringTrim } from '../../helpers';
import CheckBox from '../Checkbox';
import RadioButton from '../StatusDocuments';

import { premisionLevel } from './balances';

const manageUsers = (item, { additionalData, handlers, translate, _styles, searchedId, paddingLeft = 45, country }) => {
    const array: any = [];

    const id = searchedId.find((el) => el === item.id);

    let userColor;
    if (item.permissionLevel === PermissionEnum.USER_MASTER_PERMISSION_LEVEL) userColor = 'user-color__master';
    if (item.permissionLevel === PermissionEnum.USER_AGENT_PERMISSION_LEVEL) userColor = 'user-color__agent';
    if (item.permissionLevel === PermissionEnum.USER_PLAYER_PERMISSION_LEVEL) userColor = 'user-color__user';

    if (item?.parent?.permissionLevel !== additionalData?.permissionLevel) {
        if (item.parent?.permissionLevel > item?.permissionLevel) {
            paddingLeft += 15;
        }
    }
    const isChild = paddingLeft > 45;

    if (item._isShow) {
        array.push(
            <tr
                className={cn({
                    'user-animation': isChild,
                    'searched-user': id ? true : false,
                })}
                key={item.id}
            >
                <td
                    style={{
                        ..._styles[0],
                    }}
                >
                    {item.id}
                </td>
                <td
                    style={{
                        ..._styles[1],
                        paddingLeft: `${paddingLeft}px`,
                    }}
                >
                    <div
                        className={cn('manage-users__name-container', {
                            'manage-users__name-container--banned': item.status === UserStatusEnum.USER_STATUS_BANNED,
                            'manage-users__name-container--allow-expanded': item.children.length,
                        })}
                    >
                        <span
                            className="user-ban__btn"
                            data-user={`${item.id}::${item.name}::${item.status}`}
                            onClick={handlers.userBan}
                        >
                            {item.status === UserStatusEnum.USER_STATUS_ACTIVE ? 'x' : '✓'}
                        </span>

                        <div style={{ display: 'flex' }} data-id={item.id} onClick={handlers.expandList}>
                            {!!item.children.length && (
                                <span
                                    className={cn('manage-users__triangle', {
                                        'manage-users__triangle--expanded': item._isShowChildren,
                                    })}
                                />
                            )}
                            <span
                                onClick={handlers.infoUser}
                                title={item.login}
                                data-user={`${JSON.stringify(item)}`}
                                className={`manage-users__name string-trim ${userColor}`}
                                style={{ cursor: 'pointer' }}
                            >
                                {stringTrim(item.login)}
                            </span>
                            <span className="loginHistory" onClick={(e) => handlers.loginHistory(e, item.id, item.login)}>
                                <img className="loginHistoryIcon" src={loginHistoryIcon} alt="" />
                            </span>
                            {item.permissionLevel === PermissionEnum.USER_PLAYER_PERMISSION_LEVEL && (
                                <span
                                    className="cashback-btn-container checked-isSchedule"
                                    data-user={`${item.id}::${item.name}::${JSON.stringify(item.documents)}`}
                                    onClick={handlers.documents}
                                    style={{ marginLeft: '9px' }}
                                >
                                    <RadioButton
                                        value={
                                            item.documents && item.documents.length !== 0
                                                ? item.documents
                                                      .map((item) => ({ ...item, createDate: new Date(item.createDate) }))
                                                      .sort(
                                                          (a: { createDate: number }, b: { createDate: number }) =>
                                                              a.createDate - b.createDate,
                                                      )[item.documents.length - 1].status
                                                : null
                                        }
                                    />
                                </span>
                            )}
                        </div>
                    </div>
                </td>

                {/* Agents column */}
                {/* {additionalData.permissionLevel > userDictionary.USER_MASTER_PERMISSION_LEVEL && (
                    <td style={{ ..._styles[2] }}>
                        {item.permissionLevel > userDictionary.USER_AGENT_PERMISSION_LEVEL
                            ? item.children.filter((item) => item.permissionLevel === userDictionary.USER_AGENT_PERMISSION_LEVEL)
                                  .length
                            : '-'}
                    </td>
                )} */}

                {/* Players column */}
                {/* {additionalData.permissionLevel > userDictionary.USER_AGENT_PERMISSION_LEVEL && (
                    <td style={{ ..._styles[3] }}>
                        {item.permissionLevel > userDictionary.USER_PLAYER_PERMISSION_LEVEL
                            ? item.children.filter((item) => item.permissionLevel === userDictionary.USER_PLAYER_PERMISSION_LEVEL)
                                  .length
                            : '-'}
                    </td>
                )} */}

                <td
                    style={{ ..._styles[10] }}
                    className={
                        localStorage.locale === 'he' || localStorage.locale === 'ru' ? 'manage-users__balance-big-padding' : ''
                    }
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <span>{Number(item.balance) + Number(item.scedualPaymentBalance)}</span>
                        {item.parent?.permissionLevel === PermissionEnum.USER_ADMIN_PERMISSION_LEVEL && (
                            <div className="manage-users-btn btn-link-styles" style={{ position: 'relative', top: 0, right: 0 }}>
                                <span data-user={`${item.id}::${item.name}::Add`} onClick={handlers.userBalance}>
                                    {translate('Add')}
                                </span>
                                <span> / </span>
                                <span data-user={`${item.id}::${item.name}::Take`} onClick={handlers.userBalance}>
                                    {translate('Take')}
                                </span>
                            </div>
                        )}
                    </div>
                </td>
                {premisionLevel() === 8 && (
                    <td style={{ ..._styles[7] }}>
                        <span
                            className="limit-btn-container checked-isSchedule"
                            data-user={`${item.id}::${item.isSchedule}`}
                            onClick={handlers.limit}
                        >
                            {item.parent?.permissionLevel === PermissionEnum.USER_ADMIN_PERMISSION_LEVEL && (
                                <CheckBox label="" checked={item.isSchedule} />
                            )}
                        </span>
                    </td>
                )}
                {premisionLevel() === 8 && (
                    <td style={{ ..._styles[0], cursor: 'pointer' }}>
                        <span
                            data-user={`${item.id}::${item?.percent ?? 0}::${item.name}`}
                            onClick={
                                item.permissionLevel === PermissionEnum.USER_AGENT_PERMISSION_LEVEL ? handlers.percent : () => {}
                            }
                        >
                            {item.percent ? `${item.percent} % ` : '0 %'}
                        </span>
                    </td>
                )}
                <td>
                    <div className="date-note">
                        <div>
                            <span>{moment(item.createDate).format('MM/DD')}</span>
                            <img src={note} alt="note" />
                        </div>
                    </div>
                </td>
                <td>
                    <span
                        className="btn-link-styles user-comment__btn"
                        data-user={`${item.id}::${item.name}`}
                        onClick={handlers.changeComment}
                    >
                        {translate('Add')}
                    </span>
                </td>
            </tr>,
        );
    }

    if (item.children.length) {
        item.children.map((item) =>
            array.push(
                ...manageUsers(item, {
                    additionalData,
                    handlers,
                    _styles,
                    searchedId,
                    paddingLeft,
                    translate,
                    country,
                }),
            ),
        );
    }

    return array;
};

// @ts-ignore
export default manageUsers;
