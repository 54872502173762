import moment from 'moment';
import React from 'react';
import { formatUserTimeZone } from 'src/utils/helpers';

import { getCardByValue } from '../../helpers';
import { IChance } from '../../interfaces/IChance';

const getResult = (item: IChance): string => {
    if (item.result) {
        return `${getCardByValue(item.result.first)} ${getCardByValue(item.result.second)} ${getCardByValue(
            item.result.third,
        )} ${getCardByValue(item.result.fourth)}`;
    }
    return 'Result was not found.';
};

export default (item: IChance, { _styles }) => (
    <tr key={item.id}>
        <td style={{ ..._styles[0] }}>{item.id}</td>
        <td style={{ ..._styles[1] }}>{item.currentTickets}</td>
        <td style={{ ..._styles[3] }}>{getResult(item)}</td>
        <td style={{ ..._styles[3] }}>{item.status}</td>
        <td style={{ ..._styles[3] }}>{item.error === 1 ? 'NOT ENOUGH PLAYERS' : '-'}</td>
        <td style={{ ..._styles[4] }}>
            {moment(formatUserTimeZone(item.startDate as unknown as string)).format('HH:mm:ss DD/MM')}
        </td>
        <td style={{ ..._styles[5] }}>
            {moment(formatUserTimeZone(item.createDate as unknown as string)).format('HH:mm:ss DD/MM')}
        </td>
    </tr>
);
