import { all, fork, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from './types';

function* handleSuccess() {
    // TODO: reset refactor
    // yield new Promise(resolve => setTimeout(resolve, 1000))
    // yield put(manageHandlers.handleReset())
}

function* watchRequests() {
    yield takeEvery(ActionTypes.HANDLE_SUCCESS, handleSuccess);
}

function* rootSaga() {
    yield all([fork(watchRequests)]);
}

export default rootSaga;
