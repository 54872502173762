import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import { CSSTransition } from 'react-transition-group';
import loading from 'src/assets/images/preloader_olwin.gif';
import { ScedualPaymentStatusEnum } from 'src/common/provider-common-type-util/dictionary/scedual-payment';
import { UserStatusEnum } from 'src/common/provider-common-type-util/dictionary/user';
import InfoIUser from 'src/components/Modals/infoIUser';
import UserPercentModal from 'src/components/Modals/UserPercentModal';
import { withRouter } from 'src/withRouter';

import { api } from '../../api';
import manageUsersData from '../../assets/tableTemplates/manageUsers';
import Input from '../../components/Input';
import CashbackModal from '../../components/Modals/CashbackModal';
import ChangeCommentModal from '../../components/Modals/ChangeCommentModal';
import ChangePasswordModal from '../../components/Modals/ChangePasswordModal';
import CreateUserModal from '../../components/Modals/CreateUserModal';
import DocumentsModal from '../../components/Modals/Documents';
import ProfitModal from '../../components/Modals/ProfitModal';
import ProviderMaxLimitModal from '../../components/Modals/ProviderMaxLimitModal';
import ScedualPaymentModal from '../../components/Modals/ScedualPaymentModal';
import UserBalanceModal from '../../components/Modals/UserBalanceModal';
import UserBanModal from '../../components/Modals/UserBanModal';
import NoContent from '../../components/NoContent';
import Paginate from '../../components/Paginate';
import Table from '../../components/Table';
import { switchModalGlobal } from '../../helpers';
import { IComment } from '../../interfaces/IComment';
import { IProfileWithChildren } from '../../interfaces/IManageUsers';
import { IProfile } from '../../interfaces/IProfile';
import { IScedualPayment } from '../../interfaces/IScedualPayment';
import {
    manageUsersRequest,
    manageUsersExpansion,
    commentRequest,
    commentSuccess,
    scedualPaymentRequest,
    scedualPaymentSuccess,
    onUserSelect,
    scheludeRequest,
} from '../../store/manageUsers/actions';
import { debounce } from '../../utils/utils';

import CreateUserBtns from './CreateUserBtns';

class ManageUsers extends Component<any, any> {
    private nodeRef: any;

    constructor(props) {
        super(props);
        this.nodeRef = React.createRef();
        this.state = {
            permissionLevel: 0,
            userData: null,
            userDataInfo: null,
            createUserIsOpen: false,
            isOpenInfoUser: false,
            userBalanceIsOpen: false,
            userPercentIsOpen: false,
            userBanIsOpen: false,
            cashbackIsOpen: false,
            profitIsOpen: false,
            limitIsOpen: false,
            scedualPaymentIsOpen: false,
            changePasswordIsOpen: false,
            amountPerPage: 20,
            changeCommentIsOpen: false,
            searchLogin: '',
            searchedUserId: [],
            documentsIsOpen: false,
            country: [],
            currentPage: 1,
            isLoading: false,
        };
    }

    getCountry = async () => {
        const resp = await api.get('/user/region');
        if (resp) {
            if (resp.data) {
                if (resp.data.country) {
                    const options = Object.entries(resp.data.countries).map(([key, value]) => ({
                        label: value,
                        value: key,
                    }));
                    this.setState({ country: options });
                }
            }
        }
    };

    loadData = async () => {
        this.setState({ isLoading: true });
        await this.props.manageUsersRequest();
        await this.getCountry();
        this.setState({ isLoading: false });
    };

    componentDidMount(): void {
        this.loadData();
    }

    switchModal = (e: React.MouseEvent<HTMLElement>): void => {
        switchModalGlobal(this, e);
    };

    createUserModal = (e: any): void => {
        this.setState({
            permissionLevel: +e.currentTarget.dataset.permissionlevel,
            createUserIsOpen: true,
        });
    };

    handlePage = (pageNumber: number): void => {
        this.setState({
            currentPage: pageNumber,
            amountPerPage: pageNumber * 20,
        });
    };

    expandList = (e: any): void => {
        if (this.state.searchLogin.length !== 0) return;

        const id: number = +e.currentTarget.dataset.id;

        const user: IProfileWithChildren | undefined = this.props.allUsers.find((item) => item.id === id);

        if (!user) return;

        const ids: number[] = [];

        user.children.map((item) => {
            ids.push(item.id);

            if (user._isShowChildren) {
                item.children.map((item) => ids.push(item.id));
            }

            return null;
        });

        if (!ids.length) return;

        const newData: IProfileWithChildren[] = this.props.allUsers.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    _isShowChildren: !user._isShowChildren,
                };
            }
            if (ids.includes(item.id)) {
                return {
                    ...item,
                    _isShow: !user._isShowChildren,
                    _isShowChildren: !!user._isShowChildren,
                };
            }
            return item;
        });

        this.props.manageUsersExpansion(newData);
    };

    changePassword = (e: any): void => {
        const [userId, name] = e.currentTarget.dataset.user.split('::');

        this.setState({
            changePasswordIsOpen: true,
            userData: {
                userId,
                name,
            },
        });
    };

    userBan = (e: any): void => {
        const [userId, name, status] = e.currentTarget.dataset.user.split('::');

        this.setState({
            userBanIsOpen: true,
            userData: {
                userId,
                name,
                userStatus:
                    +status === UserStatusEnum.USER_STATUS_BANNED
                        ? UserStatusEnum.USER_STATUS_ACTIVE
                        : UserStatusEnum.USER_STATUS_BANNED,
            },
        });
    };

    changeComment = (e: any): void => {
        const [userId, name] = e.currentTarget.dataset.user.split('::');

        if (+userId !== (this.props?.comment?.target && this.props?.comment?.target?.id)) {
            this.props.commentSuccess({});
            this.props.commentRequest({ getParams: { target: userId } });
        }

        this.setState({
            changeCommentIsOpen: true,
            userData: {
                userId,
                name,
            },
        });
    };

    userBalance = (e: any): void => {
        const [target, name, type] = e.currentTarget.dataset.user.split('::');

        this.setState({
            userData: { target, name, type },
            userBalanceIsOpen: true,
        });
    };

    userPercent = (e: any): void => {
        const [target, percent, name] = e.currentTarget.dataset.user.split('::');

        this.setState({
            userData: { target, percent, name },
            userPercentIsOpen: true,
        });
    };

    infoUser = (e: any): void => {
        const [item] = e.currentTarget.dataset.user.split('::');

        const user = JSON.parse(item);

        this.setState({
            userDataInfo: { user },
            isOpenInfoUser: true,
        });
    };

    cashback = (e: any): void => {
        const [userId, name, cashbackPercent] = e.currentTarget.dataset.user.split('::');

        this.setState({
            userData: {
                userId,
                name,
                cashbackPercent,
            },
            cashbackIsOpen: true,
        });
    };

    profit = (e: any): void => {
        const [userId, name, profitPercent] = e.currentTarget.dataset.user.split('::');
        this.setState({
            userData: {
                userId,
                name,
                profitPercent,
            },
            profitIsOpen: true,
        });
    };

    limit = (e: any): void => {
        const [userId, isSchedule] = e.currentTarget.dataset.user.split('::');

        const value = isSchedule === 'true' ? false : true;

        this.props.scheludeRequest({
            id: userId,
            isSchedule: value,
        });
    };

    documents = async (e: any): Promise<void> => {
        const [userId, name, documents] = e.currentTarget.dataset.user.split('::');

        const { id, status } = JSON.parse(documents)
            .map((item) => ({ ...item, createDate: new Date(item.createDate) }))
            .sort((a: { createDate: number }, b: { createDate: number }) => a.createDate - b.createDate)[
            JSON.parse(documents).length - 1
        ];
        const resp = await api.get(`/document/url?id=${userId}`);
        if (resp.data) {
            this.setState({
                userData: {
                    userId,
                    name,
                    documents: resp.data,
                    documentId: id,
                    statusDocument: status,
                },
                documentsIsOpen: true,
            });
        } else {
            console.log(resp.error.message);
        }
    };

    scedualPayment = (e: any): void => {
        const [userId, name] = e.currentTarget.dataset.user.split('::');

        if (
            !this.props.scedualPayment ||
            !this.props.scedualPayment.user ||
            (this.props.scedualPayment.user && !this.props.scedualPayment.user.some((user) => user.id === Number(userId)))
        ) {
            this.props.scedualPaymentSuccess({});
            this.props.scedualPaymentRequest({ userId, status: ScedualPaymentStatusEnum.SCEDUAL_PAYMENT_STATUS_CREATED });
        }

        this.setState({
            userData: {
                userId,
                name,
            },
            scedualPaymentIsOpen: true,
        });
    };

    loginHistory = (e: React.MouseEvent<HTMLElement>, id: number, userName: string) => {
        e.stopPropagation();
        this.props.history.push(`/login-history/${id}`);
        this.props.onUserSelect(userName);
    };

    optimaze = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        this.setState({
            searchLogin: value,
        });
        this.changeSearchLogin(value);
    };

    changeSearchLogin = debounce((value: string): void => {
        if (this.state.currentPage !== 1) {
            this.handlePage(1);
        }

        this.setState({
            searchLogin: value,
        });
    });

    filteredUsers = () => {
        const searchLoginLowerCase = this.state.searchLogin.toLowerCase();

        const allUser = this.props.allUsers.filter((user) => user.login);

        const users = allUser.filter((user) => user.login?.toLowerCase()?.startsWith(searchLoginLowerCase));

        const children = this.props.allUsers
            .map((user) => {
                const childrens = user.children.filter((child) => child.login);
                const filteredChildren = childrens
                    .filter((child) => child.login.toLowerCase().startsWith(searchLoginLowerCase))
                    .map((item) => ({ ...item, _isShow: true }));

                return filteredChildren.length > 0 ? filteredChildren : null;
            })
            .flat()
            .filter(Boolean);

        if (searchLoginLowerCase.length === 0) {
            return users;
        }

        return [...users, ...children];
    };

    render(): JSX.Element {
        const {
            permissionLevel,
            userData,
            createUserIsOpen,
            userBalanceIsOpen,
            userPercentIsOpen,
            changePasswordIsOpen,
            userBanIsOpen,
            cashbackIsOpen,
            profitIsOpen,
            limitIsOpen,
            amountPerPage,
            changeCommentIsOpen,
            scedualPaymentIsOpen,
            documentsIsOpen,
            userDataInfo,
            isOpenInfoUser,
            isLoading,
        } = this.state;
        const { user, allUsers, userTree, usersLoaded, translate } = this.props;

        return (
            <>
                <CSSTransition in={isOpenInfoUser} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <InfoIUser
                        dataModal="isOpenInfoUser"
                        switchModal={this.switchModal}
                        permissionLevel={permissionLevel}
                        allUsers={allUsers}
                        userTree={userTree}
                        handlers={{
                            userBan: this.userBan,
                            expandList: this.expandList,
                            infoUser: this.infoUser,
                            changePassword: this.changePassword,
                            userBalance: this.userBalance,
                            cashback: this.cashback,
                            percent: this.userPercent,
                            profit: this.profit,
                            limit: this.limit,
                            scedualPayment: this.scedualPayment,
                            changeComment: this.changeComment,
                            loginHistory: this.loginHistory,
                            documents: this.documents,
                        }}
                        usersLoaded={usersLoaded}
                        translate={translate}
                        userData={userDataInfo}
                        country={this.state.country}
                    />
                </CSSTransition>
                <CSSTransition in={createUserIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <CreateUserModal
                        dataModal="createUserIsOpen"
                        switchModal={this.switchModal}
                        permissionLevel={permissionLevel}
                        allUsers={allUsers}
                        userTree={userTree}
                        usersLoaded={usersLoaded}
                        translate={translate}
                    />
                </CSSTransition>

                <CSSTransition in={userBalanceIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <UserBalanceModal dataModal="userBalanceIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>
                <CSSTransition in={userPercentIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <UserPercentModal
                        loadData={this.props.manageUsersRequest}
                        dataModal="userPercentIsOpen"
                        switchModal={this.switchModal}
                        userData={userData}
                    />
                </CSSTransition>

                <CSSTransition in={changePasswordIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <ChangePasswordModal dataModal="changePasswordIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>

                <CSSTransition in={userBanIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <UserBanModal dataModal="userBanIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>

                <CSSTransition in={cashbackIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <CashbackModal dataModal="cashbackIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>

                <CSSTransition in={profitIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <ProfitModal dataModal="profitIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>

                <CSSTransition in={limitIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <ProviderMaxLimitModal dataModal="limitIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>

                <CSSTransition in={scedualPaymentIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <ScedualPaymentModal
                        dataModal="scedualPaymentIsOpen"
                        switchModal={this.switchModal}
                        userData={userData}
                        scedualPayment={this.props.scedualPayment}
                    />
                </CSSTransition>

                <CSSTransition in={changeCommentIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <ChangeCommentModal
                        dataModal="changeCommentIsOpen"
                        switchModal={this.switchModal}
                        userData={userData}
                        comment={this.props.comment}
                    />
                </CSSTransition>
                <CSSTransition in={documentsIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <DocumentsModal
                        dataModal="documentsIsOpen"
                        switchModal={this.switchModal}
                        userData={userData}
                        comment={this.props.documents}
                    />
                </CSSTransition>

                {!isLoading ? (
                    <div className="manage-users-page page-container" style={{ marginTop: '5px' }}>
                        <CSSTransition
                            nodeRef={this.nodeRef}
                            in={usersLoaded}
                            unmountOnExit={true}
                            timeout={200}
                            classNames="modalWindowAnimation"
                        >
                            {allUsers.length ? (
                                <div ref={this.nodeRef}>
                                    <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', padding: '0 5px' }}>
                                        <Input
                                            className="input gold-input input-filter-login"
                                            placeholder={translate('Login')}
                                            type="text"
                                            name="searchLogin"
                                            error=""
                                            value={this.state.searchLogin}
                                            onChange={this.optimaze}
                                        />
                                        <CreateUserBtns
                                            user={user}
                                            createUserModal={this.createUserModal}
                                            translate={translate}
                                        />
                                    </div>
                                    <div className="manage-users__content">
                                        <div className="card-line card-line__header">
                                            <div>{translate('Users')}</div>
                                        </div>

                                        <Table
                                            tableData={{
                                                ...manageUsersData,
                                                body: this.filteredUsers().slice(amountPerPage - 20, amountPerPage),
                                            }}
                                            handlers={{
                                                userBan: this.userBan,
                                                expandList: this.expandList,
                                                infoUser: this.infoUser,
                                                changePassword: this.changePassword,
                                                userBalance: this.userBalance,
                                                cashback: this.cashback,
                                                percent: this.userPercent,
                                                profit: this.profit,
                                                limit: this.limit,
                                                scedualPayment: this.scedualPayment,
                                                changeComment: this.changeComment,
                                                loginHistory: this.loginHistory,
                                                documents: this.documents,
                                            }}
                                            additionalData={this.props.user}
                                            searchedId={this.state.searchedUserId}
                                            style={undefined}
                                            tbodyHeight=""
                                            country={this.state.country}
                                        />
                                    </div>

                                    <div className="card-line card-line__footer" />

                                    <Paginate
                                        page={this.state.currentPage - 1}
                                        pageCount={this.filteredUsers().length / 20}
                                        handlePage={this.handlePage}
                                    />
                                </div>
                            ) : (
                                <NoContent />
                            )}
                        </CSSTransition>
                    </div>
                ) : (
                    <div className="loading-page">
                        <img src={loading} alt="" />
                    </div>
                )}
            </>
        );
    }
}

interface IStoreState {
    auth: {
        user: IProfile;
    };
    manageUsers: {
        allUsers: IProfileWithChildren[];
        userTree: IProfileWithChildren[];
        usersLoaded: boolean;
        comment: IComment;
        scedualPayment: IScedualPayment;
    };
}

const mapStateToProps = (state: IStoreState) => ({
    user: state.auth.user,
    allUsers: state.manageUsers.allUsers,
    userTree: state.manageUsers.userTree,
    usersLoaded: state.manageUsers.usersLoaded,
    comment: state.manageUsers.comment,
    scedualPayment: state.manageUsers.scedualPayment,
});

const mapDispatchToProps = {
    manageUsersRequest,
    manageUsersExpansion,
    commentRequest,
    commentSuccess,
    scedualPaymentRequest,
    scedualPaymentSuccess,
    onUserSelect,
    scheludeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(withRouter(ManageUsers)));
