import { AxiosResponse } from 'axios';
import qs from 'qs';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import { api } from '../../api';
import { IAction } from '../../interfaces/IAction';
import { IEvoCurrentGame, IEvoGame, IEvoOpenGameRequest } from '../../interfaces/IEvoGames';
import manageHandlers from '../manageHandlers/actions';

import { gamesSuccess, openGameSuccess, closeGameSuccess, openGameFail } from './actions';
import { ActionTypes } from './types';

function* gamesRequest() {
    try {
        const response: AxiosResponse<IEvoGame[]> = yield api.get(`/provider/evolution/games-list`);

        yield put(gamesSuccess(response.data));
    } catch (err) {}
}

function* openGameRequest(action: IAction<IEvoOpenGameRequest>) {
    try {
        const parsedParams: string = qs.stringify({
            gameId: action.payload.gameId,
            firstName: 'test',
            lastName: 'test',
            language: 'en',
            country: 'ru',
            ipAddress: '213.153.152.180',
            mobile: 'false',
            freeGame: 'false',
        });
        const response: AxiosResponse<IEvoCurrentGame> = yield api.get(`/provider/evolution/game-url?${parsedParams}`);

        yield put(openGameSuccess(response.data));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
        yield put(openGameFail());
    }
}

function* closeGameRequest() {
    try {
        yield put(closeGameSuccess());
    } catch (err) {}
}

function* watchRequests() {
    yield takeEvery(ActionTypes.GAMES_REQUEST, gamesRequest);
    yield takeEvery(ActionTypes.OPEN_GAME_REQUEST, openGameRequest);
    yield takeEvery(ActionTypes.CLOSE_GAME_REQUEST, closeGameRequest);
}

function* rootSaga() {
    yield all([fork(watchRequests)]);
}

export default rootSaga;
