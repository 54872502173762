import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';

import { loginRequest, userEdit } from '../../store/auth/actions';
import {
    createGameRequest,
    updateGameRequest,
    createMatchRequest,
    updateMatchRequest,
    deleteMatchRequest,
    resultMatchRequest,
} from '../../store/games/actions';
import { createLogoRequest, updateLogoRequest, deleteLogoRequest } from '../../store/logos/actions';
import {
    createUserRequest,
    createUserJoinRequest,
    userBanRequest,
    addBalanceRequest,
    takeBalanceRequest,
    cashbackRequest,
    profitRequest,
    providerMaxLimitRequest,
    changePasswordRequest,
    changeCommentRequest,
    commentRequest,
    scedualPaymentRequest,
    setScedualPaymentRequest,
    deleteScedualPaymentRequest,
    updateStatusDocuments,
    manageUsersRequest,
} from '../../store/manageUsers/actions';

const ModalHOC = (WrappedComponent) => {
    class ComponentHOC extends Component<any, any> {
        constructor(props) {
            super(props);
            this.state = {
                handlerStatus: {
                    status: '',
                    message: '',
                },
            };
        }

        componentDidUpdate() {
            if (this.props.handlerStatus.status === 'success' && this.state.handlerStatus.status !== 'success') {
                this.setState(
                    {
                        handlerStatus: this.props.handlerStatus,
                    },
                    () => {
                        if (this.props.switchModal) {
                            this.props.switchModal(this.props.dataModal);
                        }
                    },
                );
            }
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    }

    return withTranslate(
        connect(
            (state: any) => ({
                user: state.auth.user,
                handlerStatus: state.manageHandlers.handlerStatus,
            }),
            {
                loginRequest,
                userEdit,
                createUserRequest,
                createUserJoinRequest,
                userBanRequest,
                addBalanceRequest,
                takeBalanceRequest,
                createGameRequest,
                updateGameRequest,
                createMatchRequest,
                resultMatchRequest,
                updateMatchRequest,
                deleteMatchRequest,
                cashbackRequest,
                profitRequest,
                providerMaxLimitRequest,
                changePasswordRequest,
                createLogoRequest,
                updateLogoRequest,
                deleteLogoRequest,
                changeCommentRequest,
                commentRequest,
                scedualPaymentRequest,
                setScedualPaymentRequest,
                deleteScedualPaymentRequest,
                updateStatusDocuments,
                manageUsersRequest,
            },
        )(ComponentHOC),
    );
};

export default ModalHOC;
