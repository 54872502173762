/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import { CSSTransition } from 'react-transition-group';
import DateRangePickerComponent from 'src/components/UI/DateRangePickerComponent/DateRangePickerComponent';
import MultiSelect from 'src/components/UI/MultiSelect';
import { dateToTimestamp } from 'src/utils/helpers';

import statisticsData from '../../assets/tableTemplates/statistics';
import Button from '../../components/Button';
import Input from '../../components/Input';
import UserBalanceModal from '../../components/Modals/UserBalanceModal';
import NoContent from '../../components/NoContent';
import Paginate from '../../components/Paginate';
import Table from '../../components/Table';
import { switchModalGlobal } from '../../helpers';
import { IEvoGame } from '../../interfaces/IEvoGames';
import { IProfile, IHandlerStatus } from '../../interfaces/IProfile';
import { IStatisticsProps, IStatisticsState, IStatisticGet, IRequestUserStatistic } from '../../interfaces/IStatistics';
import { gamesRequest } from '../../store/evoGames/actions';
import { statisticsRequest, setFilterTransaction } from '../../store/manageUsers/actions';
import InfoTransaction from 'src/components/Modals/InfoTransaction';
import { BalanceProviderTypeEnum, BalanceTypeEnum } from 'src/common/provider-common-type-util/dictionary/balance';

class Statistics extends Component<IStatisticsProps, IStatisticsState> {
    constructor(props) {
        super(props);

        this.state = {
            userData: null,
            searchLogin: '',
            searchType: [],
            providerType: [],
            currentLogin: '',
            userBalanceIsOpen: false,
            startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
            userId: '',
            isLoading: false,
            typeRequest: 'user',
            userDataInfo: null,
            isOpenInfoTransaction: false,
        };
    }

    loadData = async () => {
        if (this.props.filterTransaction.date !== null && this.props.filterTransaction.provider !== null) {
            await this.setState({
                startDate: this.props.filterTransaction.date,
                searchType: [
                    {
                        value: String(this.props.filterTransaction.provider),
                        label: BalanceTypeEnum[this.props.filterTransaction.provider],
                    },
                ],
            });
            this.filter();
            return;
        }
        this.filter();
    };

    componentWillUnmount(): void {
        this.props.setFilterTransaction({ date: null, provider: null });
    }

    componentDidMount(): void {
        this.loadData();
    }

    switchModal = (e: React.MouseEvent<HTMLElement>): void => switchModalGlobal(this, e);

    handlePage = (pageNumber: number): void => {
        const requestUserStatistic: IRequestUserStatistic = {
            getParams: {
                timeTo: dateToTimestamp(this.state.endDate),
                timeFrom: dateToTimestamp(this.state.startDate),
                pageNumber,
                typeRequest: this.state.typeRequest,
            },
        };
        if (this.state.currentLogin) {
            requestUserStatistic.getParams.login = this.state.currentLogin;
        }

        if (this.state.searchType) {
            requestUserStatistic.getParams.type = this.state.searchType.map((item) => item.value);
        }

        if (this.state.userId.length !== 0) {
            requestUserStatistic.getParams.target = Number(this.state.userId);
        }

        this.props.statisticsRequest(requestUserStatistic);
    };

    selectDate = (dates: Date[]) => {
        const [start, end] = dates;
        this.setState({ startDate: start, endDate: end });
    };

    userBalance = (e: any): void => {
        const [target, name, type] = e.currentTarget.dataset.user.split('::');

        this.setState({
            userData: { target, name, type },
            userBalanceIsOpen: true,
        });
    };

    filter = () => {
        this.setState({
            isLoading: true,
        });
        const requestUserStatistic: IRequestUserStatistic = {
            getParams: {
                timeTo: dateToTimestamp(this.state.endDate),
                timeFrom: dateToTimestamp(this.state.startDate),
                pageNumber: 1,
                typeRequest: this.state.typeRequest,
            },
        };

        if (this.state.currentLogin) {
            requestUserStatistic.getParams.login = this.state.currentLogin;
        }

        if (this.state.searchType) {
            requestUserStatistic.getParams.type = this.state.searchType.map((item) => item.value);
        }
        if (this.state.providerType) {
            requestUserStatistic.getParams.providerType = this.state.providerType.map((item) => item.value);
        }
        if (this.state.userId.length !== 0) {
            requestUserStatistic.getParams.target = Number(this.state.userId);
        }

        this.props.statisticsRequest(requestUserStatistic);

        this.setState((prevState) => ({
            currentLogin: prevState.searchLogin,
            searchType: prevState.searchType,
            isLoading: false,
        }));

        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 20000);
    };

    changeSearchLogin = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;

        this.setState({
            searchLogin: value,
        });
    };

    handleSearchTypeChange = (selectedOptions) => {
        this.setState({
            searchType: selectedOptions,
        });
    };

    handleProviderTypeChange = (selectedOptions) => {
        this.setState({
            providerType: selectedOptions,
        });
    };

    changeTypeRequest = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const { value } = event.target;

        this.setState({
            typeRequest: value,
            userId: '',
            searchType: [],
            startDate: new Date().setMonth(new Date().getMonth() - 6),
            endDate: new Date(),
        });

        this.props.statisticsRequest({
            getParams: {
                target: this.props.user.id,
                timeTo: dateToTimestamp(this.state.endDate),
                timeFrom: dateToTimestamp(this.state.startDate),
                pageNumber: 1,
                typeRequest: value,
            },
        });
    };

    changeUserId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        const regex = /^\d*$/;

        if (regex.test(value)) {
            this.setState({
                userId: value,
            });
        }
    };

    chnageLogin = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        this.setState({
            currentLogin: value,
        });
    };

    getOptionBalanceType = () => {
        const enumKeys = Object.keys(BalanceTypeEnum)
            .filter((key) => Number.isNaN(Number(BalanceTypeEnum[key])))
            .map((key) => ({
                label: this.props.translate(BalanceTypeEnum[key]),
                value: Number(key),
            }));
        return enumKeys;
    };

    getOptionBalanceProviderType = () => {
        const enumKeys = Object.keys(BalanceProviderTypeEnum)
            .filter((key) => Number.isNaN(Number(BalanceProviderTypeEnum[key])))
            .map((key) => ({
                label:
                    BalanceProviderTypeEnum[key]?.split('BALANCE_PROVIDER_TYPE_SPORT_')[1] ??
                    BalanceProviderTypeEnum[key]?.split('BALANCE_TYPE_CASINO_')[1] ??
                    BalanceProviderTypeEnum[key],
                value: Number(key),
            }));
        return enumKeys;
    };

    infoTransaction = (e: any): void => {
        const [item] = e.currentTarget.dataset.user.split('::');

        const user = JSON.parse(item);

        this.setState({
            userDataInfo: user,
            isOpenInfoTransaction: true,
        });
    };

    render(): JSX.Element {
        const { userData, userBalanceIsOpen, isOpenInfoTransaction, userDataInfo } = this.state;
        const { usersLoaded, statistics, translate } = this.props;

        return (
            <>
                <CSSTransition in={userBalanceIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <UserBalanceModal dataModal="userBalanceIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>
                <CSSTransition in={isOpenInfoTransaction} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <InfoTransaction
                        translate={translate}
                        dataModal="isOpenInfoTransaction"
                        switchModal={this.switchModal}
                        userData={userDataInfo}
                    />
                </CSSTransition>

                <div className="statistics-page page-container">
                    <div
                        className="card-line card-line__header"
                        style={{ display: 'flex', gap: '20px', justifyContent: 'flex-start', alignItems: 'center' }}
                    >
                        <div>{translate('Transactions')}</div>
                        <div className="statistic-type-request">
                            <select
                                value={this.state.typeRequest}
                                className="select gold-select select-filter-type"
                                name="typeRequest"
                                style={{
                                    background: 'transparent',
                                    paddingBottom: '5px',
                                    marginTop: '6px',
                                    color: '#e6bc73',
                                    height: '30px',
                                }}
                                onChange={this.changeTypeRequest}
                            >
                                <option value="user">{translate('transaction-by-user')}</option>
                                <option value="tree">{translate('transaction-by-tree')}</option>
                            </select>
                        </div>
                    </div>

                    <div
                        className="statistics__filter"
                        style={{ marginTop: '10px', display: 'flex', gap: '10px', padding: '0 5px' }}
                    >
                        <div className="DTPicker-container">
                            <DateRangePickerComponent
                                onChange={this.selectDate}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                className="DTPicker-container"
                            />
                        </div>
                        <Button
                            className="btn gold-btn nowrap"
                            type="button"
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.filter();
                                setTimeout(() => {
                                    this.setState({ isLoading: false });
                                }, 500);
                            }}
                            isLoading={this.state.isLoading}
                        >
                            {this.props.translate('Apply')}
                        </Button>
                    </div>
                    <div className="transactions-filter" style={{ gap: '10px' }}>
                        <div style={{ width: '100%' }}>
                            <Input
                                className="input gold-input input-filter-login"
                                placeholder={this.props.translate('Target')}
                                type="text"
                                name="target"
                                error=""
                                value={this.state.userId}
                                onChange={this.changeUserId}
                            />
                        </div>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <Input
                                className="input gold-input input-filter-login"
                                placeholder={this.props.translate('Login')}
                                type="text"
                                name="login"
                                error=""
                                value={this.state.currentLogin}
                                onChange={this.chnageLogin}
                            />
                            {this.state.userId.length !== 0 && this.state.currentLogin.length !== 0 && (
                                <span className="error-statistic">{translate('LoginPriority')}</span>
                            )}
                        </div>
                        <div style={{ width: '100%' }}>
                            <MultiSelect
                                onChange={this.handleSearchTypeChange}
                                values={this.state.searchType}
                                options={this.getOptionBalanceType()}
                                placeholder={translate('Type')}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <MultiSelect
                                onChange={this.handleProviderTypeChange}
                                values={this.state.providerType}
                                options={this.getOptionBalanceProviderType()}
                                placeholder={translate('provider_type')}
                            />
                        </div>
                    </div>
                    <CSSTransition in={usersLoaded} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                        {statistics.balanceHistory.length ? (
                            <>
                                <div className="statistics__content">
                                    <div className="statistics__branch">
                                        <Table
                                            tableData={{
                                                ...statisticsData,
                                                body: statistics.balanceHistory,
                                            }}
                                            handlers={{
                                                userBalance: this.userBalance,
                                                infoTransaction: this.infoTransaction,
                                            }}
                                            style={undefined}
                                            tbodyHeight=""
                                            additionalData={undefined}
                                        />
                                    </div>
                                </div>
                                <Paginate
                                    pageCount={statistics.totalCount / statistics.amountPerPage}
                                    handlePage={this.handlePage}
                                />
                            </>
                        ) : (
                            <NoContent translate={translate} />
                        )}
                    </CSSTransition>
                </div>
            </>
        );
    }
}

interface IStoreState {
    auth: {
        user: IProfile;
    };
    manageUsers: {
        statistics: IStatisticGet;
        filterTransaction: {
            date: null | Date;
            provider: null | number;
        };
    };
    manageHandlers: {
        handlerStatus: IHandlerStatus;
    };
    evoGames: {
        gamesList: IEvoGame[];
    };
    usersLoaded: boolean;
}

const mapStateToProps = (state: IStoreState) => ({
    user: state.auth.user,
    statistics: state.manageUsers.statistics,
    handlerStatus: state.manageHandlers.handlerStatus,
    gamesList: state.evoGames.gamesList,
    filterTransaction: state.manageUsers.filterTransaction,
    values: {
        start: new Date(new Date().getTime() - 604800000),
        end: new Date(),
    },
    errors: {
        start: '',
        end: '',
    },
    usersLoaded: true,
});

const mapDispatchToProps = {
    statisticsRequest,
    gamesRequest,
    setFilterTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(Statistics));
