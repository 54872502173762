/* eslint-disable */
import { Reducer } from 'redux';

import { gameDictionary } from '../../common/dictionary';
import { changeBalance, sort } from '../../helpers';
import { IChance } from '../../interfaces/IChance';
import { IGame, IMatch, ITicket } from '../../interfaces/IGames';

import { ActionTypes } from './types';

interface IInitialState {
    bestAtTable: {
        games: {
            data: IGame[];
        };
        game: {
            matches: IMatch[];
            tickets: ITicket[];
        };
    };
    checkpoint: any;
    monthPrize: {
        games: {
            data: IGame[];
        };
    };
    daily: {
        games: {
            data: IGame[];
        };
    };
    jackpot: {
        games: {
            data: IGame[];
        };
    };
    oneOnOne: {
        games: {
            data: IGame[];
        };
    };
    chance: {
        games: {
            data: IChance[];
        };
    };
    priceChangeRequests: any[];
}

export const initialState: IInitialState = {
    bestAtTable: {
        games: {
            data: [],
        },
        game: {
            matches: [],
            tickets: [],
        },
    },
    checkpoint: {
        games: {
            data: [],
        },
        point: {},
    },
    monthPrize: {
        games: {
            data: [],
        },
    },
    daily: {
        games: {
            data: [],
        },
    },
    jackpot: {
        games: {
            data: [],
        },
    },
    oneOnOne: {
        games: {
            data: [],
        },
    },
    chance: {
        games: {
            data: [],
        },
    },
    priceChangeRequests: [],
};

const getGameType = (type: number): string => {
    switch (type) {
        case gameDictionary.GAME_TYPE_BEST_AT_TABLE:
            return 'bestAtTable';
        case gameDictionary.GAME_TYPE_CHECKPOINT:
            return 'checkpoint';
        case gameDictionary.GAME_TYPE_MONTH_PRIZE:
            return 'monthPrize';
        case gameDictionary.GAME_TYPE_DAILY:
            return 'daily';
        case gameDictionary.GAME_TYPE_JACKPOT:
            return 'jackpot';
        case gameDictionary.GAME_TYPE_ONE_ON_ONE:
            return 'oneOnOne';
        case gameDictionary.GAME_TYPE_CHANCE:
            return 'chance';
        default:
            return '';
    }
};

const reducer: Reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GAMES_TYPES_SUCCESS: {
            const type = getGameType(action.payload.type);

            if (type === 'oneOnOne') {
                action.payload.games.data.filter(
                    (item) => item.tickets.length === 2 && (item.tickets = sort(item.tickets, 'createDate')),
                );
            }
            if (type === 'jackpot') {
                action.payload.games.data = action.payload.games.data.map((item) =>
                    item.tickets && item.tickets.length > 0
                        ? {
                              ...item,
                              tickets: item.tickets.map((ticket) => {
                                  return {
                                      ...ticket,
                                      price: changeBalance(ticket.price),
                                  };
                              }),
                          }
                        : item,
                );
            }

            if (action.payload.isUpdate) {
                const gameData = {
                    ...action.payload.games.data[0],
                    ticketPrice: changeBalance(action.payload.games.data[0].ticketPrice),
                };
                return {
                    ...state,
                    [type]: {
                        ...state[type],
                        games: {
                            ...action.payload.games,
                            data: state[type].games.data.map((item) =>
                                item.id === gameData.id ? { ...item, ...gameData } : item,
                            ),
                        },
                    },
                };
            }
            return {
                ...state,
                [type]: {
                    ...state[type],
                    games: {
                        ...action.payload.games,
                        data: changeBalance(action.payload.games.data, false, 'ticketPrice'),
                    },
                    gamesLoaded: true,
                },
            };
        }

        case ActionTypes.GAME_TYPE_SUCCESS: {
            const type = getGameType(action.payload.type);

            return {
                ...state,
                [type]: {
                    ...state[type],
                    game: {
                        ...state[type].game,
                        ...action.payload.game,
                        ticketPrice: changeBalance(action.payload.game.ticketPrice, false),
                    },
                    gameLoaded: true,
                },
            };
        }

        case ActionTypes.CREATE_GAME_TYPE_SUCCESS: {
            const type = getGameType(action.payload.type);

            return {
                ...state,
                [type]: {
                    ...state[type],
                    games: {
                        ...state[type].games,
                        data: [
                            ...state[type].games.data,
                            {
                                ...action.payload.game,
                                ticketPrice: changeBalance(action.payload.game.ticketPrice, false),
                            },
                        ],
                    },
                },
            };
        }

        case ActionTypes.GAME_TYPE_RESET: {
            const type = getGameType(action.payload.type);

            return {
                ...state,
                [type]: {
                    ...state[type],
                    game: initialState[type].game,
                    gameLoaded: false,
                },
            };
        }

        case ActionTypes.TICKETS_SUCCESS: {
            const type = getGameType(action.payload.type);

            return {
                ...state,
                [type]: {
                    ...state[type],
                    game: {
                        ...state[type].game,
                        tickets: changeBalance(action.payload.tickets.data, false, 'price'),
                    },
                    ticketsLoaded: true,
                },
            };
        }

        case ActionTypes.MONTH_TICKETS_SUCCESS: {
            const type = getGameType(action.payload.type);

            return {
                ...state,
                [type]: {
                    ...state[type],
                    tickets: changeBalance(action.payload.tickets.data, false, 'price'),
                    ticketsLoaded: true,
                },
            };
        }

        case ActionTypes.TICKET_POINT_SUCCESS: {
            const type = getGameType(action.payload.type);
            const lastCheckpointTicket = action.payload.point.lastCheckpointTicket;

            return {
                ...state,
                [type]: {
                    ...state[type],
                    point: {
                        ...action.payload.point,
                        lastCheckpointTicket: lastCheckpointTicket
                            ? {
                                  ...lastCheckpointTicket,
                                  price: changeBalance(action.payload.point.lastCheckpointTicket.price, false),
                              }
                            : null,
                    },
                    pointLoaded: true,
                },
            };
        }

        // Tickets
        case ActionTypes.CREATE_TICKET_SUCCESS: {
            const type = getGameType(action.payload.type);

            // Create ticket - CHECKPOINT
            if (action.payload.type === gameDictionary.GAME_TYPE_CHECKPOINT) {
                return {
                    ...state,
                    [type]: {
                        ...state[type],
                        point: {
                            ...state[type].point,
                            canBetOnCheckpoint: false,
                            lastCheckpointTicket: {
                                ...action.payload.ticket,
                                price: changeBalance(action.payload.ticket.price, false),
                            },
                        },
                    },
                };
            }

            // Create ticket - DAILY || JACKPOT
            if (
                action.payload.type === gameDictionary.GAME_TYPE_DAILY ||
                action.payload.type === gameDictionary.GAME_TYPE_JACKPOT
            ) {
                const ticket = action.payload.ticket;

                return {
                    ...state,
                    [type]: {
                        ...state[type],
                        games: {
                            ...state[type].games,
                            data: state[type].games.data.map((item) => (item.id === ticket.game.id ? { ...item, ticket } : item)),
                        },
                    },
                };
            }

            if (action.payload.type === gameDictionary.GAME_TYPE_MONTH_PRIZE) {
                const ticket = action.payload.ticket;

                return {
                    ...state,
                    [type]: {
                        ...state[type],
                        tickets: [
                            ...state[type].tickets,
                            {
                                ...ticket,
                                price: changeBalance(ticket.price, false),
                            },
                        ],
                    },
                };
            }

            if (action.payload.type === gameDictionary.GAME_TYPE_CHANCE) {
                const ticket = action.payload.ticket;

                return {
                    ...state,
                    [type]: {
                        ...state[type],
                        games: {
                            ...state[type].games,
                            data: state[type].games.data.map((item) =>
                                item.id === ticket.chance.id ? { ...item, tickets: [...item.tickets, ticket] } : item,
                            ),
                        },
                    },
                };
            }

            // Create ticket - default
            return {
                ...state,
                [type]: {
                    ...state[type],
                    game: {
                        ...state[type].game,
                        tickets: [
                            ...state[type].game.tickets,
                            {
                                ...action.payload.ticket,
                                price: changeBalance(action.payload.ticket.price, false),
                            },
                        ],
                    },
                },
            };
        }

        case ActionTypes.CHANGE_PRICE_TICKET: {
            const type = getGameType(action.payload.type);
            return {
                ...state,
                [type]: {
                    ...state[type],
                    games: {
                        ...state[type].games,
                        data: state[type].games.data.map((item) =>
                            item.id === action.payload.values.gameId
                                ? { ...item, ticketPrice: changeBalance(action.payload.values.price, false) }
                                : item,
                        ),
                    },
                },
            };
        }

        case ActionTypes.SET_PRICE_CHANGE_REQUEST: {
            if (action.payload.type === 'clear') {
                return {
                    ...state,
                    priceChangeRequests: [],
                };
            }

            if (state.priceChangeRequests.find(({ id }) => id === action.payload.id)) return state;

            return {
                ...state,
                priceChangeRequests: [...state.priceChangeRequests, action.payload],
            };
        }

        // Chances
        case ActionTypes.CHANCES_SUCCESS: {
            return {
                ...state,
                chance: {
                    ...state.chance,
                    games: {
                        ...state.chance.games,
                        data: changeBalance(action.payload.data, false, 'ticketPrice'),
                        totalCount: action.payload.totalCount,
                        pageNumber: action.payload.pageNumber,
                        amountPerPage: action.payload.amountPerPage,
                    },
                    chancesLoaded: true,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
