import { jwtDecode } from 'jwt-decode';
import React from 'react';
import { BalanceOutStatusEnum } from 'src/common/provider-common-type-util/dictionary/balance';
import { getAuthToken } from 'src/utils/cookies';
import { formatUserTimeZone } from 'src/utils/helpers';

export const premisionLevel = () => {
    const token = getAuthToken();
    if (!token) return 0;
    const decodedToken: { jwtPayloadData: { permissionLevel: number } } = jwtDecode(token);
    const { permissionLevel } = decodedToken.jwtPayloadData;
    return permissionLevel;
};

export default (item, { _styles, handlers, translate }) => (
    <tr key={item.id}>
        <td style={{ ..._styles[0] }}>{item.id}</td>
        <td style={{ ..._styles[1] }}>{item.target.login}</td>
        <td style={{ ..._styles[2] }}>{item.amount ? (item.amount / 100).toFixed(2) : 0}</td>
        <td style={{ ..._styles[3] }}>{item.targetCurrentBalance ? (item.targetCurrentBalance / 100).toFixed(2) : 0}</td>
        <td style={{ ..._styles[3] }}>
            {item.status
                ? BalanceOutStatusEnum[item.status]?.split('BALANCE_OUT_STATUS_')[1] ?? BalanceOutStatusEnum[item.status]
                : '-'}
        </td>
        <td style={{ ..._styles[4] }}>{formatUserTimeZone(item.createDate).split('T')[0]}</td>
        <td
            style={{ ..._styles[4] }}
            className="btn-link-styles"
            data-user={`${item.target.id}::${item.target.login}::${item.status}::${item.reason}::${item.id}`}
            onClick={handlers.userBalanceConfirmAction}
        >
            {translate('Edit')}
        </td>
    </tr>
);
