import { AxiosResponse } from 'axios';
import qs from 'qs';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { PermissionEnum } from 'src/common/provider-common-type-util/dictionary/permission';

import { api } from '../../api';
import { changeBalance } from '../../helpers';
import { IAction } from '../../interfaces/IAction';
import { IRequestTransactionBalance, IResponseBalance } from '../../interfaces/IBalance';
import { IRequestComment, IComment } from '../../interfaces/IComment';
import { ILoginHistory, IRequestLoginHistory } from '../../interfaces/ILoginHistory';
import {
    IProfileWithChildren,
    IUserRequestCreate,
    IRequestBanUser,
    IRequestCashback,
    IRequestChangePassword,
    IRequestProviderMaxLimit,
    IRequestProfit,
} from '../../interfaces/IManageUsers';
import { IProfile } from '../../interfaces/IProfile';
import {
    IRequestGetScedualPayment,
    IScedualPaymentGet,
    IRequestCreateScedualPayment,
    IScedualPayment,
    IRequestDeleteScedualPayment,
} from '../../interfaces/IScedualPayment';
import { IRequestUserStatistic, IStatisticGet, IProviderStatisticGet } from '../../interfaces/IStatistics';
import { loginRequest, userEdit } from '../auth/actions';
import { chancesSuccess, createTicketSuccess } from '../gamesTypes/actions';
import manageHandlers from '../manageHandlers/actions';

import {
    manageUsersSuccess,
    createUserSuccess,
    createUserJoinSuccess,
    userBanSuccess,
    userBalanceSuccess,
    cashbackSuccess,
    profitSuccess,
    changePasswordSuccess,
    statisticsSuccess,
    commentSuccess,
    providerMaxLimitSuccess,
    providerStatisticsSuccess,
    scedualPaymentSuccess,
    loginHistorySuccess,
    scheludeSuccess,
    balancesSuccess,
    settingsSuccess,
    bettingSuccess,
} from './actions';
import { ActionTypes } from './types';

interface ILoginPayload {
    username: string;
    password: string;
}

function* manageUsersRequest() {
    try {
        const response: AxiosResponse<IProfileWithChildren[]> = yield api.get(`/tree/children`);
        if (response?.data) {
            yield put(manageUsersSuccess(response.data));
        }
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* statisticsRequest(action: IAction<IRequestUserStatistic>) {
    const { typeRequest, ...remainOptions } = action.payload.getParams;
    try {
        const parsedParams: string = qs.stringify({
            ...remainOptions,
            amountPerPage: 20,
        });

        const res: AxiosResponse = yield api.get(`/statistic/${typeRequest}?${parsedParams}`);
        // eslint-disable-next-line prefer-destructuring
        const data: IStatisticGet = res?.data;
        if (!data) return;
        yield put(statisticsSuccess(data));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* bettingRequest(action: IAction<IRequestUserStatistic>) {
    const { ...remainoptions } = action.payload.getParams;
    const link = 'provider';
    try {
        const parsedParams: string = qs.stringify({
            ...remainoptions,
            amountPerPage: 20,
        });

        const res: AxiosResponse = yield api.get(`/statistic/${link}?${parsedParams}`);
        // eslint-disable-next-line prefer-destructuring

        const data: IStatisticGet = res?.data;
        if (!data) return;
        yield put(bettingSuccess(data));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* balancesRequest(action: IAction<any>) {
    try {
        const parsedParams: string = qs.stringify({
            ...action.payload.getParams,
            amountPerPage: 20,
        });
        const res: AxiosResponse = yield api.get(`/balance/out?${parsedParams}`);
        // eslint-disable-next-line prefer-destructuring
        const data = res?.data;

        if (!data) return;

        yield put(balancesSuccess(data));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* settingsRequest(action: IAction<any>) {
    try {
        const parsedParams: string = qs.stringify({
            ...action.payload.getParams,
            amountPerPage: 20,
        });
        const res: AxiosResponse = yield api.get(`/setting/bundle?${parsedParams}`);
        // eslint-disable-next-line prefer-destructuring
        const data = res?.data;

        if (!data) return;
        yield put(settingsSuccess(data));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* providerStatisticsRequest(action: IAction<IRequestUserStatistic>) {
    try {
        const parsedParams: string = qs.stringify(action.payload.getParams);
        const { data }: AxiosResponse<IProviderStatisticGet[]> = yield api.get(`/statistic/provider?${parsedParams}`);

        yield put(providerStatisticsSuccess(data));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* createUserRequest(action: IAction<IUserRequestCreate>) {
    try {
        const response: AxiosResponse<IProfile> = yield api.post(`/user/create`, action.payload);
        yield put(createUserSuccess(response.data));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* createUserJoinRequest(action: IAction<IUserRequestCreate>) {
    try {
        yield put(manageHandlers.handleRequest({}));
        const response: AxiosResponse<IProfile> = yield api.post(`/user/signup/code`, action.payload);
        yield put(createUserJoinSuccess(response.data));

        const payload: ILoginPayload = {
            username: action.payload.login,
            password: action.payload.password,
        };

        yield put(loginRequest(payload));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* addBalanceRequest(action: IAction<IRequestTransactionBalance>) {
    try {
        const { data }: AxiosResponse<IResponseBalance> = yield api.post(`/balance/transfer`, {
            ...action.payload,
            amount: changeBalance(action.payload.amount, true),
        });

        yield put(
            userBalanceSuccess({
                target: data.target,
                creator: data.creator,
            }),
        );

        if (data.creator.permissionLevel !== PermissionEnum.USER_ADMIN_PERMISSION_LEVEL) {
            yield put(
                userEdit({
                    balance: changeBalance(data.creator.balance),
                    scedualPaymentBalance: changeBalance(data.creator.scedualPaymentBalance),
                }),
            );
        }

        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* takeBalanceRequest(action: IAction<IRequestTransactionBalance>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const { data }: AxiosResponse<IResponseBalance> = yield api.post(`/balance/take`, {
            ...action.payload,
            amount: changeBalance(action.payload.amount, true),
        });

        yield put(
            userBalanceSuccess({
                target: data.target,
                creator: data.creator,
            }),
        );

        if (data.creator.permissionLevel !== PermissionEnum.USER_ADMIN_PERMISSION_LEVEL) {
            yield put(
                userEdit({
                    balance: changeBalance(data.creator.balance),
                    scedualPaymentBalance: changeBalance(data.creator.scedualPaymentBalance),
                }),
            );
        }

        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* userBanRequest(action: IAction<IRequestBanUser>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const response: AxiosResponse<IProfile> = yield api.post(`/user/update/ban`, action.payload);

        yield put(userBanSuccess(response.data));
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* cashbackRequest(action: IAction<IRequestCashback>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const response: AxiosResponse<IProfile> = yield api.post(`/user/cashback`, action.payload);

        yield put(cashbackSuccess(response.data));
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* profitRequest(action: IAction<IRequestProfit>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const response: AxiosResponse<IProfile> = yield api.post(`/user/update/profit`, action.payload);

        yield put(profitSuccess(response.data));
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* scheludeRequest(action: IAction<any>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const response = yield api.post(`/user/update/schedule`, action.payload);

        yield put(scheludeSuccess(response.data));
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* providerMaxLimitRequest(action: IAction<IRequestProviderMaxLimit>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const response: AxiosResponse<IProfile> = yield api.post(`/user/changeProviderMaxLimit`, {
            ...action.payload,
            providerMaxLimit: changeBalance(action.payload.providerMaxLimit, true),
        });

        yield put(providerMaxLimitSuccess(response.data));
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* changePasswordRequest(action: IAction<IRequestChangePassword>) {
    try {
        yield put(manageHandlers.handleRequest({}));
        yield api.post(`/user/password/change`, action.payload);
        yield put(changePasswordSuccess());
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* commentRequest(action: IAction<IRequestComment>) {
    const parsedParams: string = qs.stringify({
        ...action.payload.getParams,
    });

    try {
        const response: AxiosResponse<IComment> = yield api.get(`/comment?${parsedParams}`);
        yield put(commentSuccess(response.data));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* changeCommentRequest(action: IAction<IRequestComment>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const response: AxiosResponse<IComment> = yield api.post(`/comment/create`, action.payload);

        yield put(commentSuccess(response.data));
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* scedualPaymentRequest(action: IAction<IRequestGetScedualPayment>) {
    const parsedParams: string = qs.stringify({
        ...action.payload,
    });

    try {
        const {
            data: { data },
        }: AxiosResponse<IScedualPaymentGet> = yield api.get(`/scedual-payment?${parsedParams.replace('userId', 'target')}`);
        const [payment] = data;

        yield put(scedualPaymentSuccess(payment || ({} as IScedualPayment)));
    } catch (err) {
        // yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* setScedualPaymentRequest(action: IAction<IRequestCreateScedualPayment>) {
    try {
        const response: AxiosResponse<IScedualPayment> = yield api.post(`/scedual-payment/create`, {
            ...action.payload,
            amount: changeBalance(action.payload.amount, true),
        });

        yield put(scedualPaymentSuccess(response.data));
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        // yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* updateStatusDocuments(action: IAction<any>) {
    try {
        yield api.post(`/document/update`, {
            ...action.payload,
        });

        yield put(manageHandlers.handleSuccess({}));
        yield manageUsersRequest();
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}
function* deleteScedualPaymentRequest(action: IAction<IRequestDeleteScedualPayment>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const response: AxiosResponse<IScedualPayment> = yield api.post(`/scedual-payment/delete`, action.payload);

        yield put(scedualPaymentSuccess(response.data));
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* getLoginHistory(action: IAction<IRequestLoginHistory>) {
    try {
        yield put(manageHandlers.handleRequest({}));
        const parsedParams: string = qs.stringify({
            ...action.payload.getParams,
            amountPerPage: 20,
        });

        const response: AxiosResponse<ILoginHistory> = yield api.get(`/auth/?${parsedParams.replace('id', 'target')}`);
        yield put(loginHistorySuccess(response.data));
        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* chancesRequest(action: IAction<any>) {
    try {
        const parsedParams = qs.stringify({
            amountPerPage: 20,
            ...action.payload,
        });

        const { data } = yield api.get(`/chance?${parsedParams}`);
        yield put(chancesSuccess(data));
    } catch (err) {}
}

function* createTicketRequest(action: IAction<any>) {
    try {
        yield put(manageHandlers.handleRequest({}));

        const { data } = yield api.post(`/ticket/create`, {
            ...action.payload.values,
            price: action.payload.values.price ? changeBalance(action.payload.values.price, true) : undefined,
        });

        yield put(createTicketSuccess({ ticket: data, type: action.payload.type }));
        yield put(
            userEdit({
                balance: changeBalance(data.player.balance),
                scedualPaymentBalance: changeBalance(data.player.scedualPaymentBalance),
            }),
        );

        yield put(manageHandlers.handleSuccess({}));
    } catch (err) {
        // yield put(manageHandlers.handleError({ message: err.response.data.message }));
    }
}

function* watchRequests() {
    yield takeEvery(ActionTypes.MANAGE_USERS_REQUEST, manageUsersRequest);
    yield takeEvery(ActionTypes.STATISTICS_REQUEST, statisticsRequest);
    yield takeEvery(ActionTypes.BETTING_REQUEST, bettingRequest);
    yield takeEvery(ActionTypes.PROVIDER_STATISTICS_REQUEST, providerStatisticsRequest);
    yield takeEvery(ActionTypes.CREATE_USER_REQUEST, createUserRequest);
    yield takeEvery(ActionTypes.CREATE_USER_JOIN_REQUEST, createUserJoinRequest);
    yield takeEvery(ActionTypes.ADD_BALANCE_REQUEST, addBalanceRequest);
    yield takeEvery(ActionTypes.TAKE_BALANCE_REQUEST, takeBalanceRequest);
    yield takeEvery(ActionTypes.USER_BAN_REQUEST, userBanRequest);
    yield takeEvery(ActionTypes.CASHBACK_REQUEST, cashbackRequest);
    yield takeEvery(ActionTypes.PROFIT_REQUEST, profitRequest);
    yield takeEvery(ActionTypes.SCHELUDE_REQUEST, scheludeRequest);
    yield takeEvery(ActionTypes.PROVIDER_MAX_LIMIT_REQUEST, providerMaxLimitRequest);
    yield takeEvery(ActionTypes.CHANGE_PASSWORD_REQUEST, changePasswordRequest);
    yield takeEvery(ActionTypes.GET_COMMENT_REQUEST, commentRequest);
    yield takeEvery(ActionTypes.CHANGE_COMMENT_REQUEST, changeCommentRequest);
    yield takeEvery(ActionTypes.SCEDUAL_PAYMENT_REQUEST, scedualPaymentRequest);
    yield takeEvery(ActionTypes.SET_SCEDUAL_PAYMENT_REQUEST, setScedualPaymentRequest);
    yield takeEvery(ActionTypes.DELETE_SCEDUAL_PAYMENT_REQUEST, deleteScedualPaymentRequest);
    yield takeEvery(ActionTypes.GET_LOGIN_HISTORY_REQUEST, getLoginHistory);
    yield takeEvery(ActionTypes.UPDATE_STATUS_DOCUMENT, updateStatusDocuments);
    yield takeEvery(ActionTypes.BALANCES_REQUEST, balancesRequest);
    yield takeEvery(ActionTypes.CHANCES_REQUEST, chancesRequest);
    yield takeEvery(ActionTypes.CREATE_TICKET_REQUEST, createTicketRequest);
    yield takeEvery(ActionTypes.SETTINGS_REQUEST, settingsRequest);
}

function* rootSaga() {
    yield all([fork(watchRequests)]);
}

export default rootSaga;
