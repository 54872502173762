// //
// //
// export const balanceDictionary = {
//     // Balance type
//     BALANCE_TYPE_TRANSFER: 1,
//     BALANCE_TYPE_TAKE: 2,
//     BALANCE_TYPE_REDUCE: 3,
//     BALANCE_TYPE_ADD: 4,
//     BALANCE_TYPE_CASHBACK: 5,
//     BALANCE_TYPE_REFUND: 6,
//     BALANCE_TYPE_SCEDUAL_TRANSFER: 8,
//     BALANCE_TYPE_SCEDUAL_TAKE: 9,
//     BALANCE_TYPE_SCEDUAL_REDUCE: 10,
//     BALANCE_TYPE_EVOLUTION_DEBIT: 11,
//     BALANCE_TYPE_EVOLUTION_CREDIT: 12,
//     BALANCE_TYPE_EVOLUTION_CANCEL: 13,
//     BALANCE_TYPE_EVOLUTION_SCEDUAL_DEBIT: 14,
//     BALANCE_TYPE_EVOLUTION_SCEDUAL_CANCEL: 15,
//     BALANCE_TYPE_BS_BET: 16,
//     BALANCE_TYPE_BS_WIN: 17,
//     BALANCE_TYPE_BS_CANCEL: 18,
//     BALANCE_TYPE_BS_SCEDUAL_BET: 19,
//     BALANCE_TYPE_BS_SCEDUAL_CANCEL: 20,
//     BALANCE_TYPE_SLOTS_DEBIT: 21,
//     BALANCE_TYPE_SLOTS_CREDIT: 22,
//     BALANCE_TYPE_SLOTS_CANCEL: 23,
//     BALANCE_TYPE_SLOTS_SCEDUAL_DEBIT: 24,
//     BALANCE_TYPE_SLOTS_SCEDUAL_CANCEL: 25,
//     BALANCE_TYPE_NOVO_BET: 26,
//     BALANCE_TYPE_NOVO_WIN: 27,
//     BALANCE_TYPE_NOVO_ROLLBACK: 28,
//     BALANCE_TYPE_NOVO_JACKPOT: 29,
//     BALANCE_TYPE_NOVO_SCEDUAL_BET: 30,
//     BALANCE_TYPE_NOVO_SCEDUAL_ROLLBACK: 31,
//     BALANCE_TYPE_TVBET_BET: 32,
//     BALANCE_TYPE_TVBET_WIN: 33,
//     BALANCE_TYPE_TVBET_ROLLBACK: 34,
//     BALANCE_TYPE_TVBET_JACKPOT: 35,
//     BALANCE_TYPE_TVBET_SCEDUAL_BET: 36,
//     BALANCE_TYPE_TVBET_SCEDUAL_ROLLBACK: 37,
//     BALANCE_TYPE_TGG_BET: 38,
//     BALANCE_TYPE_TGG_WIN: 39,
//     BALANCE_TYPE_TGG_ROLLBACK: 40,
//     BALANCE_TYPE_TGG_SCEDUAL_BET: 41,
//     BALANCE_TYPE_TGG_SCEDUAL_ROLLBACK: 42,
//     BALANCE_TYPE_NUXAPI_DEBIT: 43,
//     BALANCE_TYPE_NUXAPI_CREDIT: 44,
//     BALANCE_TYPE_NUXAPI_SCEDUAL_DEBIT: 45,
//     BALANCE_TYPE_NUXAPI_ROLLBACK: 46,
//     BALANCE_TYPE_NUXAPI_TIP: 47,
//     BALANCE_TYPE_NUXAPI_SCEDUAL_TIP: 48,
//     BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE: 49,
// };

export const gameDictionary = {
    // Game status
    GAME_STATUS_CREATED: 1,
    GAME_STATUS_STARTED: 2,
    GAME_STATUS_ENDED: 3,
    GAME_STATUS_FAILED: 4,
    GAME_STATUS_DELETED: 5,

    // Name Game
    GAME_TYPE_BEST_AT_TABLE_NAME: 'Best a table',
    GAME_TYPE_CHECKPOINT_NAME: 'Checkpoint',
    GAME_TYPE_ONE_ON_ONE_NAME: 'One on One',
    GAME_TYPE_MONTH_PRIZE_NAME: 'Month prize',
    GAME_TYPE_DAILY_NAME: 'Daily',
    GAME_TYPE_JACKPOT_NAME: 'Jackpot',

    // Game type
    GAME_TYPE_BEST_AT_TABLE: 1,
    GAME_TYPE_CHECKPOINT: 2,
    GAME_TYPE_ONE_ON_ONE: 3,
    GAME_TYPE_MONTH_PRIZE: 4,
    GAME_TYPE_DAILY: 5,
    GAME_TYPE_JACKPOT: 6,
    GAME_TYPE_CASINO: 7,
    GAME_TYPE_CHANCE: 8,

    // checkpoint point
    GAME_CHECKPOINT_FIRST: 1,
    GAME_CHECKPOINT_SECOND: 2,
    GAME_CHECKPOINT_THIRD: 3,

    // Failed status
    GAME_STATUS_NOT_ENOUGH_PLAYERS: 1,
    GAME_STATUS_NOT_ENOUGH_MATCHES: 2,
    GAME_STATUS_NOT_ENOUGH_PLAYERS_AND_MATCHES: 3,

    // Default value
    GAME_DEFAULT_CASHBACK_PERCENT: 10,
};
//
// export const matchDictionary = {
//     // Match status
//     MATCH_STATUS_CREATED: 1,
//     MATCH_STATUS_STARTED: 2,
//     MATCH_STATUS_ENDED: 3,
//     MATCH_STATUS_DELETED: 4,
// };

// export const ticketDictionary = {
//     // Ticket team
//     TICKET_TEAM_FIRST: 1,
//     TICKET_TEAM_SECOND: 2,
//     TICKET_TEAM_DRAW: 3,
//
//     // Ticket status
//     TICKET_STATUS_ACTIVE: 1,
//     TICKET_STATUS_WON: 2,
//     TICKET_STATUS_LOST: 3,
// };

// export const ScedualPaymentDictionary = {
//     // Scedual Payment Status
//     SCEDUAL_PAYMENT_STATUS_CREATED: 1,
//     SCEDUAL_PAYMENT_STATUS_PAID: 2,
//     SCEDUAL_PAYMENT_STATUS_DELETED: 3,
// };
//
// export const LogoDictionary = {
//     // Logo status
//     LOGO_STATUS_CREATED: 1,
//     LOGO_STATUS_DELETED: 2,
// };
//
export const TickerDictionary = {
    // Ticker type
    TICKER_TYPE_BETTING: 1,
    TICKER_TYPE_GAMBLING: 2,
};
//
export const GameTypeDictionary = {
    GAME_TYPE_BEST_AT_TABLE: 1,
    GAME_TYPE_CHECKPOINT: 2,
    GAME_TYPE_ONE_ON_ONE: 3,
    GAME_TYPE_MONTH_PRIZE: 4,
    GAME_TYPE_DAILY: 5,
    GAME_TYPE_JACKPOT: 6,
    GAME_TYPE_CASINO: 7,
    GAME_TYPE_CHANCE: 8,
};
//
// export const ChanceDictionary = {
//     // Chance bet type
//     CHANCE_BET_TYPE_ACCURATE_PREDICTION: 1,
//     CHANCE_BET_TYPE_PARITY_PREDICTION: 2,
//     CHANCE_BET_TYPE_MORE_LESS_PREDICTION: 3,
//
//     // Chance parity prediction
//     CHANCE_PARITY_PREDICTION_ODD: 'odd',
//     CHANCE_PARITY_PREDICTION_EVEN: 'even',
//
//     // Chance more-less prediction
//     CHANCE_MORE_LESS_PREDICTION_LESS: 'less',
//     CHANCE_MORE_LESS_PREDICTION_MORE: 'more',
//     CHANCE_MORE_LESS_PREDICTION_COMPARED_VALUE: 42,
//
//     // Ratio
//     CHANCE_BET_TYPE_ACCURATE_PREDICTION_RATIO_SIMPLE: 8,
//     CHANCE_BET_TYPE_ACCURATE_PREDICTION_RATIO_ALL: 4096,
//     CHANCE_BET_TYPE_PARITY_PREDICTION_RATIO: 2,
//     CHANCE_BET_TYPE_MORE_LESS_PREDICTION_RATIO: 2,
//
//     CHANCE_BET_TYPE_ONE: 7,
//     CHANCE_BET_TYPE_TWO: 35,
//     CHANCE_BET_TYPE_THREE: 350,
//     CHANCE_BET_TYPE_FOUR: 2000,
// };
