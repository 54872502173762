import React from 'react';
import { PermissionEnum } from 'src/common/provider-common-type-util/dictionary/permission';

import Button from '../../components/Button';
import { IProfile } from '../../interfaces/IProfile';

interface ICreateUserBtnsProps {
    user: IProfile;
    createUserModal(e: React.MouseEvent<HTMLElement>): void;
    translate(text: string): string;
}

const CreateUserBtns: React.FC<ICreateUserBtnsProps> = (props) => {
    const isMobile = window.innerWidth <= 900;

    return (
        <div className="add-content__btns-top" style={{ gap: '5px' }}>
            {PermissionEnum.USER_ADMIN_PERMISSION_LEVEL <= props.user.permissionLevel && (
                <Button
                    className="btn-small gold-btn"
                    style={isMobile ? { padding: '0px !important', fontSize: '10px' } : { padding: '0px !important' }}
                    data-permissionlevel="4"
                    onClick={props.createUserModal}
                >
                    {props.translate('Add Player')}
                </Button>
            )}
            {PermissionEnum.USER_ADMIN_PERMISSION_LEVEL <= props.user.permissionLevel && (
                <Button
                    style={isMobile ? { fontSize: '10px' } : {}}
                    className="btn-small gold-btn"
                    data-permissionlevel="1"
                    onClick={props.createUserModal}
                >
                    {props.translate('Add User')}
                </Button>
            )}
        </div>
    );
};

export default CreateUserBtns;
