import { action } from 'typesafe-actions';

import { IAction } from '../../interfaces/IAction';
import {
    IRequestParams,
    ILogoLeagueRequestParams,
    IResponseParams,
    ILogo,
    ICreateLogoRequest,
    IUpdateLogoRequest,
    IDeleteLogoRequest,
} from '../../interfaces/IGames';

import { ActionTypes } from './types';

export const logosTeamRequest = (data: IAction<IRequestParams>) => action(ActionTypes.LOGOS_TEAM_REQUEST, data);
export const logosTeamSuccess = (data: IResponseParams<ILogo[]>) => action(ActionTypes.LOGOS_TEAM_SUCCESS, data);

export const logosLeagueRequest = (data: IAction<ILogoLeagueRequestParams>) => action(ActionTypes.LOGOS_LEAGUE_REQUEST, data);
export const logosLeagueSuccess = (data: IResponseParams<ILogo[]>) => action(ActionTypes.LOGOS_LEAGUE_SUCCESS, data);

export const createLogoRequest = (data: IAction<ICreateLogoRequest>) => action(ActionTypes.CREATE_LOGO_REQUEST, data);
export const createLogoSuccess = (data: { logo: any; type: any }) => action(ActionTypes.CREATE_LOGO_SUCCESS, data);

export const updateLogoRequest = (data: IAction<IUpdateLogoRequest>) => action(ActionTypes.UPDATE_LOGO_REQUEST, data);
export const updateLogoSuccess = (data: { logo: any; type: any; updateType: string }) =>
    action(ActionTypes.UPDATE_LOGO_SUCCESS, data);

export const deleteLogoRequest = (data: IAction<IDeleteLogoRequest>) => action(ActionTypes.DELETE_LOGO_REQUEST, data);
export const deleteLogoSuccess = (data: any) => action(ActionTypes.DELETE_LOGO_SUCCESS, data);
