import { Reducer } from 'redux';
import { PermissionEnum } from 'src/common/provider-common-type-util/dictionary/permission';

import { userTree, changeBalance } from '../../helpers';
import { IComment } from '../../interfaces/IComment';
import { IProfileWithChildren } from '../../interfaces/IManageUsers';
import { IScedualPayment } from '../../interfaces/IScedualPayment';
import { IProfileWithChildrenAndStatistics } from '../../interfaces/IStatistics';

import { ActionTypes } from './types';

interface IInitialState {
    userTree: IProfileWithChildren[];
    allUsers: IProfileWithChildren[];
    totalTableState: ITotalTableState;
    statistics: {
        balanceHistory: [];
    };
    betting: [];
    settings: {
        data: [];
        amountPerPage: number;
        pageNumber: number;
        totalCount: number;
    };
    balances: {
        data: [];
        amountPerPage: number;
        pageNumber: number;
        totalCount: number;
    };
    filterReport: {
        date: null | Date;
        provider: null | number;
    };

    filterTransaction: {
        date: null | Date;
        provider: null | number;
    };

    comment: IComment | {};
    scedualPayment: IScedualPayment | {};
}

export interface ITotalTableState {
    totalBetCount: number;
    totalCanceledBet: number;
    totalDebitAmount: number;
    totalWinAmount: number;
    totalProfitAmount: number;
    totalAgentProfitAmount: number;
    totalMasterProfitAmount: number;
    totalSystemCommission: number;
}

export const initialState: IInitialState = {
    userTree: [],
    allUsers: [],
    totalTableState: {} as ITotalTableState,
    settings: {
        data: [],
        amountPerPage: 20,
        pageNumber: 1,
        totalCount: 0,
    },
    filterReport: {
        date: null,
        provider: null,
    },
    filterTransaction: {
        date: null,
        provider: null,
    },
    balances: {
        data: [],
        amountPerPage: 20,
        pageNumber: 1,
        totalCount: 0,
    },
    betting: [],
    statistics: {
        balanceHistory: [],
    },
    comment: {},
    scedualPayment: {},
};

const reducer: Reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.MANAGE_USERS_SUCCESS: {
            const newPayload = changeBalance(action.payload, false, [
                'balance',
                'scedualPaymentBalance',
                'cashbackBalance',
                'providerMaxLimit',
            ]);

            return {
                ...state,
                userTree: userTree(newPayload),
                allUsers: newPayload,
                usersLoaded: true,
            };
        }

        // User list expansion
        case ActionTypes.MANAGE_USERS_EXPANSION: {
            return {
                ...state,
                userTree: userTree(action.payload, true),
                allUsers: action.payload,
            };
        }

        case ActionTypes.FILTER_REPORT: {
            const currentDate = new Date();
            const previousDate = new Date(currentDate);
            previousDate.setDate(currentDate.getDate() - 1);
            return {
                ...state,
                filterReport: {
                    date: new Date(previousDate.setDate(currentDate.getDate() - 1)),
                    provider: action.payload,
                },
            };
        }
        case ActionTypes.FILTER_TRANSACTION: {
            return {
                ...state,
                filterTransaction: {
                    date: new Date(action.payload.date),
                    provider: action.payload.provider,
                },
            };
        }

        // Statistics list
        case ActionTypes.STATISTICS_SUCCESS: {
            return {
                ...state,
                statistics: {
                    ...action.payload,
                    balanceHistory: changeBalance(action.payload.balanceHistory, false, ['amount', 'targetCurrentBalance']),
                },
            };
        }

        case ActionTypes.BETTING_SUCCESS: {
            return {
                ...state,
                betting: action.payload,
            };
        }

        case ActionTypes.BALANCES_SUCCESS: {
            return {
                ...state,
                balances: { ...action.payload },
            };
        }

        case ActionTypes.SETTINGS_SUCCESS: {
            return {
                ...state,
                settings: { ...action.payload },
            };
        }

        case ActionTypes.SETTINGS_UPDATE: {
            return {
                ...state,
                settings: { ...action.payload },
            };
        }

        // Provider Statistics list
        case ActionTypes.PROVIDER_STATISTICS_SUCCESS: {
            const parents: IProfileWithChildrenAndStatistics[] = [];
            const subParents: IProfileWithChildrenAndStatistics[] = [];

            const totalTableState: ITotalTableState = {
                totalBetCount: 0,
                totalCanceledBet: 0,
                totalDebitAmount: 0,
                totalWinAmount: 0,
                totalProfitAmount: 0,
                totalAgentProfitAmount: 0,
                totalMasterProfitAmount: 0,
                totalSystemCommission: 0,
            };

            let users: IProfileWithChildrenAndStatistics[] = action.payload.map((item) => {
                item.user.betCount = item.betCount;
                item.user.canceledBet = item.canceledBet;
                item.user.debitAmount = changeBalance(item.debitAmount);
                item.user.winAmount = changeBalance(item.winAmount);
                item.user.profitAmount = changeBalance(item.profitAmount);
                item.user.agentProfitAmount = changeBalance(item.agentProfitAmount);
                item.user.masterProfitAmount = changeBalance(item.masterProfitAmount);
                item.user.systemCommission = item.user.profitAmount - item.user.agentProfitAmount - item.user.masterProfitAmount;

                totalTableState.totalBetCount += item.user.betCount;
                totalTableState.totalCanceledBet += item.user.canceledBet;
                totalTableState.totalDebitAmount += item.user.debitAmount;
                totalTableState.totalWinAmount += item.user.winAmount;
                totalTableState.totalProfitAmount += item.user.profitAmount;
                totalTableState.totalAgentProfitAmount += item.user.agentProfitAmount;
                totalTableState.totalMasterProfitAmount += item.user.masterProfitAmount;
                totalTableState.totalSystemCommission += item.user.systemCommission;

                if (
                    item.user.parent &&
                    (item.user.parent.permissionLevel === PermissionEnum.USER_MASTER_PERMISSION_LEVEL ||
                        (item.user.parent.permissionLevel === PermissionEnum.USER_AGENT_PERMISSION_LEVEL &&
                            item.user.parent.parent.permissionLevel === PermissionEnum.USER_ADMIN_PERMISSION_LEVEL))
                ) {
                    item.user._isShow = false;
                    item.user.children = [];
                    const existParent: IProfileWithChildrenAndStatistics | undefined = parents.find(
                        (parent) => parent.id === item.user.parent.id,
                    );

                    if (existParent) {
                        existParent.children.push(item.user);
                    } else {
                        const newParent: IProfileWithChildrenAndStatistics = { ...item.user.parent };
                        newParent.children = [];
                        newParent._isShow = true;
                        newParent.children.push(item.user);
                        parents.push(newParent);
                    }
                } else if (item.user.parent.permissionLevel === PermissionEnum.USER_AGENT_PERMISSION_LEVEL) {
                    item.user._isShow = false;
                    item.user.children = [];
                    const existSubParent: IProfileWithChildrenAndStatistics | undefined = subParents.find(
                        (parent) => parent.id === item.user.parent.id,
                    );

                    let newSubParent;

                    if (existSubParent) {
                        existSubParent.children.push(item.user);
                    } else {
                        newSubParent = { ...item.user.parent };
                        newSubParent.children = [];
                        newSubParent._isShow = false;
                        newSubParent.children.push(item.user);
                        subParents.push(newSubParent);
                    }

                    const existParent: IProfileWithChildrenAndStatistics | undefined = parents.find(
                        (parent) => parent.id === item.user.parent.parent.id,
                    );

                    if (existParent) {
                        const isExists: boolean = existParent.children.some((child) => {
                            const id: number = existSubParent ? existSubParent?.id : newSubParent.id;
                            return child.id === id;
                        });

                        if (!isExists) {
                            existParent.children.push(existSubParent || newSubParent);
                        } else {
                            existParent.children = existParent.children.map((child) => {
                                const id: number = existSubParent ? existSubParent?.id : newSubParent.id;
                                return child.id === id ? existSubParent || newSubParent : child;
                            });
                        }
                    } else {
                        const newParent: IProfileWithChildrenAndStatistics = { ...item.user.parent.parent };
                        newParent.children = [];
                        newParent._isShow = true;
                        newParent.children.push(newSubParent);
                        parents.push(newParent);
                    }
                } else {
                    item.user._isShow = true;
                    item.user.children = [];
                    parents.push(item.user);
                    return false;
                }

                return item.user;
            });

            users = users.filter((item) => item);

            parents.forEach((item) => {
                if (item.permissionLevel === PermissionEnum.USER_MASTER_PERMISSION_LEVEL) {
                    let masterProfit = 0;
                    let masterSummaryProfit = 0;
                    let masterSystemCommission = 0;

                    item.children.forEach((child) => {
                        if (child.permissionLevel === PermissionEnum.USER_AGENT_PERMISSION_LEVEL) {
                            let agentProfit = 0;
                            let agentSummaryProfit = 0;
                            let agentSystemCommission = 0;

                            child.children.forEach((grandChild) => {
                                agentProfit += grandChild.agentProfitAmount;
                                masterProfit += grandChild.masterProfitAmount;
                                agentSummaryProfit += grandChild.profitAmount;
                                masterSummaryProfit += grandChild.profitAmount;
                                agentSystemCommission += grandChild.systemCommission;
                                masterSystemCommission += grandChild.systemCommission;
                            });

                            child.profitAmount = agentProfit;
                            child.summaryProfitAmount = agentSummaryProfit;
                            child.systemCommission = agentSystemCommission;
                        } else {
                            masterProfit += child.masterProfitAmount;
                            masterSummaryProfit += child.profitAmount;
                            masterSystemCommission += child.systemCommission;
                        }
                    });

                    item.profitAmount = masterProfit;
                    item.summaryProfitAmount = masterSummaryProfit;
                    item.systemCommission = masterSystemCommission;
                } else if (item.permissionLevel === PermissionEnum.USER_AGENT_PERMISSION_LEVEL) {
                    let agentProfit = 0;
                    let agentSummaryProfit = 0;
                    let agentSystemCommission = 0;

                    item.children.forEach((child) => {
                        agentProfit += child.agentProfitAmount;
                        agentSummaryProfit += child.profitAmount;
                        agentSystemCommission += child.systemCommission;
                    });

                    item.profitAmount = agentProfit;
                    item.summaryProfitAmount = agentSummaryProfit;
                    item.systemCommission = agentSystemCommission;
                }
            });

            const allUsers: IProfileWithChildrenAndStatistics[] = parents.concat(subParents.concat(users));

            return {
                ...state,
                totalTableState,
                userTreeStatistics: changeBalance(parents),
                allUserStatistics: changeBalance(allUsers),
                usersStatisticsLoaded: true,
            };
        }

        case ActionTypes.PROVIDER_USERS_EXPANSION: {
            return {
                ...state,
                userTreeStatistics: userTree(action.payload, true),
                allUserStatistics: action.payload,
            };
        }

        // User create
        case ActionTypes.CREATE_USER_SUCCESS: {
            const newUser = {
                ...action.payload,
                _isShow: true,
                _isShowChildren: false,
            };

            return {
                ...state,
                userTree: userTree([...state.userTree, newUser], true),
                allUsers: [...state.allUsers, newUser],
            };
        }

        case ActionTypes.CREATE_USER_JOIN_SUCCESS: {
            const newUser = {
                ...action.payload,
                _isShow: true,
                _isShowChildren: false,
            };

            return {
                ...state,
                allUsers: [...state.allUsers, newUser],
            };
        }

        // User balance
        case ActionTypes.USER_BALANCE_SUCCESS: {
            const newData = state.allUsers.map((item) =>
                item.id === +action.payload.target.id
                    ? {
                          ...item,
                          balance: changeBalance(action.payload.target.balance),
                          scedualPaymentBalance: changeBalance(action.payload.target.scedualPaymentBalance),
                          providerMaxLimit: action.payload.target.providerMaxLimit
                              ? changeBalance(action.payload.target.providerMaxLimit)
                              : null,
                          cashbackBalance: changeBalance(action.payload.target.cashbackBalance),
                      }
                    : item,
            );

            return {
                ...state,
                userTree: userTree(newData, true),
                allUsers: newData,
            };
        }

        // User ban
        case ActionTypes.USER_BAN_SUCCESS: {
            const newData = state.allUsers.map((item) =>
                item.id === +action.payload.id
                    ? {
                          ...item,
                          status: action.payload.status,
                          balance: changeBalance(action.payload.balance),
                          scedualPaymentBalance: changeBalance(action.payload.scedualPaymentBalance),
                          providerMaxLimit: action.payload.providerMaxLimit
                              ? changeBalance(action.payload.providerMaxLimit)
                              : null,
                          cashbackBalance: changeBalance(action.payload.cashbackBalance),
                      }
                    : item,
            );

            return {
                ...state,
                userTree: userTree(newData, true),
                allUsers: newData,
            };
        }

        // User cashback
        case ActionTypes.CASHBACK_SUCCESS: {
            const newData = state.allUsers.map((item) =>
                item.id === action.payload.id
                    ? {
                          ...item,
                          ...action.payload,
                          balance: changeBalance(action.payload.balance),
                          scedualPaymentBalance: changeBalance(action.payload.scedualPaymentBalance),
                          providerMaxLimit: action.payload.providerMaxLimit
                              ? changeBalance(action.payload.providerMaxLimit)
                              : null,
                          cashbackBalance: changeBalance(action.payload.cashbackBalance),
                      }
                    : item,
            );

            return {
                ...state,
                userTree: userTree(newData, true),
                allUsers: newData,
            };
        }

        // User Profit
        case ActionTypes.PROFIT_SUCCESS: {
            const newData = state.allUsers.map((item) =>
                item.id === action.payload.id
                    ? {
                          ...item,
                          ...action.payload,
                          balance: changeBalance(action.payload.balance),
                          scedualPaymentBalance: changeBalance(action.payload.scedualPaymentBalance),
                          providerMaxLimit: action.payload.providerMaxLimit
                              ? changeBalance(action.payload.providerMaxLimit)
                              : null,
                          cashbackBalance: changeBalance(action.payload.cashbackBalance),
                      }
                    : item,
            );

            return {
                ...state,
                userTree: userTree(newData, true),
                allUsers: newData,
            };
        }
        //  Provider Max limit
        case ActionTypes.PROVIDER_MAX_LIMIT_SUCCESS: {
            const newData = state.allUsers.map((item) =>
                item.id === action.payload.id
                    ? {
                          ...item,
                          ...action.payload,
                          balance: changeBalance(action.payload.balance),
                          scedualPaymentBalance: changeBalance(action.payload.scedualPaymentBalance),
                          providerMaxLimit: action.payload.providerMaxLimit
                              ? changeBalance(action.payload.providerMaxLimit)
                              : null,
                          cashbackBalance: changeBalance(action.payload.cashbackBalance),
                      }
                    : item,
            );

            return {
                ...state,
                userTree: userTree(newData, true),
                allUsers: newData,
            };
        }

        case ActionTypes.SCHELUDE_SUCCESS: {
            const newData = state.allUsers.map((item) =>
                item.id === action.payload.id
                    ? {
                          ...item,
                          ...action.payload,
                          balance: changeBalance(action.payload.balance),
                          scedualPaymentBalance: changeBalance(action.payload.scedualPaymentBalance),
                          providerMaxLimit: action.payload.providerMaxLimit
                              ? changeBalance(action.payload.providerMaxLimit)
                              : null,
                          cashbackBalance: changeBalance(action.payload.cashbackBalance),
                      }
                    : item,
            );

            return {
                ...state,
                userTree: userTree(newData, true),
                allUsers: newData,
            };
        }

        // Comment
        case ActionTypes.COMMENT_SUCCESS: {
            return {
                ...state,
                comment: action.payload,
            };
        }

        case ActionTypes.GET_LOGIN_HISTORY_SUCCESS: {
            return {
                ...state,
                loginHistory: action.payload,
            };
        }

        case ActionTypes.ON_USER_SELECT: {
            return {
                ...state,
                selectedUser: action.payload,
            };
        }

        // Scedual payment
        case ActionTypes.SCEDUAL_PAYMENT_SUCCESS: {
            return {
                ...state,
                scedualPayment: {
                    ...action.payload,
                    amount: changeBalance(action.payload.amount),
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
