import { action } from 'typesafe-actions';

import { ActionTypes } from './types';

const handleRequest = (data) => action(ActionTypes.HANDLE_REQUEST, data);
const handleSuccess = (data) => action(ActionTypes.HANDLE_SUCCESS, data);
const handleError = (data) => action(ActionTypes.HANDLE_ERROR, data);
const handleReset = () => action(ActionTypes.HANDLE_RESET);

export default {
    handleRequest,
    handleSuccess,
    handleError,
    handleReset,
};
