export enum ActionTypes {
    GAMES_REQUEST = '@@evoGames/GAMES_REQUEST',
    GAMES_SUCCESS = '@@evoGames/GAMES_SUCCESS',

    OPEN_GAME_REQUEST = '@@evoGames/OPEN_GAME_REQUEST',
    OPEN_GAME_SUCCESS = '@@evoGames/OPEN_GAME_SUCCESS',
    OPEN_GAME_FAIL = '@@evoGames/OPEN_GAME_FAIL',

    CLOSE_GAME_REQUEST = '@@evoGames/CLOSE_GAME_REQUEST',
    CLOSE_GAME_SUCCESS = '@@evoGames/CLOSE_GAME_SUCCESS',
}
