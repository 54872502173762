export enum ActionTypes {
    GET_RULES_REQUEST = '@@rules/GET_RULES_REQUEST',
    GET_RULES_SUCCESS = '@@rules/GET_RULES_SUCCESS',
    GET_RULES_ERROR = '@@rules/GET_RULES_ERROR',

    CREATE_RULES_REQUEST = '@@rules/CREATE_RULES_REQUEST',
    CREATE_RULES_SUCCESS = '@@rules/CREATE_RULES_SUCCESS',
    CREATE_RULES_ERROR = '@@rules/CREATE_RULES_ERROR',

    DELETE_RULES_REQUEST = '@@rules/DELETE_RULES_REQUEST',
    DELETE_RULES_SUCCESS = '@@rules/DELETE_RULES_SUCCESS',
    DELETE_RULES_ERROR = '@@rules/DELETE_RULES_ERROR',

    GET_TICKER_REQUEST = '@@rules/GET_TICKER_REQUEST',
    GET_TICKER_SUCCESS = '@@rules/GET_TICKER_SUCCESS',
    GET_TICKER_ERROR = '@@rules/GET_TICKER_ERROR',

    CREATE_TICKER_REQUEST = '@@rules/CREATE_TICKER_REQUEST',
    CREATE_TICKER_SUCCESS = '@@rules/CREATE_TICKER_SUCCESS',
    CREATE_TICKER_ERROR = '@@rules/CREATE_TICKER_ERROR',
}
