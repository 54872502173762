import { PermissionEnum } from 'src/common/provider-common-type-util/dictionary/permission';

export default [
    {
        title: 'Total Statistic',
        link: '/total-statistic',
        permissionLevel: PermissionEnum.USER_AGENT_PERMISSION_LEVEL,
        exclude: [] as any,
    },
    {
        title: 'Manage Users',
        link: '/manage-users',
        permissionLevel: PermissionEnum.USER_AGENT_PERMISSION_LEVEL,
        exclude: [] as any,
    },
    {
        title: 'Transactions',
        link: '/statistics',
        permissionLevel: PermissionEnum.USER_AGENT_PERMISSION_LEVEL,
        exclude: [] as any,
    },
    {
        title: 'Report',
        link: '/report',
        permissionLevel: PermissionEnum.USER_AGENT_PERMISSION_LEVEL,
        exclude: [] as any,
    },
    {
        title: 'Balances',
        link: '/balances',
        permissionLevel: PermissionEnum.USER_ADMIN_PERMISSION_LEVEL,
        exclude: [],
    },
    {
        title: 'Settings',
        link: '/settings-bundle',
        permissionLevel: PermissionEnum.USER_ADMIN_PERMISSION_LEVEL,
        exclude: [],
    },
    {
        title: 'olwin_chance',
        link: '/1w-speed-chance',
        permissionLevel: PermissionEnum.USER_PLAYER_PERMISSION_LEVEL,
        exclude: [],
    },
    {
        title: 'Chance',
        link: '/chance',
        permissionLevel: PermissionEnum.USER_PLAYER_PERMISSION_LEVEL,
        exclude: [],
    },
    // {
    //     title: 'Ticker',
    //     link: '/ticker',
    //     permissionLevel: userDictionary.USER_ADMIN_PERMISSION_LEVEL,
    //     exclude: [] as any,
    // },
    {
        title: 'Rules',
        link: '/rules',
        permissionLevel: PermissionEnum.USER_ADMIN_PERMISSION_LEVEL,
        exclude: [],
    },
    {
        title: 'RuleList',
        link: '/rule-list',
        permissionLevel: PermissionEnum.USER_PLAYER_PERMISSION_LEVEL,
        exclude: [],
    },

    // {
    //   title: "Balance",
    //   link: "/balance",
    //   permissionLevel: userDictionary.USER_PLAYER_PERMISSION_LEVEL,
    //   exclude: [
    //     userDictionary.USER_ADMIN_PERMISSION_LEVEL
    //   ] as any,
    // },
    // {
    //   title: "Personal Info",
    //   link: "/personal-info",
    //   permissionLevel: userDictionary.USER_PLAYER_PERMISSION_LEVEL,
    //   exclude: [
    //     userDictionary.USER_ADMIN_PERMISSION_LEVEL
    //   ] as any,
    // }
];
