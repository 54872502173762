// import validator from "validator";

export default () => {
    const errors: any = {};

    return {
        isValid: !Object.keys(errors).length,
        errors,
    };
};
