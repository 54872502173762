import { action } from 'typesafe-actions';

import { IAction } from '../../interfaces/IAction';
import { IRequestGetChance, IResponseGetChance } from '../../interfaces/IChance';
import { IRequestParams } from '../../interfaces/IGames';

import { ActionTypes } from './types';

// Games
export const gamesTypesRequest = (data: IAction<IRequestParams>) => action(ActionTypes.GAMES_TYPES_REQUEST, data);
export const gamesTypesSuccess = (data: any) => action(ActionTypes.GAMES_TYPES_SUCCESS, data);

// Game
export const gameTypeRequest = (data: IAction<IRequestParams>) => action(ActionTypes.GAME_TYPE_REQUEST, data);
export const gameTypeSuccess = (data: { game: any; type: any }) => action(ActionTypes.GAME_TYPE_SUCCESS, data);
export const createGameTypeRequest = (data: any) => action(ActionTypes.CREATE_GAME_TYPE_REQUEST, data);
export const createGameTypeSuccess = (data: { game: any; type: any }) => action(ActionTypes.CREATE_GAME_TYPE_SUCCESS, data);
export const gameTypeReset = (data: any) => action(ActionTypes.GAME_TYPE_RESET, data);

// Tickets
export const ticketsRequest = (data: IAction<IRequestParams>) => action(ActionTypes.TICKETS_REQUEST, data);
export const ticketsSuccess = (data: any) => action(ActionTypes.TICKETS_SUCCESS, data);

// Month Tickets
export const monthTicketsSuccess = (data: { tickets: any; type: any }) => action(ActionTypes.MONTH_TICKETS_SUCCESS, data);

// Create ticket
export const createTicketRequest = (data: any) => action(ActionTypes.CREATE_TICKET_REQUEST, data);
export const createTicketSuccess = (data: { ticket: any; type: any }) => action(ActionTypes.CREATE_TICKET_SUCCESS, data);

// Ticket point (Checkpoint game)
export const ticketPointRequest = (data: IAction<IRequestParams>) => action(ActionTypes.TICKET_POINT_REQUEST, data);
export const ticketPointSuccess = (data: { point: any; type: any }) => action(ActionTypes.TICKET_POINT_SUCCESS, data);

// One on One
export const createOneOnOneTicketRequest = (data: any) => action(ActionTypes.CREATE_ONE_ON_ONE_TICKET_REQUEST, data);
export const createOneOnOneTicketSuccess = (data: any) => action(ActionTypes.CREATE_ONE_ON_ONE_TICKET_SUCCESS, data);

export const setPriceChangeRequest = (data: any) => action(ActionTypes.SET_PRICE_CHANGE_REQUEST, data);
export const changePriceTicket = (data: any) => action(ActionTypes.CHANGE_PRICE_TICKET, data);

// Chances
export const chancesRequest = (data: IAction<IRequestGetChance>) => action(ActionTypes.CHANCES_REQUEST, data);
export const chancesSuccess = (data: IResponseGetChance) => action(ActionTypes.CHANCES_SUCCESS, data);
