export default {
    locale: 'he',
    messages: {
        // Templates
        'Cashback Percent': 'אחוז החזר מזומנים',
        'Login': 'כניסה',
        'Time/Date': 'זמן/תאריך',
        'Status': 'סטטוס',
        'Amount': 'כמות',
        'Password': 'סיסמה',
        'Masters': 'מאסטרים',
        'Agents': 'סוכנים',
        'Players': 'שחקנים',
        'Balance': 'יתרה',
        'Cashback': 'החזר מזומנים',
        'Rotatable Payment': 'תשלום סובל',
        'Date': 'תאריך',
        'Total Balance': 'יתרה כוללת',
        'Profit': 'רווח',
        'Agent Commission': 'עמלת סוכן',
        'Master Commission': 'עמלת מאסטר',
        'System Commission': 'עמלת המערכת',
        'createDate': 'תאריך יצירה',
        'startDate': 'תאריך התחלה',
        'currentTickets': 'כרטיסים נוכחיים',
        'cashbackPercent': 'אחוז החזר מזומנים',
        'result': 'תוצאה',
        'targetCurrentBalance': 'יתרה נוכחית של המטרה',
        'Type': 'סוג',
        'Id': 'מזהה',
        'Creator': 'יצרן',
        'Target': 'מטרה',
        'CashbackPlayer': 'שחקן החזר מזומנים',
        'creatorCurrentBalance': 'יתרה נוכחית של היצרן',
        'UserId': 'מזהה משתמש',
        'Bets Count': 'ספירת הימורים',
        'Canceled bets': 'הימורים שבוטלו',
        'Debit amount': 'סכום חשבון',
        'Win amount': 'סכום ניצחון',
        'Profit amount': 'סכום רווח',
        'Comment': 'הערה',
        'ProfitPercent': 'אחוז רווח',

        // Links
        'Statistics': 'סטטיסטיקה',
        'Transactions': 'עסקאות',
        'Manage Users': 'ניהול משתמשים',
        'Logout': 'התנתקות',

        // Components
        'Add': 'הוסף',
        'Take': 'קח',
        'Cancel': 'ביטול',
        'Add New': 'הוסף חדש',
        'Checkpoint': 'נקודת בדיקה',
        'One On One': 'אחד על אחד',
        'Price': 'מחיר',
        'Month Prize': 'פרס חודשי',
        'Jackpot': 'גקפוט',
        'Master': 'מאסטר',
        'Agent': 'סוכן',
        'User': 'משתמש',
        'Confirm': 'אישור',
        'User Ban': 'חסימת משתמש',
        'User Unban': 'ביטול חסימת משתמש',
        'No content': 'אין נתונים',
        'Profit Percent': 'אחוז רווח',
        'Discount': 'הנחה',
        'Change': 'שנה',
        'New password': 'סיסמה חדשה',
        'Confirm password': 'אישור סיסמה',
        'Reset': 'איפוס',
        'Change password': 'שנה סיסמה',
        'Change Comment': 'שנה הערה',
        'New Comment': 'הערה חדשה',
        'Accept': 'קבל',
        'Update': 'עדכן',
        'Create': 'צור',
        'Delete': 'מחק',
        'Update rule': 'עדכן כלל',
        'Apply': 'החל',
        'Set': 'קבע',
        'Chance': 'הזדמנות',
        'more': 'יותר',
        'less': 'פחות',
        'odd': 'אי-זוגי',
        'even': 'זוגי',
        'Special': 'מיוחד',
        'Parity': 'זוגיות/אי-זוגיות',
        'MoreLess': 'יותר/פחות',
        'Make bet': 'הוסף הימור',
        'isSchedule': 'גרף',

        // Pages
        'Best at Table': 'הטוב בטבלה',
        'Group Bet': 'הימור קבוצתי',
        'Daily': 'יומי',
        'Game': 'משחק',
        'Users': 'משתמשים',
        'Add Master': 'הוסף מאסטר',
        'Add Agent': 'הוסף סוכן',
        'Add User': 'הוסף משתמש',
        'Name': 'שם',
        'Edit': 'ערוך',
        'Rules': 'כללים',
        'Ticker': 'טיקר',
        'RuleList': 'רשימת כללים',

        // Other
        'Username': 'שם משתמש',
        'team': 'קבוצה',
        'parentId': 'מזהה הורה',
        'This user is already getting scheduled payment': 'משתמש זה כבר מקבל תשלום קבוע',
        'Payment date': 'תאריך תשלום',
        'Set scheduled payment': 'קבע תשלום קבוע',
        'Weekly': 'שבועי',
        'Monthly': 'חודשי',
        'Wait': 'המתן',
        'Set result': 'קבע תוצאה',
        'documents': 'מסמכים',
        'approve': 'אשר',
        'reject': 'דחה',
        'on_review': 'בסקירה',
        'approved': 'אושר',
        'rejected': 'נדחה',
        'secondName': 'שם משפחה',
        'birth': 'תאריך לידה',
        'country': 'מדינה',
        'city': 'עיר',
        'email': 'דוא"ל',
        'gender': 'מגדר',
        'phone': 'מספר טלפון',

        'Possible gain': 'רווח פוטנציאלי',

        // Create User
        'male': 'זכר',
        'female': 'נקבה',
        'user_name_input': 'שם משתמש',
        'enter_surname': 'הזן את שם המשפחה שלך',
        'user_surname_input': 'שם משתמש',
        'e_mail': 'דוא"ל',
        'enter_e_mail': 'הזן את כתובת הדוא"ל שלך',
        'login_input': 'לוגין',
        'birthday_date': 'תאריך לידה',
        'day': 'יום',
        'year': 'שנה',
        'month': 'חודש',
        'user_password_input': 'סיסמה',
        'enter_password': 'הזן סיסמה',
        'enter_name': 'שם משתמש',
        'select_country': 'בחר מדינה',
        'select_city': 'עיר',
        'validate_longer_4': 'השדה חייב להיות באורך של 4 תווים או יותר',
        'validate_longer_2': 'השדה חייב להיות באורך של 2 תווים או יותר',
        'validate_email': 'הזן כתובת דוא"ל תקינה',
        'validate_phone': 'הזן מספר טלפון תקין',
        'validate_password': 'הסיסמה חייבת להכיל בין 4 ל-32 תווים',
        'register_success': 'משתמש נוצר בהצלחה',

        // Create Balance
        'Balance_type': 'סוג יתרה',
        'System_provider': 'ספק המערכת',
        'Game_provider': 'ספק המשחק',
        'Provider_game_type': 'סוג משחק של ספק',
        'Provider_game_id': 'מזהה משחק של ספק',
        'Provider_game_name': 'שם משחק של ספק',
        'Provider_transaction_id': 'מזהה עסקת ספק',
        'Success': 'הצלחה',
        'reason': 'סיבה',
        'change_balance': 'שינוי יתרה',
        'amount': 'סכום',
        'date': 'תאריך',
        'target_current_balance': 'יתרה נוכחית למטרה',
        'create': 'יצירה',
        'finish': 'סיום',
        'status': 'סטטוס',
        'Action': 'פעולה',
        'Balances': 'יתרות פעילות',
        'game_provider': 'ספק משחק',
        'game_type': 'סוג משחק',
        'game_name': 'שם משחק',
        'system_provider': 'ספק המערכת',
        'provider_type': 'סוג ספק',
        'provider_game_id': 'מזהה משחק של ספק',
        'provider_transaction_id': 'מזהה עסקת ספק',

        // Settings
        'Settings': 'הגדרות',
        'name_short': 'שם קצר',
        'create_setting_bundle': 'יצירת אוסף הגדרות',
        'edit_setting_bundle': 'עריכת אוסף הגדרות',
        'olwin_chance': 'הזדמנות OLWIN',
        'System Provider': 'ספק המערכת',
        'confirm_settings': 'האם אתה יכול לעשות זאת ללא בעיה?',
        'Max Bet': 'המסכום המרבי להימור',
        'Max Win': 'הפרס המרבי',
        'Disabled': 'מושבת',
        'Provider Name': 'שם ספק',
        'Back': 'חזור',
        'Save': 'שמור',

        // New Translate
        'Betting': 'הימורים',
        'betCount': 'מספר הימורים',
        'debitAmount': 'סכום החיוב',
        'profitAmount': 'סכום הרווח',
        'totalCashback': 'סכום ההחזר הכולל',
        'winAmount': 'סכום הניצחון',
        'info-user': 'מידע משתמש',
        'role': 'תַפְקִיד',
        'Add Player': 'משתמשים מאסטר',
        'last-7-days': 'השבוע האחרון',
        'last-month': 'החודש האחרון',
        'last-6-months': 'החצי שנה האחרונה',
        'login-account': 'כניסת חשבון',
        'sign-in': 'התחברות',
        'user-agent': 'סוכן משתמש',
        'ip-address': 'כתובת IP',
        'transaction-by-user': 'קבלת עסקה לפי משתמש',
        'transaction-by-tree': 'קבלת עסקה לפי עץ',
        'canceledBet': 'הימור בוטל',
        'agentProfitAmount': 'סכום הרווח של הסוכן',
        'masterProfitAmount': 'סכום הרווח של המאסטר',
        'Report': 'דו"ח',
        'Provider': 'ספק',
        'LoginPriority': '"כניסה" יש עדיפות גבוהה יותר מ-"מטרות"',

        'Casino': 'קזינו',
        'Racing': 'מרוץ',
        'Played': 'שוחק',
        'Refunded': 'החזר',
        'Won': 'ניצח',
        'UserName': 'שם משתמש',
        'Waiting': 'המתנה',
        'only-player': 'יכול להיות רק שחקן',
        'not-player': 'לא יכול להיות שחקן',

        'total-profit-casino': 'רווחי הקזינו',
        'total-profit-racing': 'רווחי המרוצים',
        'total-profit-game': 'רווחי מערכת המשחקים',
        'total-profit-sport': 'רווח ספורט',
        'total-profit': 'רווח כללי',
        'total-deposit': 'סך הפקדה כוללת',
        'total-withdrawal': 'סך המשיכה כוללת',
        'logged-players': 'שחקנים מחוברים',
        'players-balance': 'יתרת השחקנים',
        'show-details': 'הצג פרטים',
        'oldest-date': 'התאריך הוותיק ביותר',
        'Total Statistic': 'סטטיסטיקה כוללת',

        // new translate part 2

        'system-game': 'משחקי מערכת',
        'total': 'סה"כ',
        'info_transaction': 'מידע עסקה',

        'remember': 'זכור אותי',
        'choose-language': 'בחר שפה',
        'the_current': 'הדף הנוכחי אולי הוסר, שונה או אינו זמין זמנית.',
        'return_home': 'חזור לדף הבית',

        'Percent': 'אחוז רווח',
        'validate_percent': 'אנא הזן אחוז תקין בין 0 ל-100.',
        'BALANCE_TYPE_TRANSFER': 'העברה',
        'BALANCE_TYPE_TAKE': 'לקחת',
        'BALANCE_TYPE_DEPOSIT': 'הפקדה',
        'BALANCE_TYPE_OUT': 'משיכה',
        'BALANCE_TYPE_REDUCE': 'הפחתה',
        'BALANCE_TYPE_ADD': 'הוספה',
        'BALANCE_TYPE_REFUND': 'החזר',
        'BALANCE_TYPE_SCEDUAL_REDUCE': 'הפחתה מתוכננת',
        'BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE': 'הפחתת יתרה מתוכננת',
        'BALANCE_TYPE_INFO': 'מידע',
        'BALANCE_TYPE_RACING_DEPOSIT': 'הפקדת מרוצים',
        'BALANCE_TYPE_RACING_WITHDRAW': 'משיכת מרוצים',
        'BALANCE_TYPE_CASINO_DEBIT': 'חוב קזינו',
        'BALANCE_TYPE_CASINO_CREDIT': 'אשראי קזינו',
        'BALANCE_TYPE_CASINO_ROLLBACK': 'ביטול קזינו',
        'BALANCE_TYPE_SPORT_DEBIT': 'חוב ספורט',
        'BALANCE_TYPE_SPORT_CREDIT': 'אשראי ספורט',
        'BALANCE_TYPE_SPORT_ROLLBACK': 'ביטול ספורט',
    },
};
