/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import { CSSTransition } from 'react-transition-group';
import { chancesRequest, createTicketRequest } from '../../store/gamesTypes/actions';
import loading from 'src/assets/images/preloader_olwin.gif';

import { IHandlerStatus, IProfile } from '../../interfaces/IProfile';
import { IChanceProps, IChance, IChanceState } from '../../interfaces/IChance';
import { gameDictionary } from '../../common/dictionary';

import chanceData from '../../assets/tableTemplates/chance';
import Table from 'src/components/Table';
import Paginate from 'src/components/Paginate';
import NoContent from 'src/components/NoContent';

class ChanceSpeed extends React.Component<IChanceProps, IChanceState> {
    private interval;

    constructor(props) {
        super(props);

        this.state = {
            currentChance: {} as IChance,
            currentBetType: 0,
            currentBetPrediction: '',
            selectedCards: {},
            chanceHistoryIsShown: false,
            price: 0,
            isLoading: false,
        };
    }

    static getDerivedStateFromProps(props, state): any {
        if (!state.chanceHistoryIsShown) {
            let currentChance: IChance | null;

            if (props.chance.data.length === 0) {
                currentChance = null;
            } else {
                const sortedChances: IChance[] = props.chance.data.sort((first, second) => {
                    return second.startTime - first.startTime;
                });

                currentChance = sortedChances[0];
            }
            return {
                currentChance,
            };
        } else {
            return null;
        }
    }

    loadData = async () => {
        this.setState({ isLoading: true });
        await this.updateChance();
        this.interval = setInterval(() => {
            this.updateChance();
        }, 30000);

        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 1000);
    };

    componentDidMount(): void {
        this.loadData();
    }

    updateChance = () => {
        this.props.chancesRequest({
            status: gameDictionary.GAME_STATUS_STARTED,
            type: 501,
        });
    };

    handlePage = (pageNumber: number): void => {
        this.props.chancesRequest({
            status: gameDictionary.GAME_STATUS_ENDED,
            pageNumber,
            type: 501,
        });
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(): JSX.Element {
        const { chance, chancesLoaded, translate } = this.props;

        return (
            <>
                {!this.state.isLoading ? (
                    <div className="page-container" style={{ marginTop: '5px' }}>
                        <CSSTransition in={chancesLoaded} unmountOnExit={true} timeout={2000} classNames="modalWindowAnimation">
                            <div className="chance">
                                {chance && chance.data && chance.data.length > 0 ? (
                                    <div className="chance__history">
                                        <Table
                                            tableData={{
                                                ...chanceData,
                                                body: chance.data,
                                            }}
                                        />
                                        <Paginate
                                            pageCount={chance.totalCount / chance.amountPerPage}
                                            handlePage={this.handlePage}
                                        />
                                    </div>
                                ) : (
                                    <NoContent translate={translate} />
                                )}
                            </div>
                        </CSSTransition>
                    </div>
                ) : (
                    <div className="loading-page">
                        <img src={loading} alt="" />;
                    </div>
                )}
            </>
        );
    }
}

interface IStoreState {
    auth: {
        user: IProfile;
    };
    gamesTypes: {
        chance: {
            games: {
                data: IChance[];
            };
            chancesLoaded: boolean;
        };
    };
    manageHandlers: {
        handlerStatus: IHandlerStatus;
    };
}

const mapStateToProps = (state: IStoreState) => ({
    user: state.auth.user,
    chance: state.gamesTypes.chance.games,
    chancesLoaded: state.gamesTypes.chance.chancesLoaded,
    handlerStatus: state.manageHandlers.handlerStatus,
});

const mapDispatchToProps = {
    chancesRequest,
    createTicketRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ChanceSpeed));
