import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import React from 'react';
// import { BalanceProviderType, BalanceType } from 'src/common/enum';
import { BalanceProviderTypeEnum, BalanceTypeEnum } from 'src/common/provider-common-type-util/dictionary/balance';
import { getAuthToken } from 'src/utils/cookies';

import { balanceDescription } from '../../helpers';

export const premisionLevel = () => {
    const token = getAuthToken();
    if (!token) return 0;
    const decodedToken: { jwtPayloadData: { permissionLevel: number } } = jwtDecode(token);
    const { permissionLevel } = decodedToken.jwtPayloadData;
    return permissionLevel;
};

export default (item, { _styles, handlers, translate }) => (
    <tr key={item.id}>
        <td style={{ ..._styles[0] }}>{item.id}</td>
        <td style={{ ..._styles[1] }}>{item.type ? translate(BalanceTypeEnum[item.type]) : '-'}</td>
        <td style={{ ..._styles[1] }}>
            {item.providerType
                ? BalanceProviderTypeEnum[item.providerType]?.split('BALANCE_PROVIDER_TYPE_SPORT_')[1] ??
                  BalanceProviderTypeEnum[item.providerType]?.split('BALANCE_TYPE_CASINO_')[1] ??
                  BalanceProviderTypeEnum[item.providerType]
                : '-'}
        </td>
        <td style={{ ..._styles[2] }}>{balanceDescription(item).length > 1 ? balanceDescription(item)[1] : ''}</td>
        <td style={{ ..._styles[3] }}>{item.creator ? item.creator.login : '---'}</td>
        <td style={{ ..._styles[4] }}>{item.target ? item.target.login : '---'}</td>
        <td style={{ ..._styles[6] }}>{item.amount}</td>
        <td style={{ ..._styles[7] }}>{item.creatorCurrentBalance}</td>

        <td
            style={{ ..._styles[8], cursor: 'pointer' }}
            data-user={`${JSON.stringify(item.providerTransaction)}`}
            onClick={item.providerTransaction ? handlers.infoTransaction : () => {}}
        >
            {item.targetCurrentBalance}
        </td>
        <td style={{ ..._styles[9] }}>{moment(item.createDate).format('HH:mm:ss DD/MM')}</td>
    </tr>
);
