import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Whisper } from 'rsuite';
import { api } from 'src/api';
import { ReactComponent as Casino } from 'src/assets/images/casino.svg';
import loading from 'src/assets/images/preloader_olwin.gif';
import './styles.scss';
import { BalanceTypeEnum } from 'src/common/provider-common-type-util/dictionary/balance';
import { SystemProviderEnum } from 'src/common/provider-common-type-util/dictionary/system-provider';
import { setFilterReport, setFilterTransaction } from 'src/store/manageUsers/actions';

const TotalStatistic = (props) => {
    // const isMobile = window.innerWidth <= 900;

    const [isLoading, setIsLoading] = useState(false);
    const [totalStatistic, setTotalStatistic] = useState(null);

    const navigate = useNavigate();

    const loadData = async () => {
        setIsLoading(true);
        const resp = await api.get('/statistic');

        if (resp.data) {
            setTotalStatistic(resp.data);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleClickDetailsReport = (provider: number) => {
        props.setFilterReport(provider);
        navigate('/report');
    };

    const handleClickDetailsTransaction = (date: string, provider: number) => {
        const options = {
            date,
            provider,
        };
        props.setFilterTransaction(options);
        navigate('/statistics');
    };

    if (!totalStatistic || !totalStatistic.total_report || isLoading)
        return (
            <div className="loading-page">
                <img src={loading} alt="" />;
            </div>
        );

    return (
        <div className="Total" style={{ paddingTop: '5px' }}>
            <Whisper
                followCursor
                placement="top"
                speaker={
                    <Tooltip className="Total-widjet-info">
                        <span>
                            {props.translate('Played')}: {totalStatistic.total_report.PROVIDER_CASINO.played}
                        </span>
                        <span>
                            {props.translate('Refunded')}: {totalStatistic.total_report.PROVIDER_CASINO.refunded}
                        </span>
                        <span>
                            {props.translate('Won')}: {totalStatistic.total_report.PROVIDER_CASINO.won}
                        </span>
                    </Tooltip>
                }
            >
                <div className="Total-widjet">
                    <div className="Total-widjet-icon">
                        <Casino />
                    </div>
                    <div className="Total-widjet-title">
                        <span>{props.translate('total-profit-casino')}:</span>
                        <span>{totalStatistic.total_report.PROVIDER_CASINO.profit / 100}</span>
                        <span onClick={() => handleClickDetailsReport(SystemProviderEnum.PROVIDER_CASINO)}>
                            {props.translate('show-details')}
                        </span>
                    </div>
                </div>
            </Whisper>
            <Whisper
                followCursor
                placement="top"
                speaker={
                    <Tooltip className="Total-widjet-info">
                        <span>
                            {props.translate('Played')}: {totalStatistic.total_report.PROVIDER_SPORT.played}
                        </span>
                        <span>
                            {props.translate('Refunded')}: {totalStatistic.total_report.PROVIDER_SPORT.refunded}
                        </span>
                        <span>
                            {props.translate('Won')}: {totalStatistic.total_report.PROVIDER_SPORT.won}
                        </span>
                    </Tooltip>
                }
            >
                <div className="Total-widjet" data-tooltip-id="my-tooltip">
                    <div className="Total-widjet-icon">
                        <Casino />
                    </div>
                    <div className="Total-widjet-title">
                        <span>{props.translate('total-profit-sport')}:</span>
                        <span>{totalStatistic.total_report.PROVIDER_SPORT.profit / 100}</span>
                        <span onClick={() => handleClickDetailsReport(SystemProviderEnum.PROVIDER_SPORT)}>
                            {props.translate('show-details')}
                        </span>
                    </div>
                </div>
            </Whisper>
            <Whisper
                followCursor
                placement="top"
                speaker={
                    <Tooltip className="Total-widjet-info">
                        <span>
                            {props.translate('Played')}: {totalStatistic.total_report.SYSTEM_GAME.played}
                        </span>
                        <span>
                            {props.translate('Refunded')}: {totalStatistic.total_report.SYSTEM_GAME.refunded}
                        </span>
                        <span>
                            {props.translate('Won')}: {totalStatistic.total_report.SYSTEM_GAME.won}
                        </span>
                    </Tooltip>
                }
            >
                <div className="Total-widjet">
                    <div className="Total-widjet-icon">
                        <Casino />
                    </div>
                    <div className="Total-widjet-title">
                        <span>{props.translate('total-profit-game')}:</span>
                        <span>{totalStatistic.total_report.SYSTEM_GAME.profit / 100}</span>
                        <span onClick={() => handleClickDetailsReport(SystemProviderEnum.SYSTEM_GAME)}>
                            {props.translate('show-details')}
                        </span>
                    </div>
                </div>
            </Whisper>

            <div className="Total-widjet">
                <div className="Total-widjet-icon">
                    <Casino />
                </div>
                <div className="Total-widjet-title">
                    <span>{props.translate('total-profit')}:</span>
                    <span>{totalStatistic.total_report.totalProfit / 100}</span>
                </div>
            </div>

            <Whisper
                followCursor
                placement="top"
                speaker={
                    <Tooltip className="Total-widjet-info">
                        <span>
                            {props.translate('oldest-date')}:{' '}
                            {moment(totalStatistic.total.depositOldestDate).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                    </Tooltip>
                }
            >
                <div className="Total-widjet">
                    <div className="Total-widjet-icon">
                        <Casino />
                    </div>
                    <div className="Total-widjet-title">
                        <span className="Total-widjet-title-text">{props.translate('total-deposit')}:</span>
                        <span className="Total-widjet-title-text">{totalStatistic.total.deposit / 100}</span>
                        <span
                            className="Total-widjet-title-text"
                            onClick={() =>
                                handleClickDetailsTransaction(
                                    totalStatistic.total.depositOldestDate,
                                    BalanceTypeEnum.BALANCE_TYPE_DEPOSIT,
                                )
                            }
                        >
                            {props.translate('show-details')}
                        </span>
                    </div>
                </div>
            </Whisper>
            <Whisper
                followCursor
                placement="top"
                speaker={
                    <Tooltip className="Total-widjet-info">
                        <span>
                            {props.translate('oldest-date')}:{' '}
                            {moment(totalStatistic.total.depositOldestDate).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                    </Tooltip>
                }
            >
                <div className="Total-widjet">
                    <div className="Total-widjet-icon">
                        <Casino />
                    </div>
                    <div className="Total-widjet-title">
                        <span className="Total-widjet-title-text">{props.translate('total-withdrawal')}:</span>
                        <span className="Total-widjet-title-text">{totalStatistic.total.withdrawal / 100}</span>
                        <span
                            className="Total-widjet-title-text"
                            onClick={() =>
                                handleClickDetailsTransaction(
                                    totalStatistic.total.depositOldestDate,
                                    BalanceTypeEnum.BALANCE_TYPE_OUT,
                                )
                            }
                        >
                            {props.translate('show-details')}
                        </span>
                    </div>
                </div>
            </Whisper>
            <div className="Total-widjet">
                <div className="Total-widjet-icon">
                    <Casino />
                </div>
                <div className="Total-widjet-title">
                    <span>{props.translate('logged-players')}:</span>
                    <span>
                        {totalStatistic.total.playersOnlineCount} / {totalStatistic.total.playersCount}
                    </span>
                </div>
            </div>
            <div className="Total-widjet">
                <div className="Total-widjet-icon">
                    <Casino />
                </div>
                <div className="Total-widjet-title">
                    <span>{props.translate('players-balance')}:</span>
                    <span>{totalStatistic.total.balance / 100}</span>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    setFilterReport,
    setFilterTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(TotalStatistic));
