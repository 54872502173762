import classNames from 'classnames';
import React from 'react';

import { ReactComponent as Check } from '../assets/images/check.svg';
import './styles.scss';

const CheckBox = ({
    className = '',
    checked = false,
    onChange = () => null,
    label = '',
    hideLabel = false,
    classNameBox = '',
    style = {},
}) => (
    <div style={style} className={classNames('CheckBox', className, { 'CheckBox-checked': checked })}>
        <div onClick={onChange} className={classNames('CheckBox-box', classNameBox)}>
            {checked && (
                <div className="CheckBox-checkmark">
                    <Check />
                </div>
            )}
        </div>
        {!hideLabel && label && <span className="CheckBox-label">{label}</span>}
    </div>
);

export default CheckBox;
