import React from 'react';

import { stringTrim } from '../../helpers';
import { IUserData, IHandlerStatus } from '../../interfaces/IProfile';
import ChangePasswordForm from '../Forms/ChangePasswordForm/Form';
import ModalWindow from '../ModalWindow';

import ModalHOC from './ModalHOC';

interface IChangePasswordModal {
    userData: IUserData;
    dataModal: string;
    handlerStatus: IHandlerStatus;
    changePasswordRequest(object: { newPassword: string; passwordSign: string; id: number }): void;
    switchModal(e: React.MouseEvent<HTMLElement>): void;
    translate(key: string): string;
}

const ChangePasswordModal: React.FC<IChangePasswordModal> = (props) => {
    const { userData } = props;

    const onSubmit = (values: { newPassword: string; confirmPassword: string }): void => {
        props.changePasswordRequest({
            newPassword: values.newPassword,
            passwordSign: values.confirmPassword,
            id: userData.userId,
        });
    };

    return (
        <ModalWindow dataModal={props.dataModal} closeModal={props.switchModal}>
            <div className="modal-container__inner">
                <div className="modal-container__header">
                    <div>{props.translate('Change password')}</div>
                    <div style={{ color: '#e6bc73' }}>{stringTrim(userData.name)}</div>
                </div>

                <ChangePasswordForm
                    onSubmit={onSubmit}
                    userData={userData}
                    handlerStatus={props.handlerStatus}
                    dataModal={props.dataModal}
                    switchModal={props.switchModal}
                    translate={props.translate}
                />
            </div>
        </ModalWindow>
    );
};

export default ModalHOC(ChangePasswordModal);
