import { IntlReducer as Intl } from 'react-redux-multilingual';
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { removeAuthToken } from 'src/utils/cookies';

import authReducer from './auth/reducer';
import authSaga from './auth/sagas';
import { AuthState } from './auth/types';
import evoGamesReducer from './evoGames/reducer';
import evoGamesSaga from './evoGames/sagas';
import gamesTypesReducer from './gamesTypes/reducer';
import manageHandlersReducer from './manageHandlers/reducer';
import manageHandlersSaga from './manageHandlers/sagas';
import manageUsersReducer from './manageUsers/reducer';
import manageUsersSaga from './manageUsers/sagas';
import rulesReducer from './rules/reducer';
import rulesSaga from './rules/sagas';
import setAuthorizationHeader from './setAuthorizationHeader';

export interface ApplicationState {
    auth: AuthState;
}

export const initialState = () => ({});

const appReducer = combineReducers({
    auth: authReducer,
    manageUsers: manageUsersReducer,
    evoGames: evoGamesReducer,
    rules: rulesReducer,
    manageHandlers: manageHandlersReducer,
    gamesTypes: gamesTypesReducer,
    Intl,
});

export const rootReducer = (state: any, action: any) => {
    if (action.type === '@@auth/PROFILE_LOGOUT') {
        document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.reload();
        removeAuthToken();
        setAuthorizationHeader(null);
        state = initialState();
    }
    return appReducer(state, action);
};

export function* rootSaga() {
    yield all([fork(authSaga), fork(manageUsersSaga), fork(evoGamesSaga), fork(manageHandlersSaga), fork(rulesSaga)]);
}
