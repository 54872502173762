import React from 'react';

import { IHandlerStatus } from '../../../interfaces/IProfile';
import Button from '../../Button';
import Input from '../../Input';
import FormHOC from '../FormHOC';

import validator from './validator';

interface IChangeCommentForm {
    dataModal: string;
    handlerStatus: IHandlerStatus;
    customValues: { text: string };
    errors: { text: string };
    switchModal(e: any): void;
    onSubmit(params: any): void;
    handleChange(e: any): void;
    translate(key: string): string;
}

const Form: React.FC<IChangeCommentForm> = (props) => (
    <form
        onSubmit={(event: any) => {
            event.preventDefault();

            if (!event.target.text.value) {
                return window.NotificationManager.addNotification({
                    title: '',
                    message: 'You must change value',
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true,
                    },
                });
            }

            if (event.target.text.value === props.customValues.text) {
                return window.NotificationManager.addNotification({
                    title: '',
                    message: 'Value was not changed',
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true,
                    },
                });
            }

            props.onSubmit(event);
        }}
    >
        <div className="modal-container__inputs">
            <Input
                className="input gold-input"
                placeholder={props.translate('New Comment')}
                type="text"
                name="text"
                defaultValue={props.customValues.text}
                error={props.errors.text}
                onChange={props.handleChange}
            />
        </div>
        <div className="modal-container__btns">
            <Button className="btn gold-btn" type="submit" disabled={props.handlerStatus.status === 'loading'}>
                {props.translate('Apply')}
            </Button>
            <Button className="btn grey-btn" type="button" data-modal={props.dataModal} onClick={props.switchModal}>
                {props.translate('Cancel')}
            </Button>
        </div>
    </form>
);

export default FormHOC(
    Form,
    {
        text: '',
    },
    validator,
);
