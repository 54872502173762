import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getRulesRequest } from '../../store/rules/actions';

const RuleList: React.FC<any> = ({ getRulesRequest, rules }) => {
    useEffect(() => {
        getRulesRequest({});
    }, [getRulesRequest]);

    return (
        <div className="rule-list logos-page page-container">
            <div className="rule-list__content">
                <h1 className="rule-list__title">Rules</h1>
                <ul className="rule-list__list">
                    {(rules || []).map((rule, i) =>
                        JSON.parse(rule.text)[localStorage.locale] ? (
                            <li key={i} className="rule-list__item">
                                <h2 className="rule-list__item-title">{rule.gameName}</h2>
                                <div
                                    className="rule-list__item-text"
                                    dangerouslySetInnerHTML={{
                                        __html: JSON.parse(rule.text)[localStorage.locale],
                                    }}
                                />
                            </li>
                        ) : (
                            ''
                        ),
                    )}
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    rules: state.rules.rules,
    rulesLoaded: state.rules.rulesLoaded,
});

const mapDispatchToProps = {
    getRulesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleList);
