import React from 'react';
import ReactPaginate from 'react-paginate';

interface IPaginate {
    pageCount: number;
    handlePage: (page: number) => void;
    page?: number;
}
const Paginate: React.FC<IPaginate> = (props) => {
    if (props.pageCount <= 1) return null;

    const handlePage = ({ selected }) => {
        props.handlePage(Number(selected) + 1);
    };

    return (
        <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            pageCount={props.pageCount}
            onPageChange={handlePage}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={props.page}
        />
    );
};

export default React.memo(Paginate);
