import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import Paginate from 'src/components/Paginate';
import { withRouter } from 'src/withRouter';

import { loginHistoryRequest } from '../../store/manageUsers/actions';

const LoginHistory = ({ loginHistoryRequest, loginHistory, match, selectedUser, translate }) => {
    const [page, setPage] = useState(1);
    useEffect(() => {
        const config = {
            getParams: {
                id: match.params.id,
                pageNumber: page,
            },
        };
        loginHistoryRequest(config);
    }, [loginHistoryRequest, match.params.id, page]);

    const handlePage = (pageNumber) => {
        setPage(pageNumber);
        const config = {
            getParams: {
                id: match.params.id,
                pageNumber,
            },
        };
        loginHistoryRequest(config);
    };

    if (!loginHistory) {
        return null;
    }

    return (
        <div className="loginHistoryWrapper">
            <div className="selectedUser">{selectedUser}</div>
            <div className="tableCell">
                <div className="tableHeaderItem">{translate('Date')}</div>
                <div className="tableHeaderItem">{translate('user-agent')}</div>
                <div className="tableHeaderItem">{translate('ip-address')}</div>
                <div className="tableHeaderItem">{translate('Creator')}</div>
            </div>
            {loginHistory.data.slice((page - 1) * 20, page * 20).map((el) => (
                <div key={el.id} className="tableCell">
                    <div className="tableItem">{moment(el.createDate).format('HH:mm:ss DD/MM/YYYY')}</div>
                    <div className="tableItem">{el.userAgent}</div>
                    <div className="tableItem">{el.ipAddress}</div>
                    <div className="tableItem">{el?.creator?.login}</div>
                </div>
            ))}
            <Paginate pageCount={loginHistory.data.length / 20} handlePage={handlePage} />
        </div>
    );
};

const mapStartToProps = (state) => ({
    loginHistory: state.manageUsers.loginHistory,
    selectedUser: state.manageUsers.selectedUser,
});

const mapDispatchToProps = {
    loginHistoryRequest,
};

export default withRouter(connect(mapStartToProps, mapDispatchToProps)(withTranslate(LoginHistory)));
