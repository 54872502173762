import validator from 'validator';

export default (data) => {
    const errors: any = {};

    if (validator.isEmpty(data.login)) {
        errors.login = 'required';
    }
    if (validator.isEmpty(data.password)) {
        errors.password = 'required';
    }
    if (validator.isEmpty(data.name)) {
        errors.name = 'required';
    }

    return {
        isValid: !Object.keys(errors).length,
        errors,
    };
};
