export default {
    _styles: [
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
        { width: '20%' },
        { width: '10%' },
        { width: '10%' },
        { width: '10%' },
    ],
    _template: 'statistics',
    head: [
        { title: 'Id' },
        { title: 'Type' },
        { title: 'provider_type' },
        { title: 'Game' },
        { title: 'Creator' },
        { title: 'Target' },
        // { title: 'CashbackPlayer' },
        { title: 'Amount' },
        { title: 'creatorCurrentBalance' },
        { title: 'targetCurrentBalance' },
        { title: 'createDate' },
    ],
    body: [],
};
