/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import { CSSTransition } from 'react-transition-group';
import arrow from 'src/assets/images/arrow.png';
import { ReactComponent as Sorted } from 'src/assets/images/sorted.svg';
import { ReactComponent as PDF } from 'src/assets/images/pdf.svg';
import { ReactComponent as CSV } from 'src/assets/images/csv.svg';
import qs from 'qs';
import cn from 'classnames';
import { api } from 'src/api';
import { EnumSortDirection } from 'src/common/enum';
import DateRangePickerComponent from 'src/components/UI/DateRangePickerComponent/DateRangePickerComponent';
import { dateToTimestamp } from 'src/utils/helpers';
import Button from '../../components/Button';
import Input from '../../components/Input';
import UserBalanceModal from '../../components/Modals/UserBalanceModal';
import NoContent from '../../components/NoContent';
import { switchModalGlobal } from '../../helpers';
import { IEvoGame } from '../../interfaces/IEvoGames';
import { IProfile, IHandlerStatus } from '../../interfaces/IProfile';
import { IStatisticGet, IBettingProps, IBettingState } from '../../interfaces/IStatistics';
import { gamesRequest } from '../../store/evoGames/actions';
import { setFilterReport } from 'src/store/manageUsers/actions';
import { bettingRequest } from '../../store/manageUsers/actions';
import './styles.scss';
import CheckBox from 'src/components/Checkbox';
import moment from 'moment';
import { premisionLevel } from 'src/components/Table/balances';
import html2pdf from 'html2pdf.js';
import Paginate from 'src/components/Paginate';
import TableReport from './table';
import { BalanceOutStatusEnum, BalanceTypeEnum } from 'src/common/provider-common-type-util/dictionary/balance';
import { PermissionEnum } from 'src/common/provider-common-type-util/dictionary/permission';
import { SystemProviderEnum } from 'src/common/provider-common-type-util/dictionary/system-provider';

class Report extends Component<IBettingProps, IBettingState> {
    constructor(props) {
        super(props);

        this.state = {
            userData: null,
            searchLogin: '',
            searchType: '',
            currentLogin: '',
            userBalanceIsOpen: false,
            startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
            userId: '',
            isLoading: false,
            isSchedule: false,
            reports: [],
            isTree: true,
            sorted: { key: '', sortedBy: '', name: '' },
            currentPage: 1,
            itemsPerPage: 20,
        };
    }

    sortedPosition = (array) => {
        return array
            .map((item) => item)
            .sort((a, b) => {
                if (this.state.sorted.key === 'totalProfit') {
                    if (this.state.sorted.sortedBy === EnumSortDirection.ASC)
                        return a.report_with_profit[this.state.sorted.key] - b.report_with_profit[this.state.sorted.key];
                    if (this.state.sorted.sortedBy === EnumSortDirection.DESC)
                        return b.report_with_profit[this.state.sorted.key] - a.report_with_profit[this.state.sorted.key];
                }
                if (this.state.sorted.sortedBy === EnumSortDirection.ASC)
                    return (
                        a.report_with_profit[this.state.sorted.key][this.state.sorted.name] -
                        b.report_with_profit[this.state.sorted.key][this.state.sorted.name]
                    );
                if (this.state.sorted.sortedBy === EnumSortDirection.DESC)
                    return (
                        b.report_with_profit[this.state.sorted.key][this.state.sorted.name] -
                        a.report_with_profit[this.state.sorted.key][this.state.sorted.name]
                    );
            });
    };

    factoryNameFile = () => {
        const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        const endtDate = moment(this.state.endDate).format('YYYY-MM-DD');
        const rangeDate = `${startDate}-${endtDate}`;
        let arrayName = ['olwin-report'];

        if (this.state.searchType) {
            arrayName.push(String(BalanceOutStatusEnum[this.state.searchType]));
        }
        if (this.state.currentLogin.length !== 0) {
            arrayName.push(`by-${this.state.currentLogin}`);
        }
        if (this.state.userId.length !== 0 && this.state.currentLogin.length === 0) {
            arrayName.push(`by-${this.state.userId}`);
        }
        if (this.state.isTree) {
            arrayName.push('tree');
        }
        if (this.state.isSchedule) {
            arrayName.push('schedule');
        }
        arrayName.push(rangeDate);

        return arrayName.join('-');
    };

    generatePdf = () => {
        const table = document.getElementById('report');
        const options = {
            filename: `${this.factoryNameFile()}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
        };
        html2pdf().from(table).set(options).save();
    };
    // generatePdf = () => {
    //     const table = document.getElementById('report');
    //     const options = {
    //         filename: `${this.factoryNameFile()}.pdf`,
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 2 },
    //     };

    //     html2pdf().from(table.outerHTML).set(options).save();
    // };

    getRoles(login, permissionLevel) {
        const permission = premisionLevel();

        const roles = login.map((login, index) => ({ login, permissionLevel: permissionLevel[index] })).reverse();

        const isAdmin = permission === PermissionEnum.USER_ADMIN_PERMISSION_LEVEL;
        const isMaster = permission === PermissionEnum.USER_MASTER_PERMISSION_LEVEL;
        const isAgent = permission === PermissionEnum.USER_AGENT_PERMISSION_LEVEL;

        const currentUserLogin = this.state.currentLogin;

        if (isAdmin) {
            const index = roles.findIndex((item) => item.login === currentUserLogin && item.permissionLevel !== '1');
            const find = roles.find((item) => item.login === currentUserLogin && item.permissionLevel === '1');
            if (find) {
                return find.login;
            }
            if (index !== -1) {
                const logins = roles.splice(index + 1, roles.length - 1);
                return logins.map((item) => item.login).join(' => ');
            }
            return roles.map((item) => item.login).join(' => ');
        }

        if (isMaster) {
            const findAgentIndex = roles.findIndex((item) => item.login === currentUserLogin && item.permissionLevel === '2');
            const find = roles.find((item) => item.login === currentUserLogin && item.permissionLevel === '1');
            if (find) {
                return find.login;
            }
            if (findAgentIndex !== -1) {
                const logins = roles.splice(findAgentIndex + 1, roles.length - 1);
                return logins.map((item) => item.login).join(' => ');
            }
            return roles
                .slice(1, 3)
                .map((item) => item.login)
                .join(' => ');
        }

        if (isAgent) {
            return roles
                .slice(2, 3)
                .map((item) => item.login)
                .join(' => ');
        }

        return '-';
    }

    loadData = async () => {
        const options: any = {
            timeTo: dateToTimestamp(this.state.endDate),
            timeFrom: dateToTimestamp(this.state.startDate),
            byTree: this.state.isTree,
        };

        if (this.state.currentLogin) {
            options.login = this.state.currentLogin;
        }

        if (this.state.userId.length !== 0) {
            options.target = Number(this.state.userId);
        }

        if (this.state.searchType.length !== 0) {
            options.gameProvider = this.state.searchType;
        }

        const parsedParams: string = qs.stringify({
            ...options,
        });

        const resp = await api.get(`/statistic/report?${parsedParams}`);

        if (resp.data) {
            const report = resp.data.map((item) => ({
                ...item,
                name: this.getRoles(item.parents.login, item.parents.permissionLevel),
            }));

            this.setState({ reports: report });
        }
    };

    propsDerrivedState = async () => {
        if (this.props.filterReport.date !== null && this.props.filterReport.provider !== null) {
            await this.setState({
                startDate: this.props.filterReport.date,
                searchType: String(this.props.filterReport.provider),
            });
            this.loadData();
        }
    };

    componentDidMount() {
        this.propsDerrivedState();
    }

    componentWillUnmount(): void {
        this.props.setFilterReport(null);
    }

    switchModal = (e: React.MouseEvent<HTMLElement>): void => switchModalGlobal(this, e);

    selectDate = (dates: Date[]) => {
        const [start, end] = dates;
        this.setState({ startDate: start, endDate: end });
    };

    userBalance = (e: any): void => {
        const [target, name, type] = e.currentTarget.dataset.user.split('::');

        this.setState({
            userData: { target, name, type },
            userBalanceIsOpen: true,
        });
    };

    changeSearchLogin = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;

        this.setState({
            searchLogin: value,
        });
    };

    changeUserId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        const regex = /^\d*$/;

        if (regex.test(value)) {
            this.setState({
                userId: value,
            });
        }
    };

    chnageLogin = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        this.setState({
            currentLogin: value,
        });
    };

    getOptionBalanceType = () => {
        const enumKeys = Object.keys(BalanceTypeEnum)
            .filter((key) => Number.isNaN(Number(BalanceTypeEnum[key])))
            .map((key) => ({
                label: BalanceTypeEnum[key],
                value: Number(key),
            }));

        return enumKeys.filter(
            (item) =>
                item.value === BalanceTypeEnum.BALANCE_TYPE_RACING_DEPOSIT ||
                item.value === BalanceTypeEnum.BALANCE_TYPE_RACING_WITHDRAW ||
                item.value === BalanceTypeEnum.BALANCE_TYPE_CASINO_DEBIT ||
                item.value === BalanceTypeEnum.BALANCE_TYPE_CASINO_CREDIT ||
                item.value === BalanceTypeEnum.BALANCE_TYPE_CASINO_ROLLBACK,
        );
    };

    handleSearchTypeChange = (selectedOptions) => {
        this.setState({
            searchType: selectedOptions,
        });
    };

    getLastTuesday() {
        const today = new Date();
        const dayOfWeek = today.getUTCDay();
        let daysToSubtract = dayOfWeek - 2;
        if (daysToSubtract < 0) {
            daysToSubtract += 7;
        }
        const lastTuesday = new Date(today);
        lastTuesday.setUTCDate(today.getUTCDate() - daysToSubtract);
        lastTuesday.setUTCHours(8, 0, 0, 0);
        return lastTuesday;
    }

    handleChangeIsSchedule = (value: boolean) => {
        if (value === true) {
            this.setState({ startDate: this.getLastTuesday(), endDate: new Date() });
        }
        this.setState({ isSchedule: value });
    };

    handleChangeIsTree = (value: boolean) => {
        this.setState({ isTree: value });
    };

    handleClickPrev = () => {
        const startDate = moment(this.state.startDate);
        const newStartDate = startDate.subtract(7, 'days');
        const newEndDate = moment(newStartDate).add(7, 'days');

        this.setState({ startDate: newStartDate, endDate: newEndDate });
    };

    handleClickNext = () => {
        const startDate = moment(this.state.startDate);
        const newStartDate = startDate.add(7, 'days');
        const newEndDate = moment(newStartDate).add(7, 'days');
        const lastTuesday = this.getLastTuesday();

        if (newEndDate.isAfter(new Date())) {
            this.setState({ endDate: new Date() });
        } else {
            this.setState({ endDate: newEndDate.toDate() });
        }
        if (newStartDate.isAfter(lastTuesday)) {
            this.setState({ startDate: lastTuesday, endDate: new Date() });
        } else {
            this.setState({ startDate: newStartDate.toDate() });
        }
    };

    handleChangeSorted = (key, name) => {
        if (
            this.state.sorted.key === key &&
            this.state.sorted.name === name &&
            this.state.sorted.sortedBy === EnumSortDirection.ASC
        ) {
            this.setState({ sorted: { key, sortedBy: EnumSortDirection.DESC, name } });
            return;
        }
        if (
            this.state.sorted.key === key &&
            this.state.sorted.name === name &&
            this.state.sorted.sortedBy === EnumSortDirection.DESC
        ) {
            this.setState({ sorted: { key: '', sortedBy: '', name: '' } });
            return;
        }

        this.setState({ sorted: { key, sortedBy: EnumSortDirection.ASC, name } });
    };

    activeSorted = (key, name) => {
        if (
            this.state.sorted.key === key &&
            this.state.sorted.name === name &&
            this.state.sorted.sortedBy === EnumSortDirection.ASC
        ) {
            return EnumSortDirection.ASC;
        }
        if (
            this.state.sorted.key === key &&
            this.state.sorted.name === name &&
            this.state.sorted.sortedBy === EnumSortDirection.DESC
        ) {
            return EnumSortDirection.DESC;
        }

        return null;
    };

    changeSearchType = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const { value } = event.target;

        this.setState({
            searchType: value,
        });
    };

    showColumn = (key) => {
        const find = this.state.reports.find((item) => item.report_with_profit[key] === null);
        if (find) return false;
        return true;
    };

    downloadCSV = () => {
        const table = document.getElementById('report');
        const rows = table.querySelectorAll('tr');
        const csvRows = Array.from(rows).map((row) => {
            const cells = row.querySelectorAll('th, td');
            const rowData = Array.from(cells).map((cell) => {
                if (cell instanceof HTMLTableCellElement) {
                    return cell.innerText;
                } else {
                    return '';
                }
            });
            return rowData.join(',');
        });
        const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${this.factoryNameFile()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handleClick = (e) => {
        this.setState({
            currentPage: Number(e),
        });
    };

    render(): JSX.Element {
        const { userData, userBalanceIsOpen, isSchedule, startDate, endDate, reports, isTree, currentPage, itemsPerPage } =
            this.state;
        const { usersLoaded, translate } = this.props;
        const isMobile = window.innerWidth <= 700;

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = reports.slice(indexOfFirstItem, indexOfLastItem);

        const totalPages = Math.ceil(reports.length / itemsPerPage);

        return (
            <>
                <CSSTransition in={userBalanceIsOpen} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                    <UserBalanceModal dataModal="userBalanceIsOpen" switchModal={this.switchModal} userData={userData} />
                </CSSTransition>

                <div className="statistics-page page-container">
                    <div className="card-line card-line__header" style={{ padding: '0', paddingLeft: '10px' }}>
                        <div>{translate('Report')}</div>
                    </div>
                    {isMobile && (
                        <div
                            className="statistics__filter"
                            style={{
                                alignItems: 'center',
                                marginTop: '10px',
                                gap: '10px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                        >
                            <CheckBox
                                className=""
                                label="isTree"
                                onChange={() => this.handleChangeIsTree(!isTree)}
                                checked={isTree}
                            />
                            <CheckBox
                                className=""
                                label="isSchedule"
                                onChange={() => this.handleChangeIsSchedule(!isSchedule)}
                                checked={isSchedule}
                            />
                        </div>
                    )}
                    <div
                        className="statistics__filter"
                        style={{
                            alignItems: 'center',
                            marginTop: '10px',
                            gap: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        {!isMobile && (
                            <>
                                <CheckBox
                                    className=""
                                    label="isTree"
                                    onChange={() => this.handleChangeIsTree(!isTree)}
                                    checked={isTree}
                                />
                                <CheckBox
                                    className=""
                                    label="isSchedule"
                                    onChange={() => this.handleChangeIsSchedule(!isSchedule)}
                                    checked={isSchedule}
                                />
                            </>
                        )}
                        {!isSchedule ? (
                            <div className="DTPicker-container">
                                <DateRangePickerComponent
                                    onChange={this.selectDate}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    className="DTPicker-container"
                                />
                            </div>
                        ) : (
                            <div className="report-date-schedue">
                                <img onClick={this.handleClickPrev} className="icon-prev" src={arrow} alt="" />
                                <div className="report-date-schedue-date">
                                    <span>{moment(startDate, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('DD-MM-YYYY HH:mm')}</span>
                                    <span>-</span>
                                    <span>{moment(endDate, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('DD-MM-YYYY HH:mm')}</span>
                                </div>
                                <img onClick={this.handleClickNext} className="icon-next" src={arrow} alt="" />
                            </div>
                        )}
                        <Button
                            className="btn gold-btn nowrap"
                            type="button"
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.loadData();
                                setTimeout(() => {
                                    this.setState({ isLoading: false });
                                }, 500);
                            }}
                            isLoading={this.state.isLoading}
                        >
                            {this.props.translate('Apply')}
                        </Button>
                    </div>
                    <div className="transactions-filter" style={{ gap: '10px', marginTop: '20px' }}>
                        <div style={{ position: 'relative' }}>
                            {!this.state.isTree && <span className="info">{translate('only-player')}</span>}
                            {this.state.isTree && <span className="info">{translate('not-player')}</span>}

                            <Input
                                className="input gold-input input-filter-login"
                                placeholder={this.props.translate('Target')}
                                type="text"
                                name="target"
                                error=""
                                value={this.state.userId}
                                onChange={this.changeUserId}
                            />
                        </div>
                        <div style={{ position: 'relative' }}>
                            {!this.state.isTree && <span className="info">{translate('only-player')}</span>}
                            {this.state.isTree && <span className="info">{translate('not-player')}</span>}
                            <Input
                                className="input gold-input input-filter-login"
                                placeholder={this.props.translate('Login')}
                                type="text"
                                name="login"
                                error=""
                                value={this.state.currentLogin}
                                onChange={this.chnageLogin}
                            />
                            {this.state.userId.length !== 0 && this.state.currentLogin.length !== 0 && (
                                <span className="error">{translate('LoginPriority')}</span>
                            )}
                        </div>
                        <div>
                            <select
                                value={this.state.searchType}
                                className="select gold-select select-filter-type"
                                name="status"
                                onChange={this.changeSearchType}
                            >
                                <option value="">-</option>
                                <option value={SystemProviderEnum.PROVIDER_CASINO}>
                                    {SystemProviderEnum[SystemProviderEnum.PROVIDER_CASINO]}
                                </option>
                                <option value={SystemProviderEnum.PROVIDER_RACING}>
                                    {SystemProviderEnum[SystemProviderEnum.PROVIDER_RACING]}
                                </option>
                                <option value={SystemProviderEnum.PROVIDER_SPORT}>
                                    {SystemProviderEnum[SystemProviderEnum.PROVIDER_SPORT]}
                                </option>
                                <option value={SystemProviderEnum.SYSTEM_GAME}>
                                    {SystemProviderEnum[SystemProviderEnum.SYSTEM_GAME]}
                                </option>
                            </select>
                        </div>
                    </div>
                    <CSSTransition in={usersLoaded} unmountOnExit={true} timeout={200} classNames="modalWindowAnimation">
                        {reports.length ? (
                            <>
                                <div className="statistics__content">
                                    <div className="statistics__branch">
                                        <CSSTransition
                                            in={true}
                                            unmountOnExit={true}
                                            timeout={200}
                                            classNames="modalWindowAnimation"
                                        >
                                            <div
                                                className={cn('table', {
                                                    'tbody-height': '40px',
                                                })}
                                            >
                                                <table>
                                                    <thead>
                                                        <tr style={{ borderBottom: '1px solid #cda663' }}>
                                                            <th
                                                                style={{
                                                                    width: '',
                                                                    borderRight: '1px solid #cda663',
                                                                    textAlign: 'center',
                                                                    display: 'flex',
                                                                    gap: '10px',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <PDF onClick={this.generatePdf} className="icon-download-pdf" />
                                                                <CSV onClick={this.downloadCSV} className="icon-download" />
                                                            </th>
                                                            {this.showColumn('PROVIDER_CASINO') && (
                                                                <th
                                                                    colSpan={4}
                                                                    style={{
                                                                        borderRight: '1px solid #cda663',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    {translate('Casino')}
                                                                </th>
                                                            )}
                                                            {this.showColumn('PROVIDER_RACING') && (
                                                                <th
                                                                    colSpan={4}
                                                                    style={{
                                                                        borderRight: '1px solid #cda663',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    {translate('Racing')}
                                                                </th>
                                                            )}
                                                            {this.showColumn('SYSTEM_GAME') && (
                                                                <th
                                                                    colSpan={4}
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        borderRight: !this.state.searchType
                                                                            ? '1px solid #cda663'
                                                                            : 'none',
                                                                    }}
                                                                >
                                                                    {translate('system-game')}
                                                                </th>
                                                            )}
                                                            {!this.state.searchType && (
                                                                <th colSpan={1} style={{ textAlign: 'center' }}>
                                                                    {translate('total')}
                                                                </th>
                                                            )}
                                                        </tr>
                                                        <tr style={{ borderBottom: '1px solid #cda663' }}>
                                                            <th
                                                                style={{
                                                                    width: '100px',
                                                                    borderRight: '1px solid #cda663',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                {translate('UserName')}
                                                            </th>
                                                            {this.showColumn('PROVIDER_CASINO') && (
                                                                <>
                                                                    {' '}
                                                                    <th
                                                                        className="item-table"
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('PROVIDER_CASINO', 'played')
                                                                        }
                                                                    >
                                                                        {translate('Played')}
                                                                        {this.activeSorted('PROVIDER_CASINO', 'played') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted(
                                                                                        'PROVIDER_CASINO',
                                                                                        'played',
                                                                                    ) === EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        className="item-table"
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('PROVIDER_CASINO', 'profit')
                                                                        }
                                                                    >
                                                                        {translate('Profit')}
                                                                        {this.activeSorted('PROVIDER_CASINO', 'profit') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted(
                                                                                        'PROVIDER_CASINO',
                                                                                        'profit',
                                                                                    ) === EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        className="item-table"
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('PROVIDER_CASINO', 'refunded')
                                                                        }
                                                                    >
                                                                        {translate('Refunded')}
                                                                        {this.activeSorted('PROVIDER_CASINO', 'refunded') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted(
                                                                                        'PROVIDER_CASINO',
                                                                                        'refunded',
                                                                                    ) === EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        className="item-table"
                                                                        style={{
                                                                            borderRight: '1px solid #cda663',
                                                                        }}
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('PROVIDER_CASINO', 'won')
                                                                        }
                                                                    >
                                                                        {translate('Won')}
                                                                        {this.activeSorted('PROVIDER_CASINO', 'won') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted(
                                                                                        'PROVIDER_CASINO',
                                                                                        'won',
                                                                                    ) === EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                </>
                                                            )}
                                                            {this.showColumn('PROVIDER_RACING') && (
                                                                <>
                                                                    <th
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('PROVIDER_RACING', 'played')
                                                                        }
                                                                        className="item-table"
                                                                    >
                                                                        {translate('Played')}
                                                                        {this.activeSorted('PROVIDER_RACING', 'played') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted(
                                                                                        'PROVIDER_RACING',
                                                                                        'played',
                                                                                    ) === EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('PROVIDER_RACING', 'profit')
                                                                        }
                                                                        className="item-table"
                                                                    >
                                                                        {translate('Profit')}
                                                                        {this.activeSorted('PROVIDER_RACING', 'profit') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted(
                                                                                        'PROVIDER_RACING',
                                                                                        'profit',
                                                                                    ) === EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('PROVIDER_RACING', 'refunded')
                                                                        }
                                                                        className="item-table"
                                                                    >
                                                                        {translate('Waiting')}
                                                                        {this.activeSorted('PROVIDER_RACING', 'refunded') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted(
                                                                                        'PROVIDER_RACING',
                                                                                        'refunded',
                                                                                    ) === EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        className="item-table"
                                                                        style={{
                                                                            borderRight: '1px solid #cda663',
                                                                        }}
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('PROVIDER_RACING', 'won')
                                                                        }
                                                                    >
                                                                        {translate('Won')}
                                                                        {this.activeSorted('PROVIDER_RACING', 'won') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted(
                                                                                        'PROVIDER_RACING',
                                                                                        'won',
                                                                                    ) === EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                </>
                                                            )}
                                                            {this.showColumn('SYSTEM_GAME') && (
                                                                <>
                                                                    <th
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('SYSTEM_GAME', 'played')
                                                                        }
                                                                        className="item-table"
                                                                    >
                                                                        {translate('Played')}
                                                                        {this.activeSorted('SYSTEM_GAME', 'played') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted('SYSTEM_GAME', 'played') ===
                                                                                    EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('SYSTEM_GAME', 'profit')
                                                                        }
                                                                        className="item-table"
                                                                    >
                                                                        {translate('Profit')}
                                                                        {this.activeSorted('SYSTEM_GAME', 'profit') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted('SYSTEM_GAME', 'profit') ===
                                                                                    EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('SYSTEM_GAME', 'refunded')
                                                                        }
                                                                        className="item-table"
                                                                    >
                                                                        {translate('Refunded')}
                                                                        {this.activeSorted('SYSTEM_GAME', 'refunded') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted(
                                                                                        'SYSTEM_GAME',
                                                                                        'refunded',
                                                                                    ) === EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        onClick={() =>
                                                                            this.handleChangeSorted('SYSTEM_GAME', 'won')
                                                                        }
                                                                        className="item-table"
                                                                        style={{
                                                                            borderRight: !this.state.searchType
                                                                                ? '1px solid #cda663'
                                                                                : 'none',
                                                                        }}
                                                                    >
                                                                        {translate('Won')}
                                                                        {this.activeSorted('SYSTEM_GAME', 'won') && (
                                                                            <Sorted
                                                                                className={
                                                                                    this.activeSorted('SYSTEM_GAME', 'won') ===
                                                                                    EnumSortDirection.DESC
                                                                                        ? 'iconSortDesc'
                                                                                        : 'iconSortAsc'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </th>
                                                                </>
                                                            )}

                                                            {!this.state.searchType && (
                                                                <th
                                                                    onClick={() =>
                                                                        this.handleChangeSorted('totalProfit', 'profit')
                                                                    }
                                                                    className="item-table"
                                                                >
                                                                    {translate('Profit')}
                                                                    {this.activeSorted('totalProfit', 'profit') && (
                                                                        <Sorted
                                                                            className={
                                                                                this.activeSorted('totalProfit', 'profit') ===
                                                                                EnumSortDirection.DESC
                                                                                    ? 'iconSortDesc'
                                                                                    : 'iconSortAsc'
                                                                            }
                                                                        />
                                                                    )}
                                                                </th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.sortedPosition(currentItems).map((item) => (
                                                            <tr key={item.username} style={{ borderBottom: '1px solid #cda663' }}>
                                                                <td
                                                                    style={{
                                                                        width: '100px',
                                                                        borderRight: '1px solid #cda663',
                                                                        textAlign: 'center',
                                                                        whiteSpace: 'break-spaces',
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </td>
                                                                {this.showColumn('PROVIDER_CASINO') && (
                                                                    <>
                                                                        {' '}
                                                                        <td style={{ width: '100px', textAlign: 'center' }}>
                                                                            {item.report_with_profit.PROVIDER_CASINO.played / 100}
                                                                        </td>
                                                                        <td style={{ width: '100px', textAlign: 'center' }}>
                                                                            {item.report_with_profit.PROVIDER_CASINO.profit / 100}
                                                                        </td>
                                                                        <td style={{ width: '100px', textAlign: 'center' }}>
                                                                            {item.report_with_profit.PROVIDER_CASINO.refunded /
                                                                                100}
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '100px',
                                                                                borderRight: '1px solid #cda663',
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            {item.report_with_profit.PROVIDER_CASINO.won / 100}
                                                                        </td>
                                                                    </>
                                                                )}
                                                                {this.showColumn('PROVIDER_RACING') && (
                                                                    <>
                                                                        {' '}
                                                                        <td style={{ width: '100px', textAlign: 'center' }}>
                                                                            {item.report_with_profit.PROVIDER_RACING.played / 100}
                                                                        </td>
                                                                        <td style={{ width: '100px', textAlign: 'center' }}>
                                                                            {item.report_with_profit.PROVIDER_RACING.profit / 100}
                                                                        </td>
                                                                        <td style={{ width: '100px', textAlign: 'center' }}>
                                                                            {item.report_with_profit.PROVIDER_RACING.waiting /
                                                                                100}
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '100px',
                                                                                borderRight: '1px solid #cda663',
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            {item.report_with_profit.PROVIDER_RACING.won / 100}
                                                                        </td>
                                                                    </>
                                                                )}
                                                                {this.showColumn('SYSTEM_GAME') && (
                                                                    <>
                                                                        <td style={{ width: '100px', textAlign: 'center' }}>
                                                                            {item.report_with_profit.SYSTEM_GAME.played / 100}
                                                                        </td>
                                                                        <td style={{ width: '100px', textAlign: 'center' }}>
                                                                            {item.report_with_profit.SYSTEM_GAME.profit / 100}
                                                                        </td>
                                                                        <td style={{ width: '100px', textAlign: 'center' }}>
                                                                            {item.report_with_profit.SYSTEM_GAME.refunded / 100}
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '100px',
                                                                                textAlign: 'center',
                                                                                borderRight: !this.state.searchType
                                                                                    ? '1px solid #cda663'
                                                                                    : 'none',
                                                                            }}
                                                                        >
                                                                            {item.report_with_profit.SYSTEM_GAME.won / 100}
                                                                        </td>
                                                                    </>
                                                                )}

                                                                {!this.state.searchType && (
                                                                    <td style={{ width: '100px', textAlign: 'center' }}>
                                                                        {item.report_with_profit.totalProfit / 100}
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CSSTransition>
                                    </div>
                                </div>
                                <Paginate pageCount={totalPages} handlePage={this.handleClick} />
                            </>
                        ) : (
                            <NoContent translate={translate} />
                        )}
                    </CSSTransition>
                </div>
                {
                    <div style={{ display: 'none' }}>
                        <TableReport
                            showColumn={this.showColumn}
                            translate={translate}
                            searchType={this.state.searchType}
                            handleChangeSorted={this.handleChangeSorted}
                            activeSorted={this.activeSorted}
                            sortedPosition={this.sortedPosition}
                            currentItems={this.state.reports}
                        />
                    </div>
                }
            </>
        );
    }
}

interface IStoreState {
    auth: {
        user: IProfile;
    };
    manageUsers: {
        betting: IStatisticGet;
        filterReport: {
            date: null | Date;
            provider: null | number;
        };
    };
    manageHandlers: {
        handlerStatus: IHandlerStatus;
    };
    evoGames: {
        gamesList: IEvoGame[];
    };
    usersLoaded: boolean;
}

const mapStateToProps = (state: IStoreState) => ({
    user: state.auth.user,
    betting: state.manageUsers.betting,
    handlerStatus: state.manageHandlers.handlerStatus,
    gamesList: state.evoGames.gamesList,
    values: {
        start: new Date(new Date().getTime() - 604800000),
        end: new Date(),
    },
    filterReport: state.manageUsers.filterReport,
    errors: {
        start: '',
        end: '',
    },
    usersLoaded: true,
});

const mapDispatchToProps = {
    bettingRequest,
    gamesRequest,
    setFilterReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(Report));
