import React from 'react';

export default (item, { _styles }) => (
    <tr key={item.id}>
        <td style={{ ..._styles[0] }}>{item.user.id}</td>
        <td style={{ ..._styles[1] }}>{item.user.name ?? '-'}</td>
        <td style={{ ..._styles[2] }}>{item.betCount ?? '-'}</td>
        <td style={{ ..._styles[2] }}>{item.canceledBet ?? '-'}</td>
        <td style={{ ..._styles[3] }}>{item.debitAmount ?? '-'}</td>
        <td style={{ ..._styles[4] }}>{item.profitAmount ?? '-'}</td>
        <td style={{ ..._styles[7] }}>{item.winAmount ?? '-'}</td>
        <td style={{ ..._styles[7] }}>{item.agentProfitAmount ?? '-'}</td>
        <td style={{ ..._styles[7] }}>{item.masterProfitAmount ?? '-'}</td>
        <td style={{ ..._styles[7] }}>{item.discount ?? '-'}</td>
    </tr>
);
